export const modalIds = {
  RULES_MODAL: 'RULES_MODAL',
  CATEGORIES_MODAL: 'CATEGORIES_MODAL',
  BRANDS_MODAL: 'BRANDS_MODAL',
  CONDITIONS_MODAL: 'CONDITIONS_MODAL',
  SIZES_MODAL: 'SIZES_MODAL',
  TRANSACTIONS_MODAL: 'TRANSACTIONS_MODAL',
  SUSPENSION_MODAL: 'SUSPENSION_MODAL',
  SUSPICIOUS_MODAL: 'SUSPICIOUS_MODAL',
  REFERRAL_USERS_MODAL: 'REFERRAL_USERS_MODAL',
  GROUP_INFO_MODAL: 'GROUP_INFO_MODAL',
  SUBCOMMUNITIES_MODAL: 'SUBCOMMUNITIES_MODAL',
  GROUP_MODAL: 'GROUP_MODAL',
};

export const modalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const itemTypes = {
  PARENT: 'PARENT',
  CHILD: 'CHILD',
};
