// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ActiveUsers_title__3TkZ0 {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n.ActiveUsers_container__nOJTw {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n\n.ActiveUsers_containerStatistics__5ttJ9 {\n  width: 100%;\n  display: flex;\n  gap: 10rem;\n  margin-top: 2rem;\n}", ""]);
// Exports
exports.locals = {
	"title": "ActiveUsers_title__3TkZ0",
	"container": "ActiveUsers_container__nOJTw",
	"containerStatistics": "ActiveUsers_containerStatistics__5ttJ9"
};
module.exports = exports;
