import { all, takeEvery, select, call, put } from 'redux-saga/effects';
import { showNotification } from 'react-admin';

import api from '../../api/transactions';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { showErrorMessage } from '../ui/operations';
import {
  TRANSACTIONS_FIRST_RENDER_LIMIT,
  TRANSACTIONS_LIMIT_PER_PAGE,
} from '../../constants/transactions';
import { appendTypeToTransactions } from '../../utils/transactions';
import { formatCase, normalize } from '../../utils/store';
import { getSelectedCommunityId } from '../community/selectors';

function* onGetTransactions({ payload = {} }) {
  try {
    const {
      searchTerm,
      offerIdSearchTerm,
      isLoadMore = false,
      nextPage = 0,
    } = payload;

    const type = yield select(selectors.getSelectedTransactionsType);
    const currentTransactionsCount = yield select(selectors.getTransactionsCountByType, type);
    const isExistMore = yield select(selectors.getIsExistMore);
    const isLoading = yield select(selectors.getIsLoading);

    const isNeedToFetchData = currentTransactionsCount <= (nextPage * TRANSACTIONS_LIMIT_PER_PAGE);

    if (isLoading || (isLoadMore && (!isNeedToFetchData || !isExistMore))) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const offset = isLoadMore
      ? currentTransactionsCount
      : 0;

    const limit = isLoadMore
      ? TRANSACTIONS_LIMIT_PER_PAGE
      : TRANSACTIONS_FIRST_RENDER_LIMIT;

    const transactions = yield call(
      api.getTransactions,
      { type, offset, limit, searchTerm, offerIdSearchTerm },
    );

    const hasMore = transactions.length === limit;

    const transactionsWithType = appendTypeToTransactions(transactions, type);

    const normalizedTransactions = normalize(transactionsWithType);

    yield put(actions.getTransactionsSuccess({
      ...normalizedTransactions,
      isExistMore: hasMore,
      isLoadMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onSetTransactionsType({ payload: type }) {
  const transactionsCount = yield select(selectors.getTransactionsCountByType, type);

  if (transactionsCount === 0) {
    yield put(actions.getTransactions());
  }
}

function* onCreateIssueTransaction({ payload }) {
  const { receiverIds, amount, note } = payload;
  const communityId = yield select(getSelectedCommunityId);

  try {
    yield call(api.createIssueTransaction, formatCase.toSnakeCase({
      communityId,
      // TODO: key name according to the existing API, fix
      receiversIds: receiverIds,
      amount,
      note,
    }));

    yield put(showNotification('message.success.action.transaction.send'));
    yield put (actions.getTransactions());
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onDeclineItemRequest({ payload }) {
  try {
    const { transactionId, offerId } = payload;
    yield call(api.performDeclineItemRequest, offerId);

    if (transactionId) {
      yield put(actions.declineItemRequestSuccess({ transactionId }));
    }

    yield put(showNotification('message.success.action.performDecline'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onPerformRefund({ payload }) {
  try {
    const { transactionId, offerId } = payload;
    yield call(api.performRefund, offerId);

    if (transactionId) {
      yield put(actions.performRefundSuccess({ transactionId }));
    }
    yield put(showNotification('message.success.action.performRefund'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_TRANSACTIONS, onGetTransactions),
    takeEvery(AT.SET_TRANSACTIONS_TYPE, onSetTransactionsType),
    takeEvery(AT.CREATE_ISSUE_TRANSACTION, onCreateIssueTransaction),
    takeEvery(AT.DECLINE_ITEM_REQUEST, onDeclineItemRequest),
    takeEvery(AT.PERFORM_REFUND, onPerformRefund),
  ]);
}
