import { createAction } from 'redux-actions';
import * as AT from './types';

export const setGroupId = createAction(AT.SET_GROUP_ID);
export const setCategories = createAction(AT.SET_CATEGORIES);

export const addCategory = createAction(AT.ADD_CATEGORY);
export const addCategorySuccess = createAction(AT.ADD_CATEGORY_SUCCESS);

export const setIsCategoryActive = createAction(AT.SET_IS_CATEGORY_ACTIVE);

export const updateCategory = createAction(AT.UPDATE_CATEGORY);
export const updateCategorySuccess = createAction(AT.UPDATE_CATEGORY_SUCCESS);

export const reorderCategory = createAction(AT.REORDER_CATEGORY);
