import * as React from 'react';
import PropTypes from 'prop-types';
import { Alert as ReactStrapAlert } from 'reactstrap';

const alertTypes = {
  success: 'success',
  info: 'info',
  primary: 'primary',
  warning: 'warning',
  danger: 'danger',
};

const Alert = ({ children, type }) => {
  return (
    <ReactStrapAlert color={type}>
      {children}
    </ReactStrapAlert>
  );
};

Alert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(alertTypes)),
};

Alert.defaultProps = {
  type: alertTypes.info,
};

export const alertProps = {
  types: alertTypes,
};

export default Alert;
