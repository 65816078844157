import { createSelector } from 'reselect';

export const getCitiesIds = (state) => {
  return state.cities.ids;
};

export const getCitiesEntities = (state) => {
  return state.cities.entities;
};

export const getCities = createSelector(
  [getCitiesIds, getCitiesEntities],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export const getCitiesExistMore = (state) => {
  return state.cities.isExistMore;
};

export const getIsCitiesLoading = (state) => {
  return state.cities.isLoading;
};

export const getCitiesOffset = createSelector(
  [getCitiesIds],
  (ids) => {
    return ids.length;
  },
);
