import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import ButtonWithPopover from '../../../components/Button/ButtonWithPopover';
import Button, { buttonProps } from '../../../components/Button';
import TextLabel, { textLabelColors } from '../../../components/TextLabel';
import { modalModes, itemTypes } from '../../../modals/const';
import styles from './SortableGridNestedItem.module.scss';

const SortableGridNestedItem = ({
  name,
  id,
  parentId,
  setIsCategoryActive,
  openModal,
  isActive,
}) => {
  const translate = useTranslate();

  const buttonId = `button-${id}`;
  const isDeactivated = !isActive;

  const onToggleActiveChildClick = () => {
    setIsCategoryActive({ id, isActive: !isActive });
  };

  const onEditChildClick = () => {
    const options = {
      mode: modalModes.EDIT,
      itemType: itemTypes.CHILD,
      name,
      id,
      parentId,
    };

    openModal(options);
  };

  return (
    <div
      className={cls('list-group-item', 'd-flex', 'mb-3', 'border-top', styles.nestedItemWrapper)}
      data-id={id}
    >
      <p className={cls(styles.nestedItemTitle, 'mb-0')}>
        {name}
        {isDeactivated && (
          <TextLabel
            color={textLabelColors.danger}
          >
            {translate('pages.categories.labels.deactivated')}
          </TextLabel>
        )}
      </p>

      <ButtonWithPopover
        id={buttonId}
        theme={buttonProps.themes.link}
        size={buttonProps.sizes.null}
        color={buttonProps.colors.dark}
        title={
          <i className="fa fa-ellipsis-v" />
        }
      >
        {isActive && (
          <Button
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            onClick={onEditChildClick}
            marginTop={false}
          >
            {translate('pages.categories.buttons.edit')}
          </Button>
        )}
        <Button
          size={buttonProps.sizes.null}
          theme={buttonProps.themes.link}
          onClick={onToggleActiveChildClick}
          marginTop={false}
        >
          {isActive
            ? translate('pages.categories.buttons.deactivate')
            : translate('pages.categories.buttons.activate')
          }
        </Button>
      </ButtonWithPopover>
    </div>
  );
};

SortableGridNestedItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  parentId: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,

  setIsCategoryActive: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default SortableGridNestedItem;
