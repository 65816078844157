import { createSelector } from 'reselect';

export const getGroupId = (state) => {
  return state.conditions.groupId;
};

export const getConditionEntities = (state) => {
  return state.conditions.conditionEntities;
};

export const getConditionIds = (state) => {
  return state.conditions.conditionIds;
};

export const getConditions = createSelector(
  [getConditionEntities, getConditionIds],
  (entities, ids) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);
