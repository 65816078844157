import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import ReactDatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';
import Button, { buttonProps } from '../Button';
import styles from './Datepicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

// eslint-disable-next-line no-unused-vars
const CustomInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <Button
      theme={buttonProps.themes.link}
      size={buttonProps.sizes.large}
      marginTop={false}
      onClick={onClick}
      innerRef={ref}
    >
      {value}
    </Button>
  );
});

const DatePicker = ({ value, label, id, minDate, onChange }) => {
  const maxAvailableDate = new Date();

  const onDateChange = (newDate) => {
    onChange(newDate);
  };

  return (
    <FormGroup className={cls('d-flex', 'mb-0', styles.datePickerGroup)}>
      {label && (
        <Label
          className={cls('font-weight-bold', 'mb-0', styles.datePickerLabel)}
          for={id}
        >
          {label}
        </Label>
      )}
      <ReactDatePicker
        selected={value}
        id={id}
        onChange={onDateChange}
        maxDate={maxAvailableDate}
        minDate={minDate}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        showPopperArrow={false}
        isClearable={false}
        fixedHeight
        disabledKeyboardNavigation
      />
    </FormGroup>
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),

  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  label: null,
  id: null,
  minDate: null,
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onClick: PropTypes.func,
};

CustomInput.defaultProps = {
  value: null,
  onClick: () => {},
};

export default DatePicker;
