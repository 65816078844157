// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.List_listWrapper__1QK-D {\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  grid-gap: 1rem;\n}\n.List_listWrapper__1QK-D > * {\n  margin-bottom: 0 !important;\n  font-family: Montserrat, sans-serif;\n}\n\n.List_listItemValue__3sR0U {\n  font-size: 3rem;\n  color: #444;\n  font-family: Helvetica, Arial, sans-serif;\n}", ""]);
// Exports
exports.locals = {
	"listWrapper": "List_listWrapper__1QK-D",
	"listItemValue": "List_listItemValue__3sR0U"
};
module.exports = exports;
