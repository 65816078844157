import { put, call, take, takeEvery, all, select } from 'redux-saga/effects';
import { isNil } from 'ramda';
import * as AT from './types';
import categoriesAPI from '../../api/categories';
import * as categoriesActions from './actions';
import * as categoriesSelectors from './selectors';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { normalize, formatCase, convertStringToNumber } from '../../utils/store';

function* getCategories(groupId) {
  const categories = yield call(categoriesAPI.getCategories, groupId);
  const normalizedCategories = normalize(categories, 'categoryIds', 'categoryEntities');

  yield put(categoriesActions.setCategories(normalizedCategories));
}

function* onSetGroupId({ payload: groupId }) {
  yield put(setLoader(true));

  try {
    yield* getCategories(groupId);
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onAddCategory({ payload }) {
  yield put(setLoader(true));

  try {
    const groupId = yield select(categoriesSelectors.getSelectedGroupId);
    const order = isNil(payload.parentId)
      ? yield select(categoriesSelectors.getNextParentCategoryOrder)
      : yield select(categoriesSelectors.getNextChildCategoryOrderById, payload.parentId);

    const data = formatCase.toSnakeCase({
      ...payload,
      groupId,
      order,
    });

    const categoryRaw = yield call(categoriesAPI.addCategory, data);
    const category = formatCase.toCamelCase(categoryRaw);

    yield put(categoriesActions.addCategorySuccess({
      ...category,
      parentId: convertStringToNumber(category.parentId),
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onSetIsCategoryActive({ payload }) {
  const { id, isActive } = payload;

  if (isActive) {
    const groupId = yield select(categoriesSelectors.getSelectedGroupId);

    yield call(
      categoriesAPI.activateCategory,
      formatCase.toSnakeCase({ categoryId: id, groupId }),
    );
  } else {
    yield call(categoriesAPI.deactivateCategory, id);
  }

  yield put(categoriesActions.updateCategorySuccess(payload));
}

function* onUpdateCategory({ payload }) {
  const groupId = yield select(categoriesSelectors.getSelectedGroupId);
  yield call(categoriesAPI.updateCategory, formatCase.toSnakeCase({ ...payload, groupId }));

  yield put(categoriesActions.updateCategorySuccess(payload));
}

function* watchOnReorderCategory() {
  while (true) {
    const { payload } = yield take(AT.REORDER_CATEGORY);

    const groupId = yield select(categoriesSelectors.getSelectedGroupId);
    const categoryFromState = yield select(categoriesSelectors.getCategoryById, [payload.id]);
    const isParentIdChanged =
      convertStringToNumber(categoryFromState.parentId) !== payload.parentId;

    const data = formatCase.toSnakeCase({
      groupId,
      categoryId: payload.id,
      newOrder: payload.order,
      ...(isParentIdChanged && { newParentId: payload.parentId }),
    });

    yield call(categoriesAPI.reorderCategory, data);
    yield put(categoriesActions.updateCategorySuccess(payload));
    yield* getCategories(groupId);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.SET_GROUP_ID, onSetGroupId),
    takeEvery(AT.SET_IS_CATEGORY_ACTIVE, onSetIsCategoryActive),
    takeEvery(AT.ADD_CATEGORY, onAddCategory),
    takeEvery(AT.UPDATE_CATEGORY, onUpdateCategory),
    watchOnReorderCategory(),
  ]);
}
