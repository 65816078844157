import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { getSelectedCommunityId } from '../community/selectors';
import api from '../../api/items';
import { showErrorMessage } from '../ui/operations';
import { normalize } from '../../utils/store';
import {
  ITEMS_INITIAL_LIMIT,
  ITEMS_PAGE_LIMIT,
  DEFAULT_SEARCH_TERM,
} from '../../constants/items';

function* onGetCommunityItems({ payload = {} }) {
  try {
    const {
      isLoadMore = true,
      searchTerm = DEFAULT_SEARCH_TERM,
      nextPage = 0,
    } = payload;

    const communityId = yield select(getSelectedCommunityId);
    const currentItemsOffset = yield select(selectors.getItemsOffset);
    const isExistMore = yield select(selectors.getItemsExistMore);
    const isNeedToFetchData = currentItemsOffset <= (nextPage * ITEMS_PAGE_LIMIT);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const skip = isLoadMore
      ? currentItemsOffset
      : 0;

    const limit = skip === 0
      ? ITEMS_INITIAL_LIMIT
      : ITEMS_PAGE_LIMIT;

    const items = yield call(api.getCommunityItems, { skip, limit, communityId, searchTerm });
    const hasMore = items.length === limit;

    const normalizedItems = normalize(items);

    yield put(actions.getCommunityItemsSuccess({
      ...normalizedItems,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onHideItem({ payload: itemId }) {
  try {
    yield call(api.hideItem, itemId);
    yield put(actions.hideItemSuccess({ itemId }));
    yield put(showNotification('message.success.action.hideItem'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_COMMUNITY_ITEMS, onGetCommunityItems),
    takeEvery(AT.HIDE_ITEM, onHideItem),
  ]);
}
