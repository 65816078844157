import React, { memo } from 'react';
import cls from 'classnames';
import { ReactSortable as Sortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import { useLocale } from 'react-admin';
import styles from './SortableGroupsGrid.module.scss';

const SortableGridItem = memo(({
  id,
  name,
  iconName,
}) => {

  return (
    <div
      className={cls('list-group-item', 'pb-2', styles.sortableGridItemWrapper)}
      data-id={id}
    >
      <h4 className={styles.itemInfoTitle}>
        <img
          width={25}
          height={25}
          src={iconName}
          alt=""
          className={cls('mr-3', 'p-2', styles.itemIcon)}
        />
        {name}
      </h4>
    </div>
  );
});

const SortableGroupsGrid = memo(({
  list, setList,
}) => {

  const locale = useLocale();

  const setNewList = (newList) => {
    const listWithUpdatedOrder = newList.map((item, index) => {
      return { ...item, order: index };
    });

    setList(listWithUpdatedOrder);
  };

  return (
    <Sortable
      list={list}
      setList={setNewList}
      className={cls('d-grid', styles.sortableGrid)}
    >
      {list.map(({ value, label, icon }) => {
        return (
          <SortableGridItem
            key={value}
            id={value}
            name={label[locale]}
            iconName={icon}
          />
        );
      })}
    </Sortable>
  );
});

SortableGroupsGrid.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.shape({
        en: PropTypes.string,
        he: PropTypes.string,
      }).isRequired,
      description: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  setList: PropTypes.func.isRequired,
};

SortableGridItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  iconName: PropTypes.string,
};

export default SortableGroupsGrid;
