import api from './Api';

const getTransactions = ({ type, offset, limit, searchTerm, offerIdSearchTerm }) => {
  const queryString = api.getQueryParamsFromObject({
    offset,
    limit,
    search_term: searchTerm,
    offer_id_search_term: offerIdSearchTerm,
  });

  return api.get(`/getCommunityTransactions/${type}?${queryString}`);
};

const createIssueTransaction = (data) => {
  return api.post('/admin/createIssueTransaction', data);
};

const performDeclineItemRequest = (offerId) => {
  return api.post('/admin/offers/performDeclineRequest', { offer_id: offerId });
};

const performRefund = (offerId) => {
  return api.post('/admin/offers/performRefund', { offer_id: offerId });
};

export default {
  getTransactions,
  createIssueTransaction,
  performDeclineItemRequest,
  performRefund,
};
