import * as R from 'ramda';

export const chartColors = [
  '#F28C3B',
  '#189BC9',
  '#A44AFE',
  '#777A7E',
  '#47494C',
  '#39BDDC',
  '#0AC1B6',
  '#333951',
  '#59B88C',
  '#E97881',
];

export const formatChartString = (str) => {
  const isHebrewRegExp = new RegExp(/[\u0590-\u05FF]/);
  const isHebrewString = isHebrewRegExp.test(str);

  return isHebrewString
    ? R.reverse(str)
    : str;
};

export const getItemsLineChartOptions = (data, titles) => {
  return {
    series: data.map((item, index) => {
      return {
        type: 'areaspline',
        fillOpacity: 0.2,
        lineWidth: 0,
        name: titles[index],
        data: item,
        color: chartColors[index],
      };
    }),
    credits: { enabled: false },
    chart: {
      height: 350,
    },
    title: false,
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000',
      },
      itemHoverStyle: {
        color: '#020202',
      },
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000',
        },
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: '#000000',
        },
      },
    },
    annotations: { visible: false },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    },
  };
};

export const getMembersLineChartOptions = (data, name) => {
  return {
    series: [{
      type: 'areaspline',
      fillOpacity: 0.2,
      lineWidth: 0,
      data,
      name,
      color: chartColors[2],
    }],
    credits: { enabled: false },
    chart: {
      height: 350,
    },
    title: false,
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000',
      },
      itemHoverStyle: {
        color: '#020202',
      },
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000',
        },
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: '#000000',
        },
      },
    },
    annotations: { visible: false },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    },
  };
};

export const getColumnChartOptions = (data) => {
  return {
    series: data,
    chart: {
      type: 'column',
      height: 300,
    },
    credits: { enabled: false },
    title: false,
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    xAxis: {
      visible: false,
    },
    legend: {
      verticalAlign: 'bottom',
      itemStyle: {
        color: '#788898',
        fontWeight: 400,
      },
      itemHoverStyle: {
        color: '#cccccc',
      },
      itemMarginBottom: 5,
      symbolRadius: 0,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      headerFormat: null,
      pointFormat: '<span>{point.y}: <b>{series.name}</b></span>',
    },
  };
};

export const getPieChartOptions = (data) => {
  return {
    series: [{
      name: 'reasons',
      data,
    }],
    chart: {
      type: 'pie',
      height: 330,
    },
    credits: { enabled: false },
    title: false,
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
        showInLegend: true,
        innerSize: 80,
        size: 200,
        states: {
          hover: {
            halo: {
              size: 1,
            },
          },
        },
      },
    },
    colors: chartColors,
    legend: {
      margin: 36,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'vertical',
      itemStyle: {
        color: '#788898',
        fontWeight: 400,
      },
      itemHoverStyle: {
        color: '#cccccc',
      },
      itemMarginBottom: 0,
      symbolRadius: 0,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.2f}%</b> (<b>{point.z}</b>)',
    },
  };
};

export const sortListByValue = (list, value) => {
  return R.sort(
    R.ascend((item) => {
      return item[value];
    }),
    list,
  );
};
