import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { equals, isEmpty, isNil } from 'ramda';
import { useLocale } from 'react-admin';
import {
  FormGroup,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { getGroupsForSelect, getSelectedCommunityId } from '../../store/community/selectors';
import { getCommunityGroups as getCommunityGroupsAction } from '../../store/community/actions';
import { defaultGroupForSelect } from '../../constants';
import styles from './GroupSelect.module.scss';

const GroupSelect = memo(({
  options,
  onChange,
  label,
  id,
  className,

  communityId,
  getCommunityGroups,
}) => {
  const locale = useLocale();

  useEffect(() => {
    if (!isNil(communityId)) {
      getCommunityGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  const [selectedOption, setSelectedOption] = useState(defaultGroupForSelect);
  const onSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
  };
  const isSelectDisabled = options.length === 0;

  useEffect(() => {
    if (equals(selectedOption, defaultGroupForSelect) && !isEmpty(options)) {
      onSelect(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedOption]);

  return (
    <FormGroup className={className}>
      {label && (
        <Label for={id} className="font-weight-bold">
          {label}
        </Label>
      )}
      <UncontrolledButtonDropdown id={id} className={styles.selectWrapper}>
        <DropdownToggle
          className={cls(styles.selectPlaceholder, 'rounded')}
          disabled={isSelectDisabled}
          color="default"
          size="lg"
        >
          {selectedOption.label[locale]}
          <b className={cls('fa', 'fa-angle-down', styles.caret)} />
        </DropdownToggle>
        <DropdownMenu className={styles.selectMenu}>
          {options.map((option) => {
            const onClick = () => {
              onSelect(option);
            };

            return (
              <DropdownItem onClick={onClick} key={option.value}>
                <img
                  width={25}
                  height={25}
                  src={option.icon}
                  alt=""
                  className={cls('mr-3', {
                    [styles.emptyImage]: isNil(option.icon),
                  })}
                />
                {option.label[locale]}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </FormGroup>
  );
});

GroupSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.shape({
        en: PropTypes.string,
        he: PropTypes.string,
      }).isRequired,
      description: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  communityId: PropTypes.number,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,

  onChange: PropTypes.func.isRequired,
  getCommunityGroups: PropTypes.func.isRequired,
};

GroupSelect.defaultProps = {
  communityId: null,
  label: null,
  id: null,
  className: null,
};

export default connect(
  (state) => {
    return {
      options: getGroupsForSelect(state),
      communityId: getSelectedCommunityId(state),
    };
  }, {
    getCommunityGroups: getCommunityGroupsAction,
  },
)(GroupSelect);
