import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as actions from '../../store/items/actions';
import * as selectors from '../../store/items/selectors';
import { getSelectedCommunityId } from '../../store/community/selectors';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import ItemsTable from './ItemsTable';

const Items = ({ communityId, items, isLoading, getItems, hideItem }) => {
  const translate = useTranslate();

  useEffect(() => {
    if (!R.isNil(communityId)) {
      getItems({ isLoadMore: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <PageContent title={translate('pages.items.title.page')}>
      <Widget>
        <ItemsTable
          items={items}
          getItems={getItems}
          hideItem={hideItem}
          isLoading={isLoading}
        />
      </Widget>
    </PageContent>
  );
};

Items.propTypes = {
  communityId: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getItems: PropTypes.func.isRequired,
  hideItem: PropTypes.func.isRequired,
};

Items.defaultProps = {
  communityId: null,
};

export default connect(
  (state) => ({
    communityId: getSelectedCommunityId(state),
    items: selectors.getItems(state),
    isLoading: selectors.getIsItemsLoading(state),
  }),
  {
    getItems: actions.getCommunityItems,
    hideItem: actions.hideItem,
  },
)(Items);
