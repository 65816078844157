const prefix = 'community/';

export const SET_COMMUNITY_ID = `${prefix}SET_COMMUNITY_ID`;
export const SET_GROUP_ID = `${prefix}/SET_GROUP_ID`;

export const GET_COMMUNITIES = `${prefix}GET_COMMUNITIES`;
export const GET_COMMUNITIES_SUCCESS = `${prefix}GET_COMMUNITIES_SUCCESS`;

export const GET_COMMUNITY_GROUPS = `${prefix}GET_COMMUNITY_GROUPS`;
export const GET_COMMUNITY_GROUPS_SUCCESS = `${prefix}GET_COMMUNITY_GROUPS_SUCCESS`;

export const GET_COMMUNITY_ANALYTICS = `${prefix}GET_COMMUNITY_ANALYTICS`;
export const GET_COMMUNITY_ANALYTICS_SUCCESS = `${prefix}GET_COMMUNITY_ANALYTICS_SUCCESS`;

export const UPDATE_COMMUNITY_INFO = `${prefix}UPDATE_COMMUNITY_INFO`;
export const UPDATE_COMMUNITY_INFO_SUCCESS = `${prefix}UPDATE_COMMUNITY_INFO_SUCCESS`;

export const UPDATE_GROUP_INFO = `${prefix}UPDATE_GROUP_INFO`;
export const UPDATE_GROUP_INFO_SUCCESS = `${prefix}UPDATE_GROUP_INFO_SUCCESS`;
export const UPDATE_GROUPS_ORDER = `${prefix}UPDATE_GROUPS_ORDER`;

