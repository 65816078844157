import React from 'react';
import { connect } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTranslate } from 'react-admin';
import format from 'date-fns/format';

import styles from './TotalItemsUploaded.module.scss';
import { getTotalItemsUploaded } from '../../../../../store/reports/selectors';

const TotalItemsUploaded = ({ itemsUploaded }) => {
  const translate = useTranslate();
  const formattedData = itemsUploaded?.map((item) => ({
    ...item,
    count: Number(item.count),
    value: Number(item.value),
    date: format(new Date(item.date), 'MMM dd'),
  }));

  const groupedData = formattedData?.reduce((acc, item) => {
    const existingEntry = acc?.find((entry) => entry.date === item.date);

    if (existingEntry) {
      existingEntry[item.category] = item.count;
      existingEntry[`${item.category}_value`] = item.value;
    } else {
      acc.push({
        date: item.date,
        [item.category]: item.count,
        [`${item.category}_value`]: item.value,
      });
    }

    return acc;
  }, []);

  const uniqueCategories = [...new Set(itemsUploaded?.map((item) => item.category))];

  const colors = [
    '#FF6F61', '#FFB347', '#FFD700', '#77DD77', '#779ECB',
    '#C23B22', '#FF6961', '#FFB07C', '#FFCC99', '#B5E7A0',
    '#9FE2BF', '#AEC6CF', '#FFD1DC', '#CFCFC4', '#966FD6',
    '#F49AC2', '#FF4500', '#F0E68C', '#00CED1', '#F5DEB3'
  ];


  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{translate('pages.reports.total_items_uploaded')}</h1>

      <div className={styles.graph}>
        <h1 style={{ paddingTop: '4rem' }}>{translate('pages.reports.count')}</h1>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={groupedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueCategories?.map((category, index) => (
              <Bar
                key={index}
                dataKey={category}
                name={category}
                stackId="a"
                fill={colors[index % colors.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.graph}>
        <h1 style={{ paddingTop: '4rem' }}>{translate('pages.reports.value')}</h1>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={groupedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueCategories?.map((category, index) => (
              <Bar
                key={index}
                dataKey={`${category}_value`}
                name={category}
                stackId="a"
                fill={colors[index % colors.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    itemsUploaded: getTotalItemsUploaded(state),
  };
})(TotalItemsUploaded);
