import React from 'react';
import { Route } from 'react-router-dom';

import CommunityInfo from '../pages/CommunityInfo';
import GroupInfo from '../pages/GroupInfo';
// import Rules from '../pages/Rules';
// import Features from '../pages/Features';
import Categories from '../pages/Categories';
import Brands from '../pages/Brands';
// import Conditions from '../pages/Conditions';
// import Sizes from '../pages/Sizes';
import Members from '../pages/Members';
import MemberProfile from '../pages/MemberProfile';
import Transactions from '../pages/Transactions';
import Reports from '../pages/Reports';
import Items from '../pages/Items';
// import Wallets from '../pages/Wallets';
// import Cities from '../pages/Cities';
import Talents from '../pages/Talents';
import Subcommunities from '../pages/Subcommunities/ListSubCommunities';
import CreateSubCommunity from '../pages/Subcommunities/CreateSubCommunity';
import KnowledgeLibrary from '../pages/KnowledgeLibrary';

const Routes = [
  <Route path="/communityInfo" component={CommunityInfo} />,
  <Route path="/spaces" component={Subcommunities} />,
  <Route path="/create-space" component={CreateSubCommunity} />,
  <Route path="/edit-space/:spaceId" component={CreateSubCommunity} />,
  <Route path="/groupInfo" component={GroupInfo} />,
  // <Route path="/rules" component={Rules} />,
  // <Route path="/features" component={Features} />,
  <Route path="/categories" component={Categories} />,
  <Route path="/brands" component={Brands} />,
  // <Route path="/conditions" component={Conditions} />,
  // <Route path="/sizes" component={Sizes} />,
  <Route path="/members" component={Members} exact />,
  <Route path="/members/:memberId" component={MemberProfile} />,
  <Route path="/transactions" component={Transactions} />,
  <Route path="/knowledge-library" component={KnowledgeLibrary} />,
  <Route path="/reports" component={Reports} />,
  <Route path="/items" component={Items} exact />,
  <Route path="/talents" component={Talents} exact />,
  // <Route path="/wallets" component={Wallets} exact />,
  // <Route path="/cities" component={Cities} />,
];

export default Routes;
