import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/brands/actions';
import { modalModes } from '../../const';
import { getIsBrandNameUnique } from '../../../store/brands/selectors';
import EditableTextarea from '../../../components/EditableTextarea';

const BrandsModal = ({
  options,
  closeModal,
  addBrand,
  updateBrand,
}) => {
  const translate = useTranslate();

  const [name, setName] = useState(options.name);
  const isNameUnique = useSelector((state) => {
    return getIsBrandNameUnique(state, name);
  });

  const isNameInvalid = !name || !isNameUnique;
  const isEditMode = options.mode === modalModes.EDIT;

  const onSaveClick = () => {
    const onSaveFunc = isEditMode
      ? updateBrand
      : addBrand;

    const data = isEditMode
      ? { id: options.id, name }
      : { name };

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.brands.modal.header.edit')
          : translate('pages.brands.modal.header.add')
        }
      </Modal.Header>
      <Modal.Body>
        <EditableTextarea
          value={name}
          onInputChange={setName}
          label={translate('pages.brands.labels.brandName')}
          maxLength={20}
          id="brandName"
          showButtons={false}
          isValid={!isNameInvalid}
          validationText={translate('pages.brands.validation.brandName')}
          useValidation
        />
      </Modal.Body>
      <Modal.Footer
        isSaveButtonDisabled={isNameInvalid}
        onSave={onSaveClick}
        onCancel={closeModal}
      />
    </>
  );
};

BrandsModal.propTypes = {
  options: PropTypes.shape({
    mode: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  addBrand: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
};

BrandsModal.defaultProps = {
  options: {},
};

export default connect(null, {
  addBrand: actions.addBrand,
  updateBrand: actions.updateBrand,
})(BrandsModal);
