import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { NavLink } from 'react-router-dom';
import getIsRTL from '../../utils/isRTL';
import styles from './PageDescription.module.scss';

const PageDescription = ({ path }) => {
  const translate = useTranslate();
  const isRTL = getIsRTL();
  return (
    <p className={cls(styles.pageDescription, 'mb-5')}>
      {isRTL
        ? translate(`${path}.right`)
        : translate(`${path}.left`)
      }
      <NavLink
        to="/features"
        className={styles.pageDescriptionLink}
      >
        {translate(`${path}.link`)}
      </NavLink>
      {isRTL
        ? translate(`${path}.left`)
        : translate(`${path}.right`)
      }
    </p>
  );
};

PageDescription.propTypes = {
  path: PropTypes.string.isRequired,
};

export default PageDescription;
