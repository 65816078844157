import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback as debounce } from 'use-debounce';
import { useTranslate } from 'react-admin';
import { MIN_COMMUNITY_NAME_LENGTH, VALIDATE_DEBOUNCE } from '../../../constants/validation';
import EditableTextarea from '../../../components/EditableTextarea';

const CommunityNameField = ({
  initialValue,
  validationError,
  id,
  validateName,
  updateCommunityInfo,
}) => {
  const translate = useTranslate();
  const [name, setName] = useState(initialValue);

  useEffect(() => {
    setName(initialValue);
  }, [initialValue]);

  const onInputChange = debounce((value) => {
    setName(value);
    validateName({
      fieldId: id,
      minLength: MIN_COMMUNITY_NAME_LENGTH,
      value,
    });
  }, VALIDATE_DEBOUNCE);

  const onSave = () => {
    const data = new FormData();
    data.append('community_name', name);

    updateCommunityInfo(data);
  };

  const isValid = !validationError;
  const isValueChanged = name !== initialValue;
  const isSaveButtonDisabled = Boolean(validationError) || !isValueChanged;

  return (
    <EditableTextarea
      value={initialValue}
      onInputChange={onInputChange.callback}
      onSave={onSave}
      isSaveButtonDisabled={isSaveButtonDisabled}
      id={id}
      maxLength={22}
      label={translate('pages.communityInfo.labels.name')}
      isValid={isValid}
      validationText={
        isValid
          ? null
          : translate(validationError)
      }
      useValidation={isValueChanged}
      marginTop={false}
      useCustomLabel
    />
  );
};

CommunityNameField.propTypes = {
  initialValue: PropTypes.string,
  validationError: PropTypes.string,
  id: PropTypes.string.isRequired,
  validateName: PropTypes.func.isRequired,
  updateCommunityInfo: PropTypes.func.isRequired,
};

CommunityNameField.defaultProps = {
  initialValue: null,
  validationError: null,
};

export default CommunityNameField;
