import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import * as knowledgeLibraryActions from '../../store/knowledgeLibrary/actions';
import * as knowledgeLibrarySelectors from '../../store/knowledgeLibrary/selectors';
//import * as knowledgeLibrarySelectors from '../../store/knowledgeLibrary/selectors';
import PageContent from '../../components/PageContent';
import TabsPanel from '../../components/TabsAccordion/TabsPanel';
import TabsContent from '../../components/TabsAccordion/TabsContent';
import KnowledgeLibraryTable from './KnowledgeLibraryTable';

const KnowledgeLibrary = ({
  posts,
 // isLoading,
  getPosts,
}) => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const filterType = 'Pending';

  const makeGetPostsFunc = () => {
    return getPosts;
  };

  console.log('posts', posts);

   const getLibraryPosts = makeGetPostsFunc();

   useEffect(() => {
     if (queryParams.type) {
      // setMemberFilterType(queryParams.type);
     } else {
      getLibraryPosts();
     }
      //eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  // useEffect(() => {
  //   getItems({ isLoadMore: false });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterType]);

  const tabOptions = [
    {
      value: 'Pending',
      label: translate('pages.knowledgeLibrary.labels.pending'),
    },
    {
      value: 'Adm',
      label: translate('pages.knowledgeLibrary.labels.adm'),
    },
  ];

  const onTabClick = (value) => {
    setMemberFilterType(value);
  };

  return (
    <PageContent
      title={translate('pages.knowledgeLibrary.title.page')}
    >
      <TabsPanel options={tabOptions} activeTab={filterType} onTabClick={onTabClick} />

      <TabsContent>
        <KnowledgeLibraryTable
          posts={posts}
          getPosts={getLibraryPosts}
          type={filterType}
       //   isLoading={isLoading}
          searchTerm={queryParams.searchTerm}
        />
      </TabsContent>
    </PageContent>
  );
};

KnowledgeLibrary.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getPosts: PropTypes.func.isRequired,
  getSuspensions: PropTypes.func.isRequired,
  getSuspicions: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      posts: knowledgeLibrarySelectors.getPosts(state),
      // isLoading: knowledgeLibrarySelectors.getIsLoading(state),
    };
  }, {
  getPosts: knowledgeLibraryActions.getPosts,
},
)(KnowledgeLibrary);
