// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Dashboard_pageTitle__1gb91 {\n  font-family: Montserrat, sans-serif;\n  font-weight: 600;\n}\n\n.Dashboard_pageSubTitle__3Aqlj {\n  display: block;\n  color: #47494C;\n  font-size: 1.2rem;\n}\n\n.Dashboard_lineChartsWrapper__2i2Um {\n  grid-template-columns: 1fr;\n  grid-gap: 1rem;\n}\n\n.Dashboard_columnChartsWrapper__27aos {\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 1rem;\n}", ""]);
// Exports
exports.locals = {
	"pageTitle": "Dashboard_pageTitle__1gb91",
	"pageSubTitle": "Dashboard_pageSubTitle__3Aqlj",
	"lineChartsWrapper": "Dashboard_lineChartsWrapper__2i2Um",
	"columnChartsWrapper": "Dashboard_columnChartsWrapper__27aos"
};
module.exports = exports;
