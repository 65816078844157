import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import {
  FormGroup,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import Icon, { iconSets, getIconTypes } from '../Icon';
import styles from './IconSelect.module.scss';

const IconSelect = memo(({
  onChange,
  selectedOption: initialSelectedOption,
  label,
  id,
}) => {
  const options = Object.values(getIconTypes(iconSets.main));

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption || 'Select icon');

  const onSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  const isSelectDisabled = options.length === 0;

  return (
    <FormGroup>
      {label && (
        <Label for={id} className="font-weight-bold">
          {label}
        </Label>
      )}
      <UncontrolledButtonDropdown id={id} className={styles.selectWrapper}>
        <DropdownToggle
          className={cls(styles.selectPlaceholder, 'rounded')}
          disabled={isSelectDisabled}
          color="default"
          size="lg"
        >
          {selectedOption}
          <b className={cls('fa', 'fa-angle-down', styles.caret)} />
        </DropdownToggle>
        <DropdownMenu className={styles.selectMenu}>
          {options.map((option) => {
            const onClick = () => {
              onSelect(option);
            };

            return (
              <DropdownItem onClick={onClick} key={option}>
                <Icon
                  type={option}
                  set={iconSets.main}
                  className="mr-3"
                  width={25}
                  height={25}
                />
                {option}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </FormGroup>
  );
});

IconSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
};

IconSelect.defaultProps = {
  label: null,
  id: null,
  selectedOption: null,
  onChange: () => {},
};

export default IconSelect;
