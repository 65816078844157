import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as sizesActions from '../../../store/sizes/actions';
import { modalModes } from '../../const';
import getIsRTL from '../../../utils/isRTL';
import Modal from '../../components';
import EditableTextarea from '../../../components/EditableTextarea';

const SizesModal = ({
  options,
  closeModal,
  addSize,
  updateSize,
}) => {
  const translate = useTranslate();
  const isEditMode = options.mode === modalModes.EDIT;
  const isRTL = getIsRTL();

  const [titleEn, setTitleEn] = useState(options.titleEn);
  const [titleHe, setTitleHe] = useState(options.titleHe);
  const isNameInvalid = !titleEn;

  const onSaveClick = () => {
    const onSaveFunc = isEditMode
      ? updateSize
      : addSize;

    const data = isEditMode
      ? { sizeId: options.id, titleEn, titleHe }
      : { titleEn, titleHe };

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.sizes.modal.header.edit')
          : translate('pages.sizes.modal.header.create')
        }
      </Modal.Header>
      <Modal.Body>
        <EditableTextarea
          value={titleEn}
          onInputChange={setTitleEn}
          label={translate('pages.sizes.labels.titleEn')}
          id="titleEn"
          showButtons={false}
          isValid={Boolean(titleEn)}
          validationText={translate('general.validation.notEmpty')}
          useValidation={!isRTL}
        />

        <EditableTextarea
          value={titleHe}
          onInputChange={setTitleHe}
          label={translate('pages.sizes.labels.titleHe')}
          id="titleHe"
          showButtons={false}
          dir="rtl"
          isValid={Boolean(titleHe)}
          validationText={translate('general.validation.notEmpty')}
          useValidation={isRTL}
        />

      </Modal.Body>
      <Modal.Footer
        isSaveButtonDisabled={isNameInvalid}
        onSave={onSaveClick}
        onCancel={closeModal}
      />
    </>
  );
};

SizesModal.propTypes = {
  options: PropTypes.shape({
    mode: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    titleEn: PropTypes.string,
    titleHe: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  addSize: PropTypes.func.isRequired,
  updateSize: PropTypes.func.isRequired,
};

SizesModal.defaultProps = {
  options: {},
};

export default connect(null, {
  addSize: sizesActions.addSize,
  updateSize: sizesActions.updateSize,
})(SizesModal);
