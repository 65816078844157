import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import { useDebouncedCallback as debounce } from 'use-debounce';
import {
  TALENTS_PAGE_LIMIT,
  DEFAULT_SEARCH_TERM,
  SEARCH_DELAY_TIME,
  talentStatuses,
} from '../../../constants/talents';
import { formatDateWithLocale } from '../../../utils/dateFns';
import Table from '../../../components/Table';
import Text, { textProps } from '../../../components/Text';
import ButtonWithPopover from '../../../components/Button/ButtonWithPopover';
import Button, { buttonProps } from '../../../components/Button';
import Search from '../../../components/Search';
import styles from './TalentsTable.module.scss';

const TalentsTable = ({ talents, isLoading, getTalents, hideTalent }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const [searchTerm, setSearchTerm] = useState(DEFAULT_SEARCH_TERM);

  const itemInfoFormatter = (value, row) => {
    return (
      <div className={styles.infoWrapper}>
        <img src={row.itemImage} alt={row.itemTitle} className={styles.itemImage} />
        <div className={styles.infoText}>
          <Text size={textProps.sizes.small} weight={textProps.weights.semiBold}>
            {row.itemTitle}
          </Text>
          <Text size={textProps.sizes.xSmall}>
            {`(# ${value})`}
          </Text>
        </div>
      </div>
    );
  };

  const sellerInfoFormatter = (value, row) => {
    return (
      <Link to={`/members/${value}`}>
        <div className={styles.infoWrapper}>
          <img src={row.sellerImage} className={styles.avatar} alt={row.sellerName} />
          <Text
            size={textProps.sizes.small}
            weight={textProps.weights.semiBold}
            color={textProps.colors.inherit}
          >
            {row.sellerName}
          </Text>
        </div>
      </Link>
    );
  };

  const priceFormatter = (value) => {
    return (
      <Text>{value}</Text>
    );
  };

  const createdAtFormatter = (value) => {
    return formatDateWithLocale(value, locale);
  };

  const multipleSupplyFormatter = (value) => {
    if (value === true) {
      return (
        <i className={cls('fa', 'fa-check', styles.multipleSupplyIcon, 'text-success')} />
      );
    }

    return (
      <i className={cls('fa', 'fa-times', styles.multipleSupplyIcon, 'text-danger')} />
    );
  };

  const statusFormatter = (value) => {
    const textColorsMap = {
      [talentStatuses.ACTIVE]: textProps.colors.success,
      [talentStatuses.SOLD]: textProps.colors.danger,
      [talentStatuses.PENDING]: textProps.colors.secondary,
      [talentStatuses.HIDDEN]: textProps.colors.warning,
      [talentStatuses.REMOVED]: textProps.colors.black,
    };

    return (
      <Text
        size={textProps.sizes.small}
        weight={textProps.weights.semiBold}
        color={textColorsMap[value]}
      >
        {translate(`pages.talents.table.statuses.${value}`)}
      </Text>
    );
  };

  const actionsFormatter = (_, row) => {
    const onHideTalentClick = () => {
      hideTalent(row.id);
    };

    const isPopoverDisabled = row.status === talentStatuses.HIDDEN;

    return (
      <ButtonWithPopover
        id={`button-${row.id}`}
        title={
          <i className={cls('fa', 'fa-ellipsis-v')} />
        }
        disabled={isPopoverDisabled}
      >
        <Button
          onClick={onHideTalentClick}
          size={buttonProps.sizes.null}
          theme={buttonProps.themes.link}
          marginTop={false}
        >
          {translate('pages.talents.table.buttons.hideItem')}
        </Button>
      </ButtonWithPopover>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.talents.table.columns.talent'),
      align: 'start',
      headerAlign: 'start',
      formatter: itemInfoFormatter,
    },
    {
      dataField: 'sellerId',
      text: translate('pages.talents.table.columns.seller'),
      align: 'start',
      headerAlign: 'start',
      formatter: sellerInfoFormatter,
    },
    {
      dataField: 'price',
      text: translate('pages.talents.table.columns.price'),
      formatter: priceFormatter,
    },
    {
      dataField: 'originalPrice',
      text: translate('pages.talents.table.columns.originalPrice'),
      formatter: priceFormatter,
    },
    {
      dataField: 'createdAt',
      text: translate('pages.talents.table.columns.createdAt'),
      formatter: createdAtFormatter,
    },
    {
      dataField: 'hasMultipleSupply',
      text: translate('pages.talents.table.columns.hasMultipleSupply'),
      formatter: multipleSupplyFormatter,
    },
    {
      dataField: 'status',
      text: translate('pages.talents.table.columns.status'),
      formatter: statusFormatter,
    },
    {
      dataField: '',
      text: translate('pages.talents.table.columns.actions'),
      formatter: actionsFormatter,
    },
  ];

  const onPageChange = (requestedPage) => {
    getTalents({ isLoadMore: true, nextPage: requestedPage + 1, searchTerm });
  };

  const onSearchChange = debounce((term) => {
    setSearchTerm(term);
    getTalents({ isLoadMore: false, searchTerm: term });
  }, SEARCH_DELAY_TIME);

  const paginationProps = {
    onPageChange,
    sizePerPage: TALENTS_PAGE_LIMIT,
  };

  return (
    <>
      <div className={cls(styles.searchWrapper, 'mb-5')}>
        <Search
          onChange={onSearchChange.callback}
          placeholder={translate('pages.talents.table.search.placeholder')}
          className={styles.searchPanel}
        />
      </div>

      <Table
        data={talents}
        columns={columns}
        paginationProps={paginationProps}
        isLoading={isLoading}
        useOverlay
      />
    </>
  );
};

TalentsTable.propTypes = {
  talents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    itemTitle: PropTypes.string,
    itemImage: PropTypes.string,
    sellerId: PropTypes.string,
    sellerName: PropTypes.string,
    sellerImage: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    originalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createdAt: PropTypes.string,
    hasMultipleSupply: PropTypes.bool,
    status: PropTypes.string,
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTalents: PropTypes.func.isRequired,
  hideTalent: PropTypes.func.isRequired,
};

export default TalentsTable;
