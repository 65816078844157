import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import { toggleSidebar, useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { Navbar, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import LocaleSelect from '../../components/LocaleSelect';
import CommunitySelect from '../../components/CommunitySelect';
import UserMenu from './UserMenu';
import { toggleSidebarPinned } from '../../store/ui/actions';
import { setCommunityId } from '../../store/community/actions';
import { getUserInfo } from '../../store/user/selectors';
import styles from './AppBar.module.scss';

const AppBar = () => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const translate = useTranslate();

  const onClick = useCallback(() => {
    dispatch(toggleSidebar());

    if (!isXSmall) {
      dispatch(toggleSidebarPinned());
    }
  }, [dispatch, isXSmall]);
  const onChangeCommunity = ({ value: id }) => {
    dispatch(setCommunityId(id));
  };

  return (
    <Navbar className={styles.root}>
      <Nav>
        <NavItem>
          <NavLink id="toggleSidebar" onClick={onClick}>
            <i className={cls('la', 'la-bars')} />
          </NavLink>
          {!isXSmall && (
            <UncontrolledTooltip placement="bottom" target="toggleSidebar">
              {translate('general.hints.sidebarButton')}
            </UncontrolledTooltip>
          )}
        </NavItem>
      </Nav>

      <CommunitySelect onChange={onChangeCommunity} />

      <LocaleSelect />
      {userInfo && (
        <UserMenu userInfo={userInfo} />
      )}
    </Navbar>
  );
};

export default AppBar;
