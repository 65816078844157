import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import CodeInput from 'react-code-input';
import styles from './ConfirmationCodeInput.module.scss';
import 'react-code-input/styles/style.scss';

const ConfirmationCodeInput = ({ onChange, value }) => {
  return (
    <div className={styles.codeInputWrapper}>
      <CodeInput
        type="number"
        fields={4}
        name="code"
        inputMode="numeric"
        value={value}
        onChange={onChange}
        className={cls(styles.codeInputGroup, styles.inputGroup)}
        autoFocus
      />
    </div>
  );
};

ConfirmationCodeInput.propTypes = {
  value: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

ConfirmationCodeInput.defaultProps = {
  value: '',
};

export default ConfirmationCodeInput;
