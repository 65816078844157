import { createSelector } from 'reselect';
import { getParamWithoutStateInSelector } from '../../utils/store';

export const getValidationErrors = (state) => {
  return state.validation.errors;
};

export const getValidationErrorsById = createSelector(
  [getValidationErrors, getParamWithoutStateInSelector],
  (errors, id) => {
    return errors[id];
  },
);
