import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { ReactSortable as Sortable } from 'react-sortablejs';
import SortableGridItem from './SortableGridItem';
import { getIsListsEqual } from '../../../utils/store';
import styles from './SortableGrid.module.scss';

const SortableGrid = memo(({
  items,
  setIsCategoryActive,
  reorderCategory,
  openModal,
}) => {
  const [list, setList] = useState(items);

  useEffect(() => {
    const isListsEqual = getIsListsEqual(list, items, ['order', 'chosen']);

    if (!isListsEqual) {
      setListWithOrder(items);
    }
  }, [items, list]);

  const setListWithOrder = (newList) => {
    const listWithUpdatedOrder = newList.map((item, index) => {
      return { ...item, order: index };
    });

    setList(listWithUpdatedOrder);
  };

  const onSortEnd = ({ item, newIndex: order }) => {
    const id = item.getAttribute('data-id');

    reorderCategory({
      id: parseInt(id, 10),
      order,
    });
  };

  return (
    <Sortable
      list={list}
      setList={setListWithOrder}
      onSort={onSortEnd}
      className={cls('d-grid', styles.sortableGrid)}
    >
      {list.map(({ id, name, iconName, isActive }) => {
        return (
          <SortableGridItem
            key={id}
            id={id}
            name={name}
            iconName={iconName}
            setIsCategoryActive={setIsCategoryActive}
            reorderCategory={reorderCategory}
            openModal={openModal}
            isActive={isActive}
          />
        );
      })}
    </Sortable>
  );
});

SortableGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      iconName: PropTypes.string,
    }),
  ).isRequired,
  setIsCategoryActive: PropTypes.func.isRequired,
  reorderCategory: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default SortableGrid;
