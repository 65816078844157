// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ConditionsModal_modalHeader__MKe6F {\n  align-self: center;\n}\n.ConditionsModal_modalHeader__MKe6F > h5 {\n  font-weight: bold;\n}\n\n.ConditionsModal_fieldset__1tmbP {\n  border: 1px solid #47494C;\n}\n\n.ConditionsModal_legend__1Eb5o {\n  width: initial;\n  padding: 0 0.9rem;\n  border-bottom: 0;\n  color: #47494C;\n  font-size: 1.1rem;\n  font-weight: 600;\n}", ""]);
// Exports
exports.locals = {
	"modalHeader": "ConditionsModal_modalHeader__MKe6F",
	"fieldset": "ConditionsModal_fieldset__1tmbP",
	"legend": "ConditionsModal_legend__1Eb5o"
};
module.exports = exports;
