export const updateReportIds = (reports) => {
  return Object.keys(reports).reduce((acc, type) => {
    const reportsWithId = reports[type].map((report) => {
      return {
        ...report,
        id: `${report.id}-${type}`,
      };
    });

    return {
      ...acc,
      [type]: reportsWithId,
    };
  }, {});
};
