import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Loader.module.scss';

const Loader = ({ size, className }) => {
  return (
    <div className={cls(styles.root, className)}>
      <i className="las la-circle-notch la-spin" style={{ fontSize: size }} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

Loader.defaultProps = {
  size: 21,
  className: '',
};

export default Loader;
