import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { modalModes } from '../../../modals/const';
import Button, { buttonProps } from '../../../components/Button';
import TextLabel, { textLabelColors } from '../../../components/TextLabel';
import styles from './BrandsList.module.scss';

const BrandsList = memo(({
  items,
  toggleBrandActive,

  openModal,
}) => {
  const translate = useTranslate();

  return (
    <div
      className={cls('list-group', 'list-group-lg')}
    >
      {items.map((item) => {
        const onToggleActiveClick = () => {
          toggleBrandActive({ id: item.id, isActive: !item.isActive });
        };
        const onEditBrandClick = () => {
          openModal({ id: item.id, name: item.name, mode: modalModes.EDIT });
        };

        const isDeactivated = !item.isActive;

        return (
          <div
            key={item.id}
            className={cls('list-group-item', styles.itemWrapper)}
          >
            <div className={styles.itemName}>
              {item.name}
              {isDeactivated && (
                <TextLabel color={textLabelColors.danger}>
                  {translate('pages.brands.labels.deactivated')}
                </TextLabel>
              )}
            </div>

            <div className={styles.itemButtons}>
              <Button
                size={buttonProps.sizes.null}
                theme={buttonProps.themes.link}
                color={buttonProps.themes.secondary}
                onClick={onEditBrandClick}
              >
                <i className={cls('fi', 'flaticon-edit', styles.itemButtonsIcon)} />
              </Button>
              <Button
                size={buttonProps.sizes.null}
                theme={buttonProps.themes.link}
                color={buttonProps.themes.secondary}
                onClick={onToggleActiveClick}
              >
                <i className={cls('fi', 'flaticon-trash', styles.itemButtonsIcon)} />
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
});

BrandsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  toggleBrandActive: PropTypes.func.isRequired,

  openModal: PropTypes.func.isRequired,
};

export default BrandsList;
