import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { useTranslate } from 'react-admin';

import { createIssueTransaction } from '../../../store/transactions/actions';
import Modal from '../../components';
import UserSelect from '../../../pages/Transactions/UserSelect';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';
import styles from './TransactionModal.module.scss';

const TransactionsModal = ({
  onSave,
  closeModal,
}) => {
  const translate = useTranslate();

  const [receiverIds, setReceiverIds] = useState([]);
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState('');

  const isReceiverIdsFieldValid = !isEmpty(receiverIds);
  const isAmountFieldValid = !!amount;
  const isNoteFieldValid = !!note;

  const isSaveButtonDisabled = (
    !isReceiverIdsFieldValid ||
    !isAmountFieldValid ||
    !isNoteFieldValid
  );

  const onSaveButtonClick = () => {
    const data = {
      receiverIds,
      amount,
      note,
    };

    onSave(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {translate('pages.transactions.modal.title')}
      </Modal.Header>

      <Modal.Body>
        <UserSelect
          onSelect={setReceiverIds}
          label={translate('pages.transactions.modal.select.label')}
        />

        <EditableTextarea
          value={amount}
          onInputChange={setAmount}
          id="amount"
          type={inputTypes.number}
          label={translate('pages.transactions.modal.amount.label')}
          validationText={translate('pages.transactions.modal.amount.validation')}
          inputGroupClassName={styles.inputField}
          maxLength={5}
          min={1}
          max={20000}
          step={1}
          isValid={isAmountFieldValid}
          showButtons={false}
          useValidation
        />

        <EditableTextarea
          value={note}
          onInputChange={setNote}
          id="note"
          label={translate('pages.transactions.modal.note.label')}
          validationText={translate('pages.transactions.modal.note.validation')}
          inputGroupClassName={styles.inputField}
          isValid={isNoteFieldValid}
          showButtons={false}
          marginTop={false}
          useValidation
        />
      </Modal.Body>

      <Modal.Footer
        onSave={onSaveButtonClick}
        onCancel={closeModal}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </>
  );
};

TransactionsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, {
  onSave: createIssueTransaction,
})(TransactionsModal);
