const prefix = 'conditions/';

export const SET_GROUP_ID = `${prefix}SET_GROUP_ID`;

export const SET_CONDITIONS = `${prefix}SET_CONDITIONS`;

export const ADD_CONDITION = `${prefix}ADD_CONDITION`;
export const ADD_CONDITION_SUCCESS = `${prefix}ADD_CONDITION_SUCCESS`;

export const UPDATE_CONDITION = `${prefix}UPDATE_CONDITION`;
export const UPDATE_CONDITION_SUCCESS = `${prefix}UPDATE_CONDITION_SUCCESS`;

export const TOGGLE_CONDITION_ACTIVE = `${prefix}TOGGLE_CONDITION_ACTIVE`;
