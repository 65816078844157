import React from 'react';

const Input = ({ label, type = 'text', placeholder, value, onChange }) => {
  const inputClass = type === 'textarea'
    ? 'border-[1px] border-gray2 rounded-lg p-2 w-full text-sm text-black h-[85px] focus:border-customColor'
    : 'border-[1px] border-gray2 rounded-lg p-2 w-full text-sm text-black h-[43px] focus:border-customColor';

  return (
    <div className="w-full">
      <label className="block text-black text-sm font-medium">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          className={inputClass}
          placeholder={placeholder}
          style={{ outline: 'none' }}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          type={type}
          className={inputClass}
          placeholder={placeholder}
          style={{ outline: 'none' }}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default Input;
