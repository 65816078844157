export const TALENTS_INITIAL_LIMIT = 75;
export const TALENTS_PAGE_LIMIT = 25;
export const DEFAULT_SEARCH_TERM = '';
export const SEARCH_DELAY_TIME = 300;

export const talentStatuses = {
  ACTIVE: 'active',
  SOLD: 'sold',
  PENDING: 'pending',
  HIDDEN: 'hidden',
  REMOVED: 'removed',
};
