import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';

const Switcher = ({
  checked,
  id,
  onChange,
}) => {
  const checkboxId = `checkbox-${id}`;

  return (
    <Label
      for={checkboxId}
      className="switch"
    >
      <Input
        type="checkbox"
        id={checkboxId}
        checked={checked}
        onChange={onChange}
      />
      <i />
    </Label>
  );
};

Switcher.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Switcher.defaultProps = {
  checked: false,
  onChange: () => {},
};

export default Switcher;
