export const appendTypeToTransactions = (transactions, type) => {
  return transactions.map((transaction) => {
    return {
      ...transaction,
      id: `${transaction.id}-${type}`,
      type,
    };
  });
};

export const getExportToSCVFileName = (type) => {
  return `${type}-transactions.csv`;
};
