export const offerTypes = {
  INTERESTED: 0,
  PENDING_DECISION: 1,
  OFFER_CONFIRMED: 2,
  ITEM_DELIVERED: 3,
  NOT_AVAILABLE: 4,
  ITEM_SOLD: 5,
  SELLER_LEFT_COMMUNITY: 6,
  BUYER_LEFT_COMMUNITY: 7,
  ITEM_REMOVED: 8,
};
