import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/members/actions';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';

const SuspensionModal = ({ options, closeModal, suspend, unsuspend }) => {
  const translate = useTranslate();
  const [reason, setReason] = React.useState('');

  const isSaveButtonDisabled = !reason;

  const onSaveClick = () => {
    const onSaveFunc = options.isSuspended
      ? unsuspend
      : suspend;

    const data = { id: options.id, adminId: options.adminId, reason };

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {options.isSuspended
          ? translate('pages.memberInfo.modals.title.suspension.delete')
          : translate('pages.memberInfo.modals.title.suspension.add')
        }
      </Modal.Header>

      <Modal.Body>
        <EditableTextarea
          value={reason}
          onInputChange={setReason}
          type={inputTypes.textarea}
          label={translate('pages.memberInfo.modals.inputs.labels.reason')}
          id="suspensionReason"
          showButtons={false}
        />
      </Modal.Body>

      <Modal.Footer
        onSave={onSaveClick}
        isSaveButtonDisabled={isSaveButtonDisabled}
        onCancel={closeModal}
      />
    </>
  );
};

SuspensionModal.propTypes = {
  options: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    adminId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSuspended: PropTypes.bool,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  suspend: PropTypes.func.isRequired,
  unsuspend: PropTypes.func.isRequired,
};

export default connect(null, {
  suspend: actions.suspendMember,
  unsuspend: actions.unsuspendMember,
})(SuspensionModal);
