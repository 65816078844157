// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.BrandsList_itemWrapper__3P4iy {\n  display: flex !important;\n  flex-flow: row nowrap;\n  align-items: center;\n  font-size: 1.6rem;\n}\n\n.BrandsList_itemName__1s4uh {\n  flex-grow: 1;\n  word-break: break-word;\n  font-weight: 600;\n}\n\n.BrandsList_itemButtons__3aSWr {\n  flex-shrink: 0;\n}\n\n.BrandsList_itemButtonsIcon__HQCtp {\n  font-size: 1.6rem;\n  transition: color 0.3s ease-in-out;\n}\n.BrandsList_itemButtonsIcon__HQCtp:hover {\n  color: #189BC9;\n}", ""]);
// Exports
exports.locals = {
	"itemWrapper": "BrandsList_itemWrapper__3P4iy",
	"itemName": "BrandsList_itemName__1s4uh",
	"itemButtons": "BrandsList_itemButtons__3aSWr",
	"itemButtonsIcon": "BrandsList_itemButtonsIcon__HQCtp"
};
module.exports = exports;
