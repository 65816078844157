import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { getReportedUsers } from '../../../store/reports/selectors';
import Widget from '../../../components/Widget';
import Table from '../../../components/Table';
import styles from './ReportsContent.module.scss';

const ReportedUsersTable = ({ items }) => {
  const translate = useTranslate();

  const idFormatter = (value) => {
    const [id] = value.split('-');
    return id;
  };

  const reportedUserNameFormatter = (value, cell) => {
    return (
      <div>
        <img src={cell.reportedUserImage} className={styles.avatar} alt={value} />
        <span>{value}</span>
      </div>
    );
  };

  const paginationProps = {
    sizePerPage: 10,
  };

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.reports.table.reporters.columns.id'),
      formatter: idFormatter,
    }, {
      dataField: 'reportedUserName',
      text: translate('pages.reports.table.reporters.columns.reporterName'),
      formatter: reportedUserNameFormatter,
      align: 'start',
      headerAlign: 'start',
    }, {
      dataField: 'value',
      text: translate('pages.reports.table.reporters.columns.value'),
    },
  ];

  return (
    <Table
      data={items}
      columns={columns}
      paginationProps={paginationProps}
    />
  );
};

const ReportedUsers = ({ reportedUsers }) => {
  const translate = useTranslate();

  return (
    <div className={cls('d-grid', styles.gridWrapper)}>
      <Widget
        title={translate('pages.reports.labels.table.reporters.item')}
        className={cls(styles.tableWidget, 'shadow-none')}
      >
        <ReportedUsersTable items={reportedUsers.item} />
      </Widget>

      <Widget
        title={translate('pages.reports.labels.table.reporters.user')}
        className={cls(styles.tableWidget, 'shadow-none')}
      >
        <ReportedUsersTable items={reportedUsers.user} />
      </Widget>
    </div>
  );
};

ReportedUsersTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      reportedUserName: PropTypes.string,
      reportedUserImage: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

ReportedUsers.propTypes = {
  reportedUsers: PropTypes.shape({
    user: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        reportedUserName: PropTypes.string,
        reportedUserImage: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    item: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        reportedUserName: PropTypes.string,
        reportedUserImage: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default connect((state) => {
  return {
    reportedUsers: getReportedUsers(state),
  };
})(ReportedUsers);
