import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';

const initialState = {
  isLoading: false,
  isSidebarPinned: false,
  locale: localStorage.getItem('lang'),
};

const rulesReducer = handleActions({
  [AT.SET_LOADER]: deepMerge(({ payload }) => {
    return {
      isLoading: payload,
    };
  }),
  [AT.TOGGLE_SIDEBAR_PINNED]: deepMerge((_, state) => {
    return {
      isSidebarPinned: !state.isSidebarPinned,
    };
  }),
  [AT.CHANGE_LOCALE]: deepMerge(({ payload }) => {
    return {
      locale: payload,
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default rulesReducer;
