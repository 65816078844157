import React from 'react';
import PropTypes from 'prop-types';
import HighChartsReact from 'highcharts-react-official';
import { getColumnChartOptions } from '../../utils/charts';
import Widget from '../Widget';
import styles from './Chart.module.scss';

const ColumnChart = ({
  data,
  title,
  isLoading,
}) => {
  const chartOptions = getColumnChartOptions(data);

  return (
    <Widget
      title={title}
      fetchingData={isLoading}
      className={styles.chartWrapper}
    >
      <HighChartsReact options={chartOptions} />
    </Widget>
  );
};

ColumnChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      y: PropTypes.number,
    }),
  ).isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

ColumnChart.defaultProps = {
  title: null,
};

export default ColumnChart;
