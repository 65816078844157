// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.SortableGridItem_sortableGridItemWrapper__4x_dk {\n  height: auto;\n  background-color: #F5F6F7 !important;\n  border-top-width: 1px !important;\n}\n.SortableGridItem_sortableGridItemWrapper__4x_dk.SortableGridItem_deactivatedItem__29Y3q > *:not(.SortableGridItem_sortableGridItemContent__3Cf6u) {\n  opacity: 0.5;\n}\n.SortableGridItem_sortableGridItemWrapper__4x_dk:hover {\n  background-color: rgb(230.8333333333, 233.25, 235.6666666667) !important;\n  cursor: pointer;\n  transition: all 0.2s ease-in-out;\n}\n\n.SortableGridItem_sortableGridItemContent__3Cf6u {\n  display: flex;\n  flex-flow: row nowrap;\n  border-bottom: 1px solid #47494C;\n}\n\n.SortableGridItem_sortableGridItemInfo__3mzHe {\n  flex-grow: 1;\n}\n\n.SortableGridItem_itemInfoTitle__1H2nI {\n  color: #189BC9;\n  position: relative;\n}\n\n.SortableGridItem_itemIcon__34IPm {\n  box-sizing: content-box;\n  border-radius: 50%;\n  fill: #A44AFE !important;\n  stroke: #A44AFE !important;\n}\n\n.SortableGridItem_sortableGridItemButtons__2s3Wr {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.SortableGridItem_deactivated__3Rwno {\n  position: absolute;\n  top: 25%;\n}\n\n.SortableGridItem_moreIcon__36G-C {\n  font-size: 2rem !important;\n}", ""]);
// Exports
exports.locals = {
	"sortableGridItemWrapper": "SortableGridItem_sortableGridItemWrapper__4x_dk",
	"deactivatedItem": "SortableGridItem_deactivatedItem__29Y3q",
	"sortableGridItemContent": "SortableGridItem_sortableGridItemContent__3Cf6u",
	"sortableGridItemInfo": "SortableGridItem_sortableGridItemInfo__3mzHe",
	"itemInfoTitle": "SortableGridItem_itemInfoTitle__1H2nI",
	"itemIcon": "SortableGridItem_itemIcon__34IPm",
	"sortableGridItemButtons": "SortableGridItem_sortableGridItemButtons__2s3Wr",
	"deactivated": "SortableGridItem_deactivated__3Rwno",
	"moreIcon": "SortableGridItem_moreIcon__36G-C"
};
module.exports = exports;
