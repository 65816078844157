import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PlusIcon from '../../../../../assets/icons/plus.svg';
import MoreVerticalIcon from '../../../../../assets/icons/more-vertical.svg';
import Loader from '../../../../../components/Loader';

const BoxList = ({
  title,
  onAdd,
  data,
  isLoading,
  isModerator = false,
  onRemoveModerator,
  onMakeModerator,
  onRemoveMember,
}) => {
  const [popupVisible, setPopupVisible] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const togglePopup = (id, event) => {
    if (popupVisible === id) {
      setPopupVisible(null);
    } else {
      const rect = event.target.getBoundingClientRect();
      setPopupPosition({
        top: rect.top + window.scrollY + rect.height - 30,
        left: rect.left + window.scrollX - 190,
      });
      setPopupVisible(id);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(null);
  };

  return (
    <div className="relative w-[323px] h-full min-h-[250px] border-[1px] border-gray2 rounded-lg p-4">
      <div className="flex flex-row justify-between items-center">
        <span className="text-lg font-medium text-dark1">{title}</span>
        <button
          className="flex flex-row items-center gap-2 text-base text-bluePrimary"
          onClick={onAdd}
        >
          <img src={PlusIcon} alt="plus" />
          Add
        </button>
      </div>

      <div
        className="flex flex-col gap-2 mt-4 pr-4 mb-4"
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
          marginRight: '-16px',
          paddingRight: '16px',
        }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader />
          </div>
        ) : (
          data?.map((item) => (
            <div
              key={item.id}
              className="flex flex-row items-center justify-between gap-2 relative"
            >
              <div className="flex items-center gap-2">
                <img
                  src={item.profile_image || item.profileImage}
                  alt={item.name}
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-sm font-medium text-dark2">{item.name}</span>
              </div>
              <div>
                <button onClick={(e) => togglePopup(item.id, e)} className="relative">
                  <img src={MoreVerticalIcon} alt="more" />
                </button>
                {popupVisible === item.id &&
                  ReactDOM.createPortal(
                    <div
                      className="absolute bg-white border border-gray2 shadow-md rounded-2xl z-50 w-[181px] py-3 flex flex-col items-center justify-center"
                      style={{
                        top: popupPosition.top,
                        left: popupPosition.left,
                        position: 'absolute',
                      }}
                    >
                      {isModerator ? (
                        <>
                          <button
                            className="block w-full text-center text-[16px] text-red2 font-semibold py-1 hover:bg-gray1 rounded"
                            onClick={() => onRemoveModerator(item)}
                          >
                            Remove Moderator
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="block w-full text-[16px] text-black py-1 text-center hover:bg-gray1 rounded"
                            onClick={() => onMakeModerator(item)}
                          >
                            Make Moderator
                          </button>
                          <div className="w-full h-[1px] bg-gray2 my-3" />
                          <button
                            className="block w-full text-center text-[16px] text-red2 font-semibold py-1 hover:bg-gray1 rounded"
                            onClick={() => {
                              onRemoveMember(item);
                              handleClosePopup();
                            }}
                          >
                            Remove Member
                          </button>
                        </>
                      )}
                    </div>,
                    document.body,
                  )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BoxList;
