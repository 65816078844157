import api from './Api';

const getCities = ({ offset, limit, searchTerm }) => {
  const query = api.getQueryParamsFromObject({
    offset,
    limit,
    search_term: searchTerm,
  });

  return api.get(`/admin/cities/getCities?${query}`);
};

const updateCityImage = (data) => {
  return api.post('/admin/cities/updateImage', data);
};

const deleteCity = ({ cityId }) => {
  return api.delete(`/admin/cities/delete/${cityId}`);
};

export default {
  getCities,
  updateCityImage,
  deleteCity,
};
