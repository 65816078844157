import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as actions from '../../store/talents/actions';
import * as selectors from '../../store/talents/selectors';
import { getSelectedCommunityId } from '../../store/community/selectors';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import TalentsTable from './TalentsTable';

const Talents = ({ communityId, talents, isLoading, getTalents, hideTalent }) => {
  const translate = useTranslate();

  useEffect(() => {
    if (!R.isNil(communityId)) {
      getTalents({ isLoadMore: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <PageContent title={translate('pages.talents.title.page')}>
      <Widget>
        <TalentsTable
          talents={talents}
          getTalents={getTalents}
          hideTalent={hideTalent}
          isLoading={isLoading}
        />
      </Widget>
    </PageContent>
  );
};

Talents.propTypes = {
  communityId: PropTypes.number,
  talents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTalents: PropTypes.func.isRequired,
  hideTalent: PropTypes.func.isRequired,
};

Talents.defaultProps = {
  communityId: null,
};

export default connect(
  (state) => ({
    communityId: getSelectedCommunityId(state),
    talents: selectors.getTalents(state),
    isLoading: selectors.getIsTalentsLoading(state),
  }),
  {
    getTalents: actions.getCommunityTalents,
    hideTalent: actions.hideTalent,
  },
)(Talents);
