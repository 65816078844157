// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SortableGrid_sortableGrid__25_jY {\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 2rem;\n}", ""]);
// Exports
exports.locals = {
	"sortableGrid": "SortableGrid_sortableGrid__25_jY"
};
module.exports = exports;
