import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsLoading } from '../../store/ui/selectors';
import Loader from '.';

const LoaderWrapper = ({ children, showLoader }) => {
  const isLoading = useSelector(getIsLoading);

  return isLoading || showLoader
    ? <Loader size={50} />
    : children;
};

LoaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  showLoader: PropTypes.bool,
};

LoaderWrapper.defaultProps = {
  showLoader: false,
};

export default LoaderWrapper;
