// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Chart_chartWrapper__3K3XF {\n  min-height: 350px;\n  margin-bottom: 0;\n}", ""]);
// Exports
exports.locals = {
	"chartWrapper": "Chart_chartWrapper__3K3XF"
};
module.exports = exports;
