// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Text_root__3JPFE {\n  font-family: Montserrat, sans-serif;\n}", ""]);
// Exports
exports.locals = {
	"root": "Text_root__3JPFE"
};
module.exports = exports;
