import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import ImageUploader from '../../../components/ImageUploader';

const GroupBannerField = ({
  initialValue,
  onUpdate,
}) => {
  const translate = useTranslate();

  const onImageUpload = (file) => {
    const data = new FormData();
    data.append('file', file);

    onUpdate(data);
  };

  return (
    <ImageUploader
      label={translate('pages.groupInfo.inputs.imageUploader.label')}
      defaultValue={initialValue}
      onChange={onImageUpload}
    />
  );
};

GroupBannerField.propTypes = {
  initialValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

GroupBannerField.defaultProps = {
  initialValue: null,
};

export default GroupBannerField;
