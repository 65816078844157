import { createAction } from 'redux-actions';
import * as AT from './types';

export const setSubcommunityId = createAction(AT.SET_SUBCOMMUNITY_ID);

export const addSubcommunity = createAction(AT.ADD_SUBCOMMUNITY);
export const addSubcommunitySuccess = createAction(AT.ADD_SUBCOMMUNITY_SUCCESS);

export const updateSubcommunity = createAction(AT.UPDATE_SUBCOMMUNITY);
export const updateSubcommunitySuccess = createAction(AT.UPDATE_SUBCOMMUNITY_SUCCESS);

export const getSubcommunities = createAction(AT.GET_SUBCOMMUNITIES);
export const getSubcommunitiesSuccess = createAction(AT.GET_SUBCOMMUNITIES_SUCCESS);
