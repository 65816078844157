import React from 'react';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import getIsRTL from '../../../utils/isRTL';
import Button, { buttonProps } from '..';
import styles from './BackButton.module.scss';

const BackButton = () => {
  const translate = useTranslate();
  const history = useHistory();

  const isRTL = getIsRTL();

  const backButtonIcon = isRTL
    ? 'fa-angle-right'
    : 'fa-angle-left';

  const onClick = () => {
    history.goBack();
  };

  return (
    <Button
      id="back"
      onClick={onClick}
      theme={buttonProps.themes.link}
      size={buttonProps.sizes.large}
      classNames={styles.wrapper}
    >
      <b className={cls('fa', backButtonIcon, styles.buttonIcon)} />
      <span>{translate('general.button.back')}</span>
    </Button>
  );
};

export default BackButton;
