import { getAmountOfDaysFromDateToNow } from './moment';

export const PENDING_APPROVAL_STATUS = 'waiting_approval';
export const MEMBERS_INITIAL_LIMIT = 75;
export const MEMBERS_PAGE_LIMIT = 15;
export const MEMBERS_INITIAL_SEARCH_TERM = '';
export const MEMBERS_SEARCH_DELAY_TIME = 300;
export const NEW_MEMBER_MAX_DAYS_COUNT = 30;
export const MEMBERS_STATUSES = {
  PENDING_APPROVAL: 'pendingApproval',
  NEW_MEMBER: 'newMember',
  MEMBER: 'member',
  ADMIN: 'admin',
  DECLINED: 'declined',
};

export const getMemberStatus = ({ isAdmin, createdAtDate, status }) => {
  if (status === PENDING_APPROVAL_STATUS) {
    return MEMBERS_STATUSES.PENDING_APPROVAL;
  }

  if (status === MEMBERS_STATUSES.DECLINED) {
    return MEMBERS_STATUSES.DECLINED;
  }

  if (isAdmin) {
    return MEMBERS_STATUSES.ADMIN;
  }

  const amountOfDaysFromRegistry = getAmountOfDaysFromDateToNow(createdAtDate);

  if (amountOfDaysFromRegistry <= NEW_MEMBER_MAX_DAYS_COUNT) {
    return MEMBERS_STATUSES.NEW_MEMBER;
  }

  return MEMBERS_STATUSES.MEMBER;
};

export const memberActivityTypes = {
  BUYING: 'buying',
  SELLING: 'selling',
};
