import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { isNil, path } from 'ramda';
import { useTranslate } from 'react-admin';
import { FormGroup, FormFeedback, InputGroup, Input, ButtonGroup, Label } from 'reactstrap';
import Button, { buttonProps } from '../Button';
import styles from './EditableTextarea.module.scss';

export const inputTypes = {
  text: 'text',
  number: 'number',
  textarea: 'textarea',
};

const EditableTextarea = ({
  value: defaultValue,
  id,
  label,
  type,
  maxLength,
  showButtons,
  marginTop,
  className,
  inputGroupClassName,
  useValidation,
  isValid,
  isSaveButtonDisabled,
  validationText,
  onInputChange,
  onSave,
  useCustomLabel,
  ...rest
}) => {
  const inputRef = useRef();
  const translate = useTranslate();
  const isTextArea = type === inputTypes.textarea;
  const currentValue = path(['current', 'value'], inputRef);

  const onChange = (event) => {
    onInputChange(event.target.value);
  };

  const onCancel = () => {
    onInputChange(defaultValue);
    inputRef.current.value = defaultValue;
  };

  useEffect(() => {
    onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <FormGroup
      className={cls({
        'mt-5': marginTop,
      })}
    >
      <Label
        for={id}
        className={cls('font-weight-bold', {
          [styles.customLabel]: useCustomLabel,
        })}
      >
        {label}
      </Label>
      <InputGroup
        className={cls('d-grid', styles.inputGroup, {
          [inputGroupClassName]: inputGroupClassName,
        })}
      >
        <Input
          type={type}
          id={id}
          defaultValue={defaultValue}
          onChange={onChange}
          maxLength={maxLength}
          className={cls(styles.input, className, {
            [styles.textarea]: isTextArea,
          })}
          innerRef={inputRef}
          {...(useValidation && { valid: isValid, invalid: !isValid })}
          {...rest}
        />
        {useValidation && (
          <FormFeedback className={cls('m-0', styles.validationMessage)}>
            {validationText}
          </FormFeedback>
        )}

        {showButtons && (
          <ButtonGroup className={cls('d-grid', styles.buttonGroup)}>
            <Button
              onClick={onSave}
              disabled={
                isNil(isSaveButtonDisabled) ? defaultValue === currentValue : isSaveButtonDisabled
              }
              theme={buttonProps.themes.secondary}
              color={buttonProps.colors.light}
              weight={buttonProps.weights.bold}
              marginTop={false}
            >
              {translate('pages.groupInfo.buttons.save')}
            </Button>
            <Button
              onClick={onCancel}
              disabled={defaultValue === currentValue}
              theme={buttonProps.themes.gray}
              color={buttonProps.colors.dark}
              weight={buttonProps.weights.bold}
              marginTop={false}
            >
              {translate('pages.groupInfo.buttons.cancel')}
            </Button>
          </ButtonGroup>
        )}
      </InputGroup>
    </FormGroup>
  );
};

EditableTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf(Object.values(inputTypes)),
  maxLength: PropTypes.number,
  showButtons: PropTypes.bool,
  marginTop: PropTypes.bool,
  className: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  useValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  validationText: PropTypes.string,
  useCustomLabel: PropTypes.bool,
  onSave: PropTypes.func,
  onInputChange: PropTypes.func,
};

EditableTextarea.defaultProps = {
  value: '',
  type: inputTypes.text,
  showButtons: true,
  marginTop: true,
  className: null,
  inputGroupClassName: null,
  maxLength: null,
  useValidation: false,
  isValid: true,
  isSaveButtonDisabled: null,
  useCustomLabel: false,
  validationText: null,
  onSave: () => {},
  onInputChange: () => {},
};

export default EditableTextarea;
