import { createAction } from 'redux-actions';
import * as AT from './types';

export const getRules = createAction(AT.GET_RULES);
export const getRulesSuccess = createAction(AT.GET_RULES_SUCCESS);

export const addRule = createAction(AT.ADD_RULE);
export const addRuleSuccess = createAction(AT.ADD_RULE_SUCCESS);

export const updateRule = createAction(AT.UPDATE_RULE);
export const updateRuleSuccess = createAction(AT.UPDATE_RULE_SUCCESS);

export const removeRule = createAction(AT.REMOVE_RULE);

export const reorderRule = createAction(AT.REORDER_RULE);

export const setSelectedKind = createAction(AT.SET_SELECTED_KIND);
