import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { ModalFooter as UIModalFooter } from 'reactstrap';
import Button, { buttonProps } from '../../components/Button';

const ModalFooter = ({
  isSaveButtonDisabled,
  onSave,
  onCancel,
}) => {
  const translate = useTranslate();

  return (
    <UIModalFooter className="border-0">
      <Button
        classNames="mr-3"
        theme={buttonProps.themes.gray}
        color={buttonProps.colors.dark}
        weight={buttonProps.weights.bold}
        onClick={onCancel}
      >
        {translate('general.button.cancel')}
      </Button>
      <Button
        disabled={isSaveButtonDisabled}
        theme={
          isSaveButtonDisabled
            ? buttonProps.themes.secondary
            : buttonProps.themes.primary
        }
        color={buttonProps.colors.light}
        weight={buttonProps.weights.bold}
        onClick={onSave}
      >
        {translate('general.button.save')}
      </Button>
    </UIModalFooter>
  );
};

ModalFooter.propTypes = {
  isSaveButtonDisabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ModalFooter;
