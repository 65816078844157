// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Datepicker_datePickerGroup__1aNS8 {\n  flex-flow: row nowrap;\n  align-items: center;\n}\n\n.Datepicker_datePickerLabel__5mz2_ {\n  font-size: 1.1rem;\n}", ""]);
// Exports
exports.locals = {
	"datePickerGroup": "Datepicker_datePickerGroup__1aNS8",
	"datePickerLabel": "Datepicker_datePickerLabel__5mz2_"
};
module.exports = exports;
