import { createAction } from 'redux-actions';
import * as AT from './types';

export const getCommunityTalents = createAction(AT.GET_COMMUNITY_TALENTS);
export const getCommunityTalentsSuccess = createAction(AT.GET_COMMUNITY_TALENTS_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);

export const hideTalent = createAction(AT.HIDE_TALENT);
export const hideTalentSuccess = createAction(AT.HIDE_TALENT_SUCCESS);
