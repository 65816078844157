import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';
import { RULE_STATE_FIELDS_MAP_BY_KIND, RULE_TYPES } from '../../constants';

const initialState = {
  entities: [],
  communityRulesIds: [],
  itemRulesIds: [],
  wishRulesIds: [],
  selectedKind: RULE_TYPES.COMMUNITY,
};

const rulesReducer = handleActions({
  [AT.SET_SELECTED_KIND]: deepMerge(({ payload }) => {
    return {
      selectedKind: payload,
    };
  }),
  [AT.GET_RULES_SUCCESS]: deepMerge(({ payload }) => {
    return {
      entities: payload.entities,
      communityRulesIds: payload.communityRulesIds,
      itemRulesIds: payload.itemRulesIds,
      wishRulesIds: payload.wishRulesIds,
    };
  }),
  [AT.ADD_RULE_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      entities: {
        [payload.id]: payload,
      },
      [RULE_STATE_FIELDS_MAP_BY_KIND[payload.kind]]: [
        ...state[RULE_STATE_FIELDS_MAP_BY_KIND[payload.kind]],
        payload.id,
      ],
    };
  }),
  [AT.UPDATE_RULE_SUCCESS]: deepMerge(({ payload }) => {
    return {
      entities: {
        [payload.id]: payload,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default rulesReducer;
