import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { isEmpty } from 'ramda';
import { connect, useSelector } from 'react-redux';
import { useNotify, useTranslate } from 'react-admin';
import { updateCommunityInfo as updateCommunityInfoAction } from '../../store/community/actions';
import { validateField } from '../../store/validation/actions';
import { COMMUNITY_INFO_FIELD_IDS } from '../../constants/validation';
import { getCommunityInfo, getSelectedCommunityId } from '../../store/community/selectors';
import { getValidationErrors } from '../../store/validation/selectors';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import Separator from '../../components/Separator';
import CommunityNameField from './Fields/CommunityName';
import CommunityAboutField from './Fields/CommunityAbout';
import CommunityIconField from './Fields/CommunityIcon';
import TokenNameField from './Fields/TokenName';
import WalletImageField from './Fields/WalletImage';
import styles from './CommunityInfo.module.scss';
import { Map, useMapsLibrary } from '@vis.gl/react-google-maps';
import Button, { buttonProps } from '../../components/Button';
import communityApi from '../../api/community';

const CommunityInfo = ({
  communityInfo,
  communityInfoValidationErrors,
  updateCommunityInfo,
  validateCommunityInfoField,
}) => {
  const defaultLocation = { lat: -22.9993, lng: -43.38397 };
  const communityLocation = communityInfo?.defaultLocation
    ? {
        lat: communityInfo.defaultLocation.latitude,
        lng: communityInfo.defaultLocation.longitude,
      }
    : defaultLocation;

  const translate = useTranslate();
  const notify = useNotify();
  const [selectedLocation, setSelectedLocation] = useState();
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');
  const [inputError, setInputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const communityId = useSelector((state) => getSelectedCommunityId(state));

  const onPlaceSelect = (place) => {
    if (place.geometry) {
      const location = place.geometry.location;
      setSelectedLocation({ lat: location.lat(), lng: location.lng() });
    }
  };

  const sendLocationToEndpoint = async () => {
    try {
      setIsLoading(true);
      const default_location = {
        latitude: selectedLocation.lat,
        longitude: selectedLocation.lng,
      };

      await communityApi.updateCommunityLocation({ communityId, default_location });

      notify('message.success.action.saveLocation', 'success');
    } catch (error) {
      console.error('Error sending location:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [placeAutocomplete]);

  const handleInputBlur = () => {
    if (!inputRef.current.value) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  return (
    <PageContent title={translate('pages.communityInfo.title.page')}>
      <Widget className={styles.communityInfoWidget}>
        <h6>{translate('pages.communityInfo.labels.addLocation')}</h6>
        <input
          ref={inputRef}
          placeholder={translate('pages.communityInfo.labels.searchLocation')}
          onBlur={handleInputBlur}
          className={styles.searchInput}
          style={{ border: inputError ? '2px solid red' : '1px solid #ccc' }}
        />
        {!isEmpty(communityInfo) && (
          <>
            <Map
              defaultZoom={12}
              defaultCenter={communityLocation}
              center={selectedLocation}
              gestureHandling={'greedy'}
              disableDefaultUI={true}
              style={{ width: '40%', height: 400, marginBottom: 20 }}
            />

            <Button
              theme={buttonProps.themes.primary}
              weight={buttonProps.weights.bold}
              onClick={sendLocationToEndpoint}
              isLoading={isLoading}
              disabled={isLoading}
              style={{ minWidth: 150 }}
            >
              {translate('pages.communityInfo.labels.saveLocation')}
            </Button>
          </>
        )}
        {/*!isEmpty(communityInfo) && (
          <>
            <div className={cls('d-grid', styles.communityInfoWrapper)}>
              <CommunityIconField
                initialValue={communityInfo.communityIcon}
                updateCommunityInfo={updateCommunityInfo}
              />

              <CommunityNameField
                initialValue={communityInfo.name}
                validationError={communityInfoValidationErrors.name}
                id={COMMUNITY_INFO_FIELD_IDS.COMMUNITY_NAME}
                validateName={validateCommunityInfoField}
                updateCommunityInfo={updateCommunityInfo}
              />

              <CommunityAboutField
                initialValue={communityInfo.about}
                updateCommunityInfo={updateCommunityInfo}
              />
            </div>

            <Separator />

            <div className={cls('d-grid', styles.communityInfoWrapper)}>
              <WalletImageField
                updateCommunityInfo={updateCommunityInfo}
                initialValue={communityInfo.walletBackgroundImage}
              />

              <TokenNameField
                initialValue={communityInfo.tokenName}
                validationError={communityInfoValidationErrors.token_name}
                id={COMMUNITY_INFO_FIELD_IDS.TOKEN_NAME}
                validateName={validateCommunityInfoField}
                updateCommunityInfo={updateCommunityInfo}
              />
            </div>
          </>
        )*/}
      </Widget>
    </PageContent>
  );
};

CommunityInfo.propTypes = {
  communityInfo: PropTypes.shape({
    name: PropTypes.string,
    about: PropTypes.string,
    communityIcon: PropTypes.string,
    walletBackgroundImage: PropTypes.string,
    tokenName: PropTypes.string,
  }).isRequired,
  communityInfoValidationErrors: PropTypes.shape({
    name: PropTypes.string,
    token_name: PropTypes.string,
  }).isRequired,

  updateCommunityInfo: PropTypes.func.isRequired,
  validateCommunityInfoField: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      communityInfo: getCommunityInfo(state),
      communityInfoValidationErrors: getValidationErrors(state),
    };
  },
  {
    updateCommunityInfo: updateCommunityInfoAction,
    validateCommunityInfoField: validateField,
  },
)(CommunityInfo);
