import api from './Api';

const getRules = (communityId) => {
  return api.get(`/getRules/${communityId}`);
};

const addRule = (data) => {
  return api.post('/addRule', data);
};

const removeRule = (communityId, ruleId) => {
  return api.delete(`/removeRule/${communityId}/${ruleId}`);
};

const updateRule = (data) => {
  return api.put('/updateRule', data);
};

const reorderRule = (data) => {
  return api.put('/reorderRule', data);
};

export default {
  getRules,
  addRule,
  removeRule,
  updateRule,
  reorderRule,
};
