import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import HighChartsReact from 'highcharts-react-official';
import { getPieChartOptions } from '../../utils/charts';
import Widget from '../Widget';
import styles from './Chart.module.scss';

const PieChart = ({
  data,
  title,
}) => {
  const chartOptions = getPieChartOptions(data);

  return (
    <Widget
      title={title}
      className={cls(styles.chartWrapper, 'shadow-none')}
    >
      <HighChartsReact options={chartOptions} />
    </Widget>
  );
};

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      y: PropTypes.number,
    }),
  ).isRequired,
  title: PropTypes.string,
};

PieChart.defaultProps = {
  title: null,
};

export default PieChart;
