import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  currentGroupId: null,
  community: {},
  group: {},
};

const reducer = handleActions({
  [AT.SET_CURRENT_GROUP_ID]: deepMerge(({ payload }) => {
    return {
      currentGroupId: payload,
    };
  }),
  [AT.SET_COMMUNITY_FEATURES]: deepMerge(({ payload }) => {
    return {
      community: payload,
    };
  }),
  [AT.SET_GROUP_FEATURES]: deepMerge(({ payload }) => {
    return {
      group: payload,
    };
  }),
  [AT.UPDATE_COMMUNITY_FEATURES]: deepMerge(({ payload }) => {
    return {
      community: payload,
    };
  }),
  [AT.UPDATE_GROUP_FEATURES]: deepMerge(({ payload }) => {
    return {
      group: payload,
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
