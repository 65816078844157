import api from './Api';

const getCommunityTalents = ({ skip, limit, communityId, searchTerm }) => {
  const query = api.getQueryParamsFromObject({ skip, limit, search_term: searchTerm });

  return api.get(`/admin/items/${communityId}?${query}&item_types_ids=[2]`);
};

const hideTalent = (talentId) => {
  return api.post('/admin/items/hideItem', { id: talentId });
};

export default {
  getCommunityTalents,
  hideTalent,
};
