import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import { getMemberById } from '../../store/members/selectors';
import * as actions from '../../store/members/actions';
import * as transactionActions from '../../store/transactions/actions';
import { getUserInfo } from '../../store/user/selectors';
import { memberActivityTypes } from '../../utils/members';
import PageContent from '../../components/PageContent';
import MemberCard from './components/MemberCard';
import Widget from '../../components/Widget';
import LoaderWrapper from '../../components/Loader/LoaderWrapper';
import ActivitiesTable from './components/ActivitiesTable';
import styles from './MemberProfile.module.scss';

const MemberProfile = ({
  member,
  currentUserInfo,
  getCommunityMemberInfo,
  toggleMemberShipperStatus,
  cancelItemArrival,
  declineItemRequest,
  performRefund,
}) => {
  const translate = useTranslate();
  const { memberId } = useParams();

  useEffect(() => {
    getCommunityMemberInfo(memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const showLoader = R.isEmpty(member) || R.isNil(R.pathOr(null, ['activities'], member));

  return (
    <PageContent title={translate('pages.memberInfo.title')} useGoBack>
      <Widget
        title={translate('pages.memberInfo.cardTitle')}
        className={styles.wrapper}
      >
        <LoaderWrapper showLoader={showLoader}>
          <MemberCard
            member={member}
            currentUserInfo={currentUserInfo}
            toggleShipperStatus={toggleMemberShipperStatus}
          />
        </LoaderWrapper>
      </Widget>
      <Widget
        title={translate('pages.memberInfo.activitiesTitle')}
        className={styles.wrapper}
      >
        <LoaderWrapper showLoader={showLoader}>
          <ActivitiesTable
            items={R.pathOr([], ['activities', 'buying'], member)}
            type={memberActivityTypes.BUYING}
            userId={member.id}
            cancelItemArrival={cancelItemArrival}
            declineItemRequest={declineItemRequest}
            performRefund={performRefund}
          />
          <ActivitiesTable
            items={R.pathOr([], ['activities', 'selling'], member)}
            type={memberActivityTypes.SELLING}
            userId={member.id}
            cancelItemArrival={cancelItemArrival}
            declineItemRequest={declineItemRequest}
            performRefund={performRefund}
          />
        </LoaderWrapper>
      </Widget>
    </PageContent>
  );
};

MemberProfile.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    profileImage: PropTypes.string.isRequired,
    createdAtDate: PropTypes.string.isRequired,
    lastAccessDate: PropTypes.string.isRequired,
    isAdmin: PropTypes.string.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    activities: PropTypes.shape({
      buying: PropTypes.arrayOf(PropTypes.shape({})),
      selling: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      memberId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  currentUserInfo: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSuperAdmin: PropTypes.bool,
  }),
  getCommunityMemberInfo: PropTypes.func.isRequired,
  toggleMemberShipperStatus: PropTypes.func.isRequired,
  cancelItemArrival: PropTypes.func.isRequired,
  declineItemRequest: PropTypes.func.isRequired,
  performRefund: PropTypes.func.isRequired,
};

MemberProfile.defaultProps = {
  member: {},
  currentUserInfo: {},
};

export default connect(
  (state, { match: { params } }) => {
    const { memberId } = params;

    return {
      member: getMemberById(state, memberId),
      currentUserInfo: getUserInfo(),
    };
  },
  {
    getCommunityMemberInfo: actions.getCommunityMemberInfo,
    toggleMemberShipperStatus: actions.toggleMemberShipperStatus,
    cancelItemArrival: actions.cancelItemArrival,
    declineItemRequest: transactionActions.declineItemRequest,
    performRefund: transactionActions.performRefund,
  },
)(MemberProfile);
