import React from 'react';
import { connect } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTranslate } from 'react-admin';
import styles from './UniqueParticipants.module.scss';
import { getUniqueParticipants } from '../../../../../store/reports/selectors';

const UniqueParticipants = ({ uniqueParticipants }) => {
  const translate = useTranslate();

  const uniqueGiversData = uniqueParticipants
    ?.map((participant) => ({
      date: participant?.date,
      new: participant?.new?.unique_seller,
      existing: participant?.existing?.unique_seller,
    }))
    .filter((data) => data.new || data.existing);

  const uniqueUploadersData = uniqueParticipants
    ?.map((participant) => ({
      date: participant?.date,
      new: participant?.new?.unique_uploader,
      existing: participant?.existing?.unique_uploader,
    }))
    .filter((data) => data.new || data.existing);

  const uniqueReceiversData = uniqueParticipants
    ?.map((participant) => ({
      date: participant?.date,
      new: participant?.new?.unique_buyer,
      existing: participant?.existing?.unique_buyer,
    }))
    .filter((data) => data.new || data.existing);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{translate('pages.reports.unique_participants')}</h1>

      <div className={styles.graph}>
        <h1>{translate('pages.reports.unique_givers')}</h1>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={uniqueGiversData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="new" stackId="a" fill="#00C6D7" />
            <Bar dataKey="existing" stackId="a" fill="#0070FF" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.graph}>
        <h1>{translate('pages.reports.unique_uploaders')}</h1>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={uniqueUploadersData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="new" stackId="a" fill="#00C6D7" />
            <Bar dataKey="existing" stackId="a" fill="#0070FF" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.graph}>
        <h1>{translate('pages.reports.unique_receivers')}</h1>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={uniqueReceiversData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="new" stackId="a" fill="#00C6D7" />
            <Bar dataKey="existing" stackId="a" fill="#0070FF" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    uniqueParticipants: getUniqueParticipants(state),
  };
})(UniqueParticipants);
