import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { useDebouncedCallback as debounce } from 'use-debounce';

import getIsRTL from '../../../utils/isRTL';
import * as memberSelectors from '../../../store/members/selectors';
import { getCommunityMembers } from '../../../store/members/actions';
import { MEMBERS_PAGE_LIMIT } from '../../../utils/members';
import styles from './UserSelect.module.scss';

const UserSelect = memo(({
  options,
  isExistMore,
  isLoading,
  label,
  getOptions,
  onSelect,
}) => {
  const translate = useTranslate();
  const [searchTerm, setSearchTerm] = useState();

  const isRTL = getIsRTL();

  useEffect(() => {
    if (isEmpty(options) && !isLoading) {
      getOptions({ isLoadMore: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, isLoading]);

  const onMenuScrollToBottom = debounce(() => {
    if (isExistMore) {
      getOptions({
        searchTerm,
        isLoadMore: true,
        nextPage: Math.trunc(options.length / MEMBERS_PAGE_LIMIT) + 1,
      });
    }
  }, 200);

  const updateOptionsWithSearchTerm = debounce((term) => {
    setSearchTerm(term);
    getOptions({ searchTerm: term, isLoadMore: false });
  }, 300);

  const onInputChange = (value) => {
    updateOptionsWithSearchTerm.callback(value);
  };

  const onChange = (selectedOptions) => {
    const ids = Array.isArray(selectedOptions)
      ? selectedOptions.map(({ value }) => {
        return value;
      })
      : [];

    onSelect(ids);
  };

  const renderOption = ({
    innerRef,
    innerProps,
    data,
    isFocused,
  }) => {
    const { value, label: optionLabel, image } = data;

    return (
      <div
        key={value}
        ref={innerRef}
        className={cls('option', styles.option, {
          [styles.isFocused]: isFocused,
        })}
        {...innerProps}
      >
        <img src={image} className={styles.optionImage} alt={optionLabel} />
        <span>{optionLabel}</span>
      </div>
    );
  };

  return (
    <FormGroup>
      {label && (
        <Label className="font-weight-bold">
          {label}
        </Label>
      )}

      <Select
        options={options}
        onMenuScrollToBottom={onMenuScrollToBottom.callback}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder={translate('pages.transactions.modal.select.placeholder')}
        isLoading={isLoading}
        isRtl={isRTL}
        defaultMenuIsOpen={false}
        components={{
          Option: renderOption,
        }}
        className={styles.select}
        autoFocus
        isSearchable
        isMulti
      />
    </FormGroup>
  );
});

UserSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  isExistMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string,

  onSelect: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
};

UserSelect.defaultProps = {
  label: null,
};

export default connect(
  (state) => {
    return {
      options: memberSelectors.getMembersForSelect(state),
      isExistMore: memberSelectors.getIsExistMore(state),
      isLoading: memberSelectors.getIsLoading(state),
    };
  }, {
    getOptions: getCommunityMembers,
  },
)(UserSelect);
