// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DateRange_dateRangeWrapper__16xnp {\n  flex-flow: row wrap;\n  justify-content: flex-start;\n}", ""]);
// Exports
exports.locals = {
	"dateRangeWrapper": "DateRange_dateRangeWrapper__16xnp"
};
module.exports = exports;
