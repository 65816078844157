const prefix = 'subcommunities/';

export const ADD_SUBCOMMUNITY = `${prefix}ADD_SUBCOMMUNITY`;
export const ADD_SUBCOMMUNITY_SUCCESS = `${prefix}ADD_SUBCOMMUNITY_SUCCESS`;

export const UPDATE_SUBCOMMUNITY = `${prefix}UPDATE_SUBCOMMUNITY`;
export const UPDATE_SUBCOMMUNITY_SUCCESS = `${prefix}UPDATE_SUBCOMMUNITY_SUCCESS`;
export const SET_SUBCOMMUNITIES = `${prefix}SET_SUBCOMMUNITIES`;

export const GET_SUBCOMMUNITIES = `${prefix}GET_SUBCOMMUNITIES`;
export const GET_SUBCOMMUNITIES_SUCCESS = `${prefix}GET_SUBCOMMUNITIES_SUCCESS`;

export const SET_SUBCOMMUNITY_ID = `${prefix}SET_SUBCOMMUNITY_ID`;
