import api from './Api';
import { ANALYITCS_DAYS_COUNT } from '../constants/wallets';

const getCommunityWallets = ({ communityId, limit, skip, searchTerm }) => {
  const queryParams = api.getQueryParamsFromObject({
    limit,
    skip,
    search_term: searchTerm,
  });

  return api.get(`/admin/wallets/${communityId}?${queryParams}`);
};

const getWalletsAnalytics = ({ communityId, onlyLastMonth }) => {
  const queryParams = api.getQueryParamsFromObject(onlyLastMonth
    ? { period_in_days: ANALYITCS_DAYS_COUNT }
    : {},
  );

  return api.get(`/admin/wallets/analytics/${communityId}?${queryParams}`);
};

export default {
  getCommunityWallets,
  getWalletsAnalytics,
};
