import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import EditableTextarea from '../../../components/EditableTextarea';

const GroupNameField = ({ initialValue, groupNameMap, id, localeType, onUpdate }) => {
  const translate = useTranslate();

  const [name, setName] = useState(initialValue);
  const isValueChanged = name !== initialValue;
  const isSaveButtonDisabled = !isValueChanged ?? !name;

  useEffect(() => {
    setName(initialValue);
  }, [initialValue]);

  const onSave = async () => {
    const updatedName = {
      ...groupNameMap,
      [localeType]: name,
    };

    const data = new FormData();
    data.append('name', JSON.stringify(updatedName));

    await onUpdate(data);
  };

  return (
    <EditableTextarea
      value={initialValue}
      onInputChange={setName}
      onSave={onSave}
      isSaveButtonDisabled={isSaveButtonDisabled}
      id={id}
      label={translate(`pages.groupInfo.inputs.groupName.label.${localeType}`)}
      marginTop={false}
      useCustomLabel
    />
  );
};

GroupNameField.propTypes = {
  initialValue: PropTypes.string,
  groupNameMap: PropTypes.shape({
    en: PropTypes.string,
    he: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  localeType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

GroupNameField.defaultProps = {
  initialValue: null,
};

export default GroupNameField;
