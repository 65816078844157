import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  groupId: null,
  conditionEntities: {},
  conditionIds: [],
};

const reducer = handleActions({
  [AT.SET_GROUP_ID]: deepMerge(({ payload }) => {
    return {
      groupId: payload,
    };
  }),
  [AT.SET_CONDITIONS]: deepMerge(({ payload }) => {
    return {
      conditionEntities: payload.conditionEntities,
      conditionIds: payload.conditionIds,
    };
  }),
  [AT.ADD_CONDITION_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      conditionEntities: {
        [payload.id]: payload,
      },
      conditionIds: [...state.conditionIds, payload.id],
    };
  }),
  [AT.UPDATE_CONDITION_SUCCESS]: deepMerge(({ payload }) => {
    return {
      conditionEntities: {
        [payload.id]: payload,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
