import React from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';
import styles from './TabsContent.module.scss';

const TabsContent = ({ children }) => {
  return (
    <TabContent className={styles.tabsContent}>
      <TabPane>
        {children}
      </TabPane>
    </TabContent>
  );
};

TabsContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default TabsContent;
