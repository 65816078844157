// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Brands_brandsWidget__2MwIs {\n  max-width: 560px;\n  margin: 0 auto;\n}", ""]);
// Exports
exports.locals = {
	"brandsWidget": "Brands_brandsWidget__2MwIs"
};
module.exports = exports;
