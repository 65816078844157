import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';
import { TRANSACTIONS_TYPES } from '../../constants/transactions';
import { offerTypes } from '../../constants/offers';

const initialState = {
  selectedType: TRANSACTIONS_TYPES.transfer,
  isLoading: false,
  isExistMore: true,
  entities: {},
  ids: [],
};

const reducer = handleActions({
  [AT.SET_TRANSACTIONS_TYPE]: deepMerge(({ payload }) => {
    return {
      selectedType: payload,
      isExistMore: true,
    };
  }),
  [AT.SET_IS_LOADING]: deepMerge(({ payload }) => {
    return {
      isLoading: payload,
    };
  }),
  [AT.GET_TRANSACTIONS_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      entities: payload.entities,
      ids: payload.isLoadMore
        ? [...state.ids, ...payload.ids]
        : payload.ids,
      isExistMore: payload.isExistMore,
    };
  }),
  [AT.DECLINE_ITEM_REQUEST_SUCCESS]: deepMerge(({ payload }) => ({
    entities: {
      [payload.transactionId]: {
        offerStatus: offerTypes.INTERESTED,
      },
    },
  })),
  [AT.PERFORM_REFUND_SUCCESS]: deepMerge(({ payload }) => ({
    entities: {
      [payload.transactionId]: {
        offerStatus: offerTypes.INTERESTED,
      },
    },
  })),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
