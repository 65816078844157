import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/subcommunities/actions';
import * as selectors from '../../../store/community/selectors';
import { modalModes } from '../../const';
import EditableTextarea from '../../../components/EditableTextarea';
import Switcher from '../../../components/Switcher/Switcher';
import Text, { textProps } from '../../../components/Text';
import ImageUploader from '../../../components/ImageUploader';
import imageUploaderApi from '../../../domains/ImageUploader/imageUploader.api';

const SubcommunitiesModal = ({
  options,
  addSubcommunity,
  updateSubcommunity,
  closeModal,
  communityId,
}) => {
  const translate = useTranslate();

  const [name, setName] = useState(options.name);
  const [joinCode, setJoinCode] = useState(options.joinCode);
  const [backgroundImage, setBackgroundImage] = useState(options.backgroundImage);
  const [isPrivate, setIsPrivate] = useState(false);

  const isEditMode = options.mode === modalModes.EDIT;

  const isSaveButtonDisabled = !name || !backgroundImage;

  const onSaveClick = async () => {
    const data = {
      name,
      join_code: joinCode?.length > 0 ? joinCode : null,
      is_private: isPrivate,
      community_id: communityId,
      background_image: backgroundImage,
      ...(isEditMode && { id: options.id }),
    };

    const onSaveFunc = isEditMode ? updateSubcommunity : addSubcommunity;

    await onSaveFunc(data);
    closeModal();
  };

  const onChangeIsPrivate = (event) => {
    setIsPrivate(event.target.checked);
  };

  const onChangeImage = async (file) => {
    const formData = new FormData();

    formData.append('image', file);
    const link = await imageUploaderApi.uploadImage(formData);

    setBackgroundImage(link);
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.subcommunities.modal.header.edit')
          : translate('pages.subcommunities.modal.header.create')}
      </Modal.Header>

      <Modal.Body>
        <EditableTextarea
          value={name}
          onInputChange={setName}
          label={translate('pages.subcommunities.inputs.name.label')}
          maxLength={30}
          id="name"
          showButtons={false}
        />

        <ImageUploader id="newSubcommunityImage" onChange={onChangeImage} />

        <Text
          color={textProps.colors.success}
          size={textProps.sizes.small}
          weight={textProps.weights.bold}
        >
          {translate('pages.subcommunities.inputs.isPrivate.label')}
        </Text>

        <Switcher id="subCommunityCreate" checked={isPrivate} onChange={onChangeIsPrivate} />

        <EditableTextarea
          value={joinCode}
          onInputChange={setJoinCode}
          label={translate('pages.subcommunities.inputs.joinCode.label')}
          maxLength={30}
          id="categoryName"
          showButtons={false}
        />
      </Modal.Body>
      <Modal.Footer
        onSave={onSaveClick}
        onCancel={closeModal}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </>
  );
};

SubcommunitiesModal.propTypes = {
  options: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    joinCode: PropTypes.string,
    isPrivate: PropTypes.bool,
    mode: PropTypes.string,
    backgroundImage: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  addSubcommunity: PropTypes.func.isRequired,
  updateSubcommunity: PropTypes.func.isRequired,
  communityId: PropTypes.string,
};

SubcommunitiesModal.defaultProps = {
  options: {},
};

export default connect(
  (state) => ({
    communityId: selectors.getSelectedCommunityId(state),
  }),
  {
    addSubcommunity: actions.addSubcommunity,
    updateSubcommunity: actions.updateSubcommunity,
  },
)(SubcommunitiesModal);
