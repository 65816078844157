import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';

const initialState = {
    posts: [],
}

const reducer = handleActions({
    [AT.GET_POSTS_SUCCESS]: (state, action) => ({
        ...state,
        posts: action.payload,
    }),
}, initialState);

export default reducer;