import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useDebouncedCallback as debounce } from 'use-debounce';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  MEMBERS_INITIAL_SEARCH_TERM,
  MEMBERS_PAGE_LIMIT,
  MEMBERS_SEARCH_DELAY_TIME,
} from '../../../utils/members';
import { memberTypes } from '../../../constants/members';
import { formatDateWithLocale } from '../../../utils/dateFns';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Text, { textProps } from '../../../components/Text';
import Badge, { badgeProps } from '../../../components/Badge';
import Switcher from '../../../components/Switcher/Switcher';
import styles from './MembersTable.module.scss';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { IMAGES_URL } from '../../../constants/Images';

const MembersTable = ({ items, getItems, isLoading, type, searchTerm: searchTermFromQuery }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const searchRef = useRef();
  const [searchTerm, setSearchTerm] = useState(MEMBERS_INITIAL_SEARCH_TERM);
  const [filters, setFilters] = useState({
    created_before: undefined,
    created_after: undefined,
    last_login_before: undefined,
    last_login_after: undefined,
    more_got_than: undefined,
    less_got_than: undefined,
    more_items_than: undefined,
    less_items_than: undefined,
    more_points_than: undefined,
    less_points_than: undefined,
    more_gave_than: undefined,
    less_gave_than: undefined,
  });
  const [isOnlyActive, setOnlyActive] = useState(false);
  const [isShowFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    if (searchTermFromQuery) {
      if (searchRef) {
        searchRef.current.value = searchTermFromQuery;
      }

      onSearchChange.callback(searchTermFromQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // going to get the information and rerender itself everytime there is a change in isOnlyActive
  useEffect(() => {
    getItems({ searchTerm, isLoadMore: false, isOnlyActive });
  }, [isOnlyActive]);

  const dateFormatter = (value) => {
    return formatDateWithLocale(value, locale);
  };

  const statusFormatter = (value) => {
    return translate(`pages.members.table.statuses.${value}`);
  };

  const nameFormatter = (value, row) => {
    return (
      <Link to={`/members/${row.id}`}>
        <div className={styles.nameWrapper}>
          <img src={row.profileImage} className={styles.avatar} alt={value} />
          <span>{value}</span>
          {row.isShipper && (
            <Badge
              title={translate('general.label.shipper')}
              theme={badgeProps.themes.primary}
              className={styles.shipperBadge}
              size={10}
            />
          )}
        </div>
      </Link>
    );
  };

  const suspendedUserNameFormatter = (value, row) => {
    return (
      <Link to={`/members/${value}`}>
        <div className={styles.nameWrapper}>
          <img src={row.profileImage} className={styles.avatar} alt={value} />
          <span>{`${row.name} (ID: ${value})`}</span>
        </div>
      </Link>
    );
  };

  const suspensionInitiatorFormatter = (value) => {
    return <Link to={`/members/${value}`}>{value}</Link>;
  };

  const reasonFormatter = (value) => {
    return (
      <Text
        color={textProps.colors.secondary}
        size={textProps.sizes.small}
        weight={textProps.weights.semiBold}
      >
        {value}
      </Text>
    );
  };

  const suspensionStatusFormatter = (value) => {
    return (
      <Text
        color={value === true ? textProps.colors.success : textProps.colors.danger}
        weight={textProps.weights.bold}
      >
        {value === true
          ? translate('general.label.active')
          : translate('general.label.deactivated')}
      </Text>
    );
  };

  const onPageChange = (requestedPage) => {
    getItems({ isLoadMore: true, searchTerm, nextPage: requestedPage + 1, isOnlyActive });
  };

  const onSearchChange = debounce((term) => {
    setSearchTerm(term);

    getItems({ isLoadMore: false, searchTerm: term, isOnlyActive });
  }, MEMBERS_SEARCH_DELAY_TIME);

  const memberColumns = [
    {
      dataField: 'name',
      text: translate('pages.members.table.columns.name'),
      formatter: nameFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'nickname',
      text: translate('pages.members.table.columns.nickname'),
    },
    {
      dataField: 'id',
      text: translate('pages.members.table.columns.id'),
    },
    {
      dataField: 'phoneNumber',
      text: translate('pages.members.table.columns.phoneNumber'),
    },
    {
      dataField: 'totalItemsBought',
      text: translate('pages.members.table.columns.gotItems'),
    },
    {
      dataField: 'totalItemsSold',
      text: translate('pages.members.table.columns.gaveItems'),
    },
    {
      dataField: 'totalItemsPosted',
      text: translate('pages.members.table.columns.uploadedItems'),
    },
    {
      dataField: 'totalPoints',
      text: translate('pages.members.table.columns.totalPoints'),
    },
    {
      dataField: 'referrerId',
      text: translate('pages.members.table.columns.referralId'),
    },
    {
      dataField: 'lastAccessDate',
      text: translate('pages.members.table.columns.lastAccessDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'createdAtDate',
      text: translate('pages.members.table.columns.createdAtDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'status',
      text: translate('pages.members.table.columns.status'),
      formatter: statusFormatter,
    },
  ];

  const suspensionColumns = [
    {
      dataField: 'userId',
      text: translate('pages.members.table.columns.name'),
      formatter: suspendedUserNameFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'nickname',
      text: translate('pages.members.table.columns.nickname'),
    },
    {
      dataField: 'phoneNumber',
      text: translate('pages.members.table.columns.phoneNumber'),
    },
    {
      dataField: 'initiatorId',
      text: translate('pages.members.table.columns.initiator'),
      formatter: suspensionInitiatorFormatter,
    },
    {
      dataField: 'activationReason',
      text: translate('pages.members.table.columns.reason'),
      formatter: reasonFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'deactivationReason',
      text: translate('pages.members.table.columns.rehabNote'),
      formatter: reasonFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'activationDate',
      text: translate('pages.members.table.columns.activationDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'deactivationDate',
      text: translate('pages.members.table.columns.deactivationDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'isActive',
      text: translate('pages.members.table.columns.status'),
      formatter: suspensionStatusFormatter,
    },
  ];

  const suspicionColumns = [
    {
      dataField: 'userId',
      text: translate('pages.members.table.columns.name'),
      formatter: suspendedUserNameFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'nickname',
      text: translate('pages.members.table.columns.nickname'),
    },
    {
      dataField: 'phoneNumber',
      text: translate('pages.members.table.columns.phoneNumber'),
    },
    {
      dataField: 'activationReason',
      text: translate('pages.members.table.columns.reason'),
      formatter: reasonFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'activationDate',
      text: translate('pages.members.table.columns.activationDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'deactivationDate',
      text: translate('pages.members.table.columns.deactivationDate'),
      formatter: dateFormatter,
    },
    {
      dataField: 'isActive',
      text: translate('pages.members.table.columns.status'),
      formatter: suspensionStatusFormatter,
    },
  ];

  const getTableColumnsByType = () => {
    if (type === memberTypes.SUSPENDED) {
      return suspensionColumns;
    }

    if (type === memberTypes.SUSPICIOUS) {
      return suspicionColumns;
    }

    return memberColumns;
  };

  const paginationProps = {
    onPageChange,
    sizePerPage: MEMBERS_PAGE_LIMIT,
  };

  /* Provides a switch checkbox if on the correct table */
  const getSwitcherCheckBox = () => {
    const onSwitcherChange = (event) => {
      setOnlyActive(event.target.checked);
    };
    if (type === memberTypes.SUSPENDED || type === memberTypes.SUSPICIOUS) {
      return (
        <div className={styles.activeCheckBox}>
          <Text
            color={textProps.colors.success}
            size={textProps.sizes.small}
            weight={textProps.weights.bold}
          >
            {translate('pages.members.table.switcher.title')}
          </Text>
          <Switcher checked={isOnlyActive} onChange={onSwitcherChange} />
        </div>
      );
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const onApplyFilters = async () => {
    await getItems({
      isLoadMore: false,
      searchTerm,
      isOnlyActive,
      created_before: filters.created_before
        ? new Date(filters.created_before).toISOString()
        : undefined,
      created_after: filters.created_after
        ? new Date(filters.created_after).toISOString()
        : undefined,
      last_login_before: filters.last_login_before
        ? new Date(filters.last_login_before).toISOString()
        : undefined,
      last_login_after: filters.last_login_after
        ? new Date(filters.last_login_after).toISOString()
        : undefined,
      ...filters,
    });
    setShowFilterModal((prev) => !prev);
  };

  const onClearFilters = async () => {
    await getItems({
      isLoadMore: false,
      searchTerm,
      isOnlyActive,
    });
    setShowFilterModal((prev) => !prev);
  };

  return (
    <>
      <div className={cls(styles.searchWrapper, 'mb-5')}>
        {getSwitcherCheckBox()}
        <button onClick={() => setShowFilterModal((prev) => !prev)} className={styles.buttonFilter}>
          <img src={IMAGES_URL.ICON_FILTER} style={{ width: 18, height: 18 }} />
          {translate('pages.members.show_members_filter')}
        </button>
        <Modal isOpen={isShowFilterModal} centered>
          <ModalHeader>{translate('pages.members.filter_members_by')}</ModalHeader>
          <ModalBody style={{ padding: 20 }}>
            <div className={styles.containerCreatedFilter}>
              <FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleDate">
                  {translate('pages.members.members_created_before')}
                </Label>
                <Input
                  type="date"
                  onChange={(e) => handleFilterChange('created_before', e.target.value)}
                  value={filters.created_before}
                  style={{ height: 40 }}
                />
              </FormGroup>
              <FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleDate">
                  {translate('pages.members.members_created_after')}
                </Label>
                <Input
                  type="date"
                  onChange={(e) => handleFilterChange('created_after', e.target.value)}
                  value={filters.created_after}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>

            <div className={styles.containerCreatedFilter}>
              <FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleDate">
                  {translate('pages.members.members_last_login_before')}
                </Label>
                <Input
                  type="date"
                  onChange={(e) => handleFilterChange('last_login_before', e.target.value)}
                  value={filters.last_login_before}
                  style={{ height: 40 }}
                />
              </FormGroup>
              <FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleDate">
                  {translate('pages.members.members_last_login_after')}
                </Label>
                <Input
                  type="date"
                  onChange={(e) => handleFilterChange('last_login_after', e.target.value)}
                  value={filters.last_login_after}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>

            <div className={styles.containerCreatedFilter}>
              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_got_more_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('more_got_than', e.target.value)}
                  value={filters.more_got_than}
                  style={{ height: 40 }}
                />
              </FormGroup>

              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_got_less_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('less_got_than', e.target.value)}
                  value={filters.less_got_than}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>

            <div className={styles.containerCreatedFilter}>
              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_with_more_items_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('more_items_than', e.target.value)}
                  value={filters.more_items_than}
                  style={{ height: 40 }}
                />
              </FormGroup>

              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_with_fewer_items_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('less_items_than', e.target.value)}
                  value={filters.less_items_than}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>

            <div className={styles.containerCreatedFilter}>
              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_with_more_points_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('more_points_than', e.target.value)}
                  value={filters.more_points_than}
                  style={{ height: 40 }}
                />
              </FormGroup>

              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_with_fewer_points_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('less_points_than', e.target.value)}
                  value={filters.less_points_than}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>

            <div className={styles.containerCreatedFilter}>
              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_gave_more_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('more_gave_than', e.target.value)}
                  value={filters.more_gave_than}
                  style={{ height: 40 }}
                />
              </FormGroup>

              <FormGroup FormGroup style={{ width: '100%' }}>
                <Label style={{ fontWeight: '700' }} for="exampleNumber">
                  {translate('pages.members.members_gave_less_than')}
                </Label>
                <Input
                  type="number"
                  placeholder="0"
                  onChange={(e) => handleFilterChange('less_gave_than', e.target.value)}
                  value={filters.less_gave_than}
                  style={{ height: 40 }}
                />
              </FormGroup>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className={styles.modalFooter}>
              <Button
                color="transparent"
                style={{ color: 'red', borderWidth: 1, borderColor: 'red', width: 100 }}
                onClick={() => setShowFilterModal((prev) => !prev)}
              >
                {translate('pages.members.cancel')}
              </Button>
              <div className={styles.modalButtons}>
                <Button
                  color="transparent"
                  onClick={onClearFilters}
                  style={{ color: '#189BC9', borderWidth: 1, borderColor: '#189BC9' }}
                >
                  {translate('pages.members.clear_filters')}
                </Button>
                <Button color="primary" onClick={onApplyFilters} style={{ width: 150 }}>
                  {translate('pages.members.apply_filters')}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Search
          onChange={onSearchChange.callback}
          placeholder={translate('pages.members.table.search.placeholder')}
          className={styles.searchPanel}
          innerRef={searchRef}
        />
      </div>

      <Table
        data={items}
        columns={getTableColumnsByType()}
        paginationProps={paginationProps}
        loading={isLoading}
        useOverlay
      />
    </>
  );
};

MembersTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      nickname: PropTypes.string,
      profileImage: PropTypes.string,
      status: PropTypes.string,
      createdAtDate: PropTypes.string,
      lastAccessDate: PropTypes.string,
      phoneNumber: PropTypes.string,
      isShipper: PropTypes.bool,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(memberTypes)).isRequired,
  searchTerm: PropTypes.string,
  getItems: PropTypes.func.isRequired,
};

export default MembersTable;
