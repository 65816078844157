import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/categories/actions';
import { itemTypes, modalModes } from '../../const';
import EditableTextarea from '../../../components/EditableTextarea';
import IconSelect from '../../../components/IconSelect';

const CategoriesModal = ({
  options,
  addCategory,
  updateCategory,
  closeModal,
}) => {
  const translate = useTranslate();

  const [name, setName] = useState(options.name);
  const [iconName, setIconName] = useState(options.iconName);
  const isChild = options.itemType === itemTypes.CHILD;
  const isEditMode = options.mode === modalModes.EDIT;

  const isSaveButtonDisabled = !name;

  const onSaveClick = () => {
    const data = {
      name,
      iconName,
      ...(isEditMode && { id: options.id }),
      ...(isChild && { parentId: options.parentId }),
    };

    const onSaveFunc = isEditMode
      ? updateCategory
      : addCategory;

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.categories.modal.header.edit')
          : translate('pages.categories.modal.header.create')
        }
      </Modal.Header>
      <Modal.Body>
        <EditableTextarea
          value={name}
          onInputChange={setName}
          label={translate('pages.categories.labels.categoryName')}
          maxLength={30}
          id="categoryName"
          showButtons={false}
        />
        {options.itemType !== itemTypes.CHILD && (
          <IconSelect
            onChange={setIconName}
            selectedOption={iconName}
            label={translate('pages.categories.labels.iconName')}
            id="iconName"
          />
        )}
      </Modal.Body>
      <Modal.Footer
        onSave={onSaveClick}
        onCancel={closeModal}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </>
  );
};

CategoriesModal.propTypes = {
  options: PropTypes.shape({
    mode: PropTypes.string,
    itemType: PropTypes.string,
    id: PropTypes.number,
    parentId: PropTypes.number,
    name: PropTypes.string,
    iconName: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
};

CategoriesModal.defaultProps = {
  options: {},
};

export default connect(null, {
  addCategory: actions.addCategory,
  updateCategory: actions.updateCategory,
})(CategoriesModal);
