// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.CommunitySelect_selectWrapper__1G4BX {\n  min-width: 150px;\n  height: 40px;\n  flex-grow: 1;\n  justify-content: flex-end;\n}\n\n.CommunitySelect_selectMenu__2HN1r {\n  width: 100%;\n  max-width: 200px;\n}\n\n[dir=rtl] .CommunitySelect_selectMenu__2HN1r {\n  right: auto !important;\n}\n\n.CommunitySelect_selectPlaceholder__3Hmfs {\n  max-width: 200px;\n  border-width: 0;\n  display: flex !important;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  color: #798892 !important;\n  background-color: #F5F6F7;\n  font-weight: 700 !important;\n  border-radius: 0.5rem !important;\n}\n\n.CommunitySelect_selectIcon__2dPee {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  height: 20px;\n  width: 20px;\n  background: #d6dee5;\n  font-weight: 600;\n  font-size: 18px;\n}\n.CommunitySelect_selectIcon__2dPee img {\n  height: 100%;\n}\n\n.CommunitySelect_caret__39CJQ {\n  font-size: 2rem !important;\n}", ""]);
// Exports
exports.locals = {
	"selectWrapper": "CommunitySelect_selectWrapper__1G4BX",
	"selectMenu": "CommunitySelect_selectMenu__2HN1r",
	"selectPlaceholder": "CommunitySelect_selectPlaceholder__3Hmfs",
	"selectIcon": "CommunitySelect_selectIcon__2dPee",
	"caret": "CommunitySelect_caret__39CJQ"
};
module.exports = exports;
