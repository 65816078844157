import { createAction } from 'redux-actions';
import * as AT from './types';

export const getTransactions = createAction(AT.GET_TRANSACTIONS);
export const getTransactionsSuccess = createAction(AT.GET_TRANSACTIONS_SUCCESS);

export const setTransactionsType = createAction(AT.SET_TRANSACTIONS_TYPE);

export const setIsLoading = createAction(AT.SET_IS_LOADING);

export const createIssueTransaction = createAction(AT.CREATE_ISSUE_TRANSACTION);

export const declineItemRequest = createAction(AT.DECLINE_ITEM_REQUEST);
export const declineItemRequestSuccess = createAction(AT.DECLINE_ITEM_REQUEST_SUCCESS);

export const performRefund = createAction(AT.PERFORM_REFUND);
export const performRefundSuccess = createAction(AT.PERFORM_REFUND_SUCCESS);
