// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.PageDescription_pageDescription__1v0B4 {\n  font-size: 1rem;\n  color: #494A4C;\n}\n\n.PageDescription_pageDescriptionLink__2D-sA {\n  font-family: inherit;\n}", ""]);
// Exports
exports.locals = {
	"pageDescription": "PageDescription_pageDescription__1v0B4",
	"pageDescriptionLink": "PageDescription_pageDescriptionLink__2D-sA"
};
module.exports = exports;
