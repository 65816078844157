// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.LoginPage_authPage__1bcIl {\n  height: 100vh;\n}\n@media (min-width: 768px) and (min-height: 670px) {\n  .LoginPage_authPage__1bcIl {\n    padding-top: 5vh;\n  }\n}\n\n.LoginPage_widgetAuth__OOxdm {\n  max-width: 360px;\n  padding: 30px 30px 0 30px !important;\n  border-radius: 0.7rem;\n}\n.LoginPage_widgetAuth__OOxdm h1,\n.LoginPage_widgetAuth__OOxdm h2,\n.LoginPage_widgetAuth__OOxdm h3,\n.LoginPage_widgetAuth__OOxdm h4,\n.LoginPage_widgetAuth__OOxdm h5,\n.LoginPage_widgetAuth__OOxdm h6 {\n  font-weight: 400;\n  text-align: center;\n}\n.LoginPage_widgetAuth__OOxdm .LoginPage_widgetAuthInfo__2YwOo {\n  font-size: 13px;\n  color: #888;\n  margin-bottom: 0;\n  text-align: center;\n}\n.LoginPage_widgetAuth__OOxdm .LoginPage_auth-btn__22hiR {\n  width: 100%;\n}\n\n.LoginPage_authForm__2jXrb {\n  margin: 30px 0;\n}\n\n.LoginPage_authFooter__2wDb4 {\n  margin-bottom: 25px;\n  font-size: 13px;\n  color: #636c72;\n  text-align: center;\n}\n@media (min-width: 768px) and (min-height: 670px) {\n  .LoginPage_authFooter__2wDb4 {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n  }\n}\n\n.LoginPage_authLogo__3G6K8 {\n  margin-top: 15px;\n  margin-bottom: 40px;\n  text-align: center;\n  font-weight: 400;\n}\n.LoginPage_authLogo__3G6K8 i {\n  font-size: 13px;\n  margin: 0 20px;\n}\n\n.LoginPage_authAlert__2P-oU {\n  margin-top: 1.5rem;\n  background-color: transparent !important;\n  border: none !important;\n}", ""]);
// Exports
exports.locals = {
	"authPage": "LoginPage_authPage__1bcIl",
	"widgetAuth": "LoginPage_widgetAuth__OOxdm",
	"widgetAuthInfo": "LoginPage_widgetAuthInfo__2YwOo",
	"auth-btn": "LoginPage_auth-btn__22hiR",
	"authForm": "LoginPage_authForm__2jXrb",
	"authFooter": "LoginPage_authFooter__2wDb4",
	"authLogo": "LoginPage_authLogo__3G6K8",
	"authAlert": "LoginPage_authAlert__2P-oU"
};
module.exports = exports;
