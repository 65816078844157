import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import ImageUploader from '../../../components/ImageUploader';

const CommunityIconField = ({
  initialValue,
  updateCommunityInfo,
}) => {
  const translate = useTranslate();

  const onImageUpload = (file) => {
    const data = new FormData();
    data.append('community_icon', file);

    updateCommunityInfo(data);
  };

  return (
    <ImageUploader
      label={translate('pages.communityInfo.labels.icon')}
      defaultValue={initialValue}
      onChange={onImageUpload}
    />
  );
};

CommunityIconField.propTypes = {
  initialValue: PropTypes.string,
  updateCommunityInfo: PropTypes.func.isRequired,
};

CommunityIconField.defaultProps = {
  initialValue: null,
};

export default CommunityIconField;
