// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.UserMenu_avatar__1EFK- {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  background: #d6dee5;\n  font-weight: 600;\n  font-size: 18px;\n}\n.UserMenu_avatar__1EFK- img {\n  height: 40px;\n  width: 40px;\n  object-fit: cover;\n}", ""]);
// Exports
exports.locals = {
	"avatar": "UserMenu_avatar__1EFK-"
};
module.exports = exports;
