import { modalIds } from '../const';
import CategoriesModal from './Categories';
import BrandsModal from './Brands';
import RulesModal from './Rules';
import ConditionsModal from './Conditions';
import SizesModal from './Sizes';
import TransactionsModal from './Transactions';
import SuspiciousModal from './Suspicious';
import SuspensionModal from './Suspension';
import ReferralUsersModal from './ReferralUsers';
import GroupInfoModal from './GroupInfo';
import SubcommunitiesModal from './Subcommunities';

const modalsMap = {
  [modalIds.CATEGORIES_MODAL]: CategoriesModal,
  [modalIds.BRANDS_MODAL]: BrandsModal,
  [modalIds.RULES_MODAL]: RulesModal,
  [modalIds.CONDITIONS_MODAL]: ConditionsModal,
  [modalIds.SIZES_MODAL]: SizesModal,
  [modalIds.TRANSACTIONS_MODAL]: TransactionsModal,
  [modalIds.SUSPENSION_MODAL]: SuspensionModal,
  [modalIds.SUSPICIOUS_MODAL]: SuspiciousModal,
  [modalIds.REFERRAL_USERS_MODAL]: ReferralUsersModal,
  [modalIds.GROUP_INFO_MODAL]: GroupInfoModal,
  [modalIds.SUBCOMMUNITIES_MODAL]: SubcommunitiesModal,
  [modalIds.GROUP_MODAL]: GroupInfoModal,
};

export default modalsMap;
