import api from './Api';

const getPosts = (communityId) => {
    return api.get(`/v2/admin/knowledge-library/community/${communityId}`);
}

const approvePost = (id) => {
    return api.post(`/v2/admin/knowledge-library/${id}/approve`);
}

export default {
    getPosts,
    approvePost,
}
