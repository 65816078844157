import {
  select,
  put,
  call,
  takeEvery,
  all,
} from 'redux-saga/effects';
import api from '../../api/conditions';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { normalize, formatCase } from '../../utils/store';

function* onSetGroupId({ payload: groupId }) {
  yield put(setLoader(true));

  try {
    const conditions = yield call(api.get, groupId);
    const normalizedConditions = normalize(conditions, 'conditionIds', 'conditionEntities');

    yield put(actions.setConditions(normalizedConditions));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onAddCondition({ payload }) {
  yield put(setLoader(true));

  try {
    const groupId = yield select(selectors.getGroupId);

    const newCondition = yield call(api.add, formatCase.toSnakeCase({
      ...payload,
      groupId,
    }));

    yield put(actions.addConditionSuccess(formatCase.toCamelCase(newCondition)));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onUpdateCondition({ payload }) {
  const groupId = yield select(selectors.getGroupId);

  yield call(api.update, formatCase.toSnakeCase({
    groupId,
    ...payload,
  }));

  const { conditionId: id, ...rest } = payload;

  yield put(actions.updateConditionSuccess({ id, ...rest }));
}

function* onToggleConditionActive({ payload }) {
  const toggleFunc = payload.isActive
    ? api.activate
    : api.deactivate;

  const groupId = yield select(selectors.getGroupId);

  yield call(toggleFunc, groupId, payload.id);

  yield put(actions.updateConditionSuccess(payload));
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.SET_GROUP_ID, onSetGroupId),
    takeEvery(AT.ADD_CONDITION, onAddCondition),
    takeEvery(AT.UPDATE_CONDITION, onUpdateCondition),
    takeEvery(AT.TOGGLE_CONDITION_ACTIVE, onToggleConditionActive),
  ]);
}
