import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './TextLabel.module.scss';

export const textLabelColors = {
  danger: 'danger',
  null: null,
};

const TextLabel = ({ children, className, color }) => {
  return (
    <sup
      className={cls(className, styles.textLabel, 'ml-2', {
        [`text-${color}`]: color,
      })}
    >
      {children}
    </sup>
  );
};

TextLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(textLabelColors)),
};

TextLabel.defaultProps = {
  className: null,
  color: textLabelColors.null,
};

export default TextLabel;
