import loginAPI from '../api/login';
import PATHS from '../constants/localStoragePaths';
import { getIsAuthorized, IS_AUTHORIZED_FLAG } from '../utils/auth';

const authProvider = {
  login: async (params) => {
    const data = await loginAPI.sendVerificationCode(params)
      .catch((error) => Promise.reject(error));

    if (data) {
      localStorage.setItem(PATHS.IS_AUTHORIZED, IS_AUTHORIZED_FLAG);
      localStorage.setItem(PATHS.USER_INFO, JSON.stringify(data));
      return Promise.resolve();
    }

    return Promise.reject();
  },
  logout: async () => {
    const isAuthorized = getIsAuthorized();

    if (isAuthorized) {
      await loginAPI.logout();
    }

    localStorage.removeItem(PATHS.USER_INFO);
    localStorage.removeItem(PATHS.IS_AUTHORIZED);

    return Promise.resolve();
  },
  checkError: ({ status }) => {
    return (
      (status === 401 || status === 403)
        ? Promise.reject()
        : Promise.resolve()
    );
  },
  checkAuth: () => {
    const isAuthorized = getIsAuthorized();

    return isAuthorized
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem(PATHS.IS_AUTHORIZED);
    return (
      role
        ? Promise.resolve(role)
        : Promise.reject()
    );
  },
};

export default authProvider;
