import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Table = ({
  data,
  columns,
  isLoading,
  paginationProps,
  useOverlay,
  ...rest
}) => {
  const translate = useTranslate();

  const noDataIndication = () => {
    const path = isLoading
      ? 'general.label.loading'
      : 'general.label.noData';

    return translate(path);
  };

  const pagination = paginationProps && paginationFactory({
    ...paginationProps,
    paginationSize: 3,
    hideSizePerPage: true,
    alwaysShowAllBtns: false,
    hidePageListOnlyOnePage: true,
    withFirstAndLast: false,
  });

  const overlay = overlayFactory({
    spinner: true,
    styles: {
      overlay: (base) => {
        return {
          ...base,
          background: 'rgba(0, 0, 0, 0.1)',
          zIndex: 99,
        };
      },
    },
  });

  const props = {
    ...(useOverlay && { overlay }),
    ...(paginationProps && { pagination }),
  };

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      isLoading={isLoading}
      noDataIndication={noDataIndication}
      wrapperClasses="table-responsive"
      bordered={false}
      hover
      striped
      bootstrap4
      {...props}
      {...rest}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    dataField: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  isLoading: PropTypes.bool,
  paginationProps: PropTypes.shape({
    onPageChange: PropTypes.func,
    sizePerPage: PropTypes.number,
  }),
  useOverlay: PropTypes.bool,
};

Table.defaultProps = {
  isLoading: false,
  paginationProps: null,
  useOverlay: false,
};

export default Table;
