// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ImageUploader_label__2EkIU {\n  font-size: 1.5rem;\n  margin-bottom: 1.5rem !important;\n  color: #777A7E;\n}\n\n.ImageUploader_fileInput__1K5rK.ImageUploader_fileInputNew__Xm0V4 {\n  position: relative;\n  box-sizing: content-box;\n}\n.ImageUploader_fileInput__1K5rK.ImageUploader_fileInputNew__Xm0V4 .ImageUploader_thumbnail__2tLdh {\n  padding: 0.25rem;\n  line-height: 1.5;\n  background-color: #F5F6F7;\n  border: 1px solid #d6dee5;\n  border-radius: 0.3rem;\n  transition: all 0.2s ease-in-out;\n  max-width: 200px;\n  max-height: 200px;\n  overflow: hidden;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);\n}\n\n.ImageUploader_fileInputButton__1Xvn8 {\n  height: 40px;\n  align-self: flex-end;\n}\n\n.ImageUploader_fileInputGroup__1KwYh {\n  grid-template: 1fr/min-content;\n  grid-gap: 0.5rem;\n}\n\n.ImageUploader_previewImage__30kAD {\n  max-width: 200px;\n  max-height: 200px;\n  object-fit: contain;\n}\n\n.ImageUploader_emptyPreview__2wPTj {\n  width: 200px;\n  height: 200px;\n  background-color: #e9ecef;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  font-size: 1.5rem;\n}\n\n.ImageUploader_dimensionsMessage__tqsih {\n  font-weight: bold;\n  font-size: 0.875rem;\n  color: rgb(110.4798657718, 111.9932885906, 115.0201342282);\n  margin-bottom: 0.5rem;\n}", ""]);
// Exports
exports.locals = {
	"label": "ImageUploader_label__2EkIU",
	"fileInput": "ImageUploader_fileInput__1K5rK",
	"fileInputNew": "ImageUploader_fileInputNew__Xm0V4",
	"thumbnail": "ImageUploader_thumbnail__2tLdh",
	"fileInputButton": "ImageUploader_fileInputButton__1Xvn8",
	"fileInputGroup": "ImageUploader_fileInputGroup__1KwYh",
	"previewImage": "ImageUploader_previewImage__30kAD",
	"emptyPreview": "ImageUploader_emptyPreview__2wPTj",
	"dimensionsMessage": "ImageUploader_dimensionsMessage__tqsih"
};
module.exports = exports;
