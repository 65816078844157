import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { getTopReportsAnalytics } from '../../../store/reports/selectors';
import PieChart from '../../../components/Charts/PieChart';
import styles from './ReportsContent.module.scss';

const TopReports = ({ topReportsAnalytics }) => {
  const translate = useTranslate();
  const { item: itemAnalytics, user: userAnalytics } = topReportsAnalytics;

  return (
    <div className={cls('d-grid', styles.chartsWrapper)}>
      <PieChart
        title={translate('pages.reports.labels.charts.titles.item', { total: itemAnalytics.total })}
        data={itemAnalytics.data}
      />

      <PieChart
        title={translate('pages.reports.labels.charts.titles.user', { total: userAnalytics.total })}
        data={userAnalytics.data}
      />
    </div>
  );
};

TopReports.propTypes = {
  topReportsAnalytics: PropTypes.shape({
    item: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          y: PropTypes.number,
          z: PropTypes.number,
        }),
      ),
      total: PropTypes.number,
    }),
    user: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          y: PropTypes.number,
          z: PropTypes.number,
        }),
      ),
      total: PropTypes.number,
    }),
  }).isRequired,
};

export default connect((state) => {
  return {
    topReportsAnalytics: getTopReportsAnalytics(state),
  };
})(TopReports);
