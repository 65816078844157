import { createSelector } from 'reselect';
import { getParamWithoutStateInSelector, sortByProp } from '../../utils/store';

export const getGroupId = (state) => {
  return state.brands.groupId;
};

export const getBrandIds = (state) => {
  return state.brands.brandIds;
};

export const getBrandEntities = (state) => {
  return state.brands.brandEntities;
};

export const getBrands = createSelector(
  [getBrandEntities, getBrandIds],
  (entities, ids) => {
    const brandsList =  ids.map((id) => {
      return entities[id];
    });

    return sortByProp('name')(brandsList);
  },
);

export const getIsBrandNameUnique = createSelector(
  [getBrands, getParamWithoutStateInSelector],
  (brands, brandName) => {
    return !brands.find(({ name }) => {
      return name === brandName;
    });
  },
);
