// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextLabel_textLabel__KqPjk {\n  position: relative;\n  font-size: 0.8rem;\n}", ""]);
// Exports
exports.locals = {
	"textLabel": "TextLabel_textLabel__KqPjk"
};
module.exports = exports;
