// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ReferralUsersModal_modalHeader__3-QGZ {\n  align-self: center;\n}\n.ReferralUsersModal_modalHeader__3-QGZ > h5 {\n  font-weight: bold;\n}\n\n.ReferralUsersModal_header__1y3Wo {\n  width: 100%;\n  text-align: center;\n}\n\n.ReferralUsersModal_listContainer__pR6Sb {\n  max-height: 500px;\n  width: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  overflow-y: scroll;\n  scrollbar-width: none;\n  padding: 1rem 0;\n  background-color: #f8f9fa;\n  gap: 0.5rem;\n}\n.ReferralUsersModal_listContainer__pR6Sb::-webkit-scrollbar {\n  display: none;\n}\n\n.ReferralUsersModal_listItem__amUry {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1rem;\n  padding: 1rem;\n  cursor: pointer;\n}\n.ReferralUsersModal_listItem__amUry:hover {\n  background-color: #d6dee5;\n}\n\n.ReferralUsersModal_profileImage__5gorY {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  border: 1px solid #333951 !important;\n  background-color: #d6dee5;\n  object-fit: cover;\n}", ""]);
// Exports
exports.locals = {
	"modalHeader": "ReferralUsersModal_modalHeader__3-QGZ",
	"header": "ReferralUsersModal_header__1y3Wo",
	"listContainer": "ReferralUsersModal_listContainer__pR6Sb",
	"listItem": "ReferralUsersModal_listItem__amUry",
	"profileImage": "ReferralUsersModal_profileImage__5gorY"
};
module.exports = exports;
