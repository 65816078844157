import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { ModalHeader as UIModalHeader } from 'reactstrap';
import styles from '../wrappers/Modal.module.scss';

const ModalHeader = ({
  children = '',
  className = '',
  ...props
}) => {
  return (
    <UIModalHeader
      close={false}
      className={cls(styles.modalHeader, 'border-0', className)}
      {...props}
    >
      {children}
    </UIModalHeader>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
};

ModalHeader.defaultProps = {
  children: '',
  className: '',
};

export default ModalHeader;
