import api from './Api';

const get = (groupId) => {
  return api.get(`/sizes/${groupId}`);
};

const add = (data) => {
  return api.post('/size', data);
};

const update = (data) => {
  return api.put('/size', data);
};

const activate = (groupId, sizeId) => {
  return api.put(`/size/activate/${groupId}/${sizeId}`);
};

const deactivate = (groupId, sizeId) => {
  return api.put(`/size/deactivate/${groupId}/${sizeId}`);
};

export default {
  get,
  add,
  update,
  activate,
  deactivate,
};
