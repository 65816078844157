// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.CommunityInfo_communityInfoWidget__1yhGA {\n  min-height: 50vh;\n}\n\n.CommunityInfo_communityInfoWrapper__VF9aN {\n  grid-template: auto/70%;\n  grid-gap: 4rem;\n}\n\n.CommunityInfo_searchButton__1kQrs {\n  display: block;\n  width: 100%;\n  padding: 8px;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  background-color: #189BC9;\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n}\n\n.CommunityInfo_searchInput__Kkxn0 {\n  width: 40%;\n  height: 40px;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  margin-bottom: 20px;\n}\n\n@media (max-width: 991.98px) {\n  .CommunityInfo_communityInfoWrapper__VF9aN {\n    grid-template-columns: 1fr;\n  }\n}", ""]);
// Exports
exports.locals = {
	"communityInfoWidget": "CommunityInfo_communityInfoWidget__1yhGA",
	"communityInfoWrapper": "CommunityInfo_communityInfoWrapper__VF9aN",
	"searchButton": "CommunityInfo_searchButton__1kQrs",
	"searchInput": "CommunityInfo_searchInput__Kkxn0"
};
module.exports = exports;
