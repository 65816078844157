const prefix = 'brands/';

export const SET_GROUP_ID = `${prefix}SET_GROUP_ID`;

export const SET_BRANDS = `${prefix}SET_BRANDS`;

export const TOGGLE_BRAND_ACTIVE = `${prefix}TOGGLE_BRAND_ACTIVE`;

export const UPDATE_BRAND = `${prefix}UPDATE_BRAND`;
export const UPDATE_BRAND_SUCCESS = `${prefix}UPDATE_BRAND_SUCCESS`;

export const ADD_BRAND = `${prefix}ADD_BRAND`;
export const ADD_BRAND_SUCCESS = `${prefix}ADD_BRAND_SUCCESS`;
