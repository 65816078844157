import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  subcommunitiesEntities: {},
  subcommunitiesIds: [],
  selectedSubcommunityId: null,
};

const reducer = handleActions(
  {
    [AT.GET_SUBCOMMUNITIES_SUCCESS]: deepMerge(({ payload }) => {
      return {
        subcommunitiesEntities: payload.subcommunitiesEntities,
        subcommunitiesIds: payload.subcommunitiesIds,
      };
    }),
    [AT.SET_SUBCOMMUNITIES]: deepMerge(({ payload }) => {
      return {
        subcommunitiesEntities: payload.subcommunitiesEntities,
        subcommunitiesIds: payload.subcommunitiesIds,
      };
    }),
    [AT.ADD_SUBCOMMUNITY_SUCCESS]: deepMerge(({ payload }, state) => {
      return {
        subcommunitiesEntities: {
          [payload.id]: payload,
        },
        subcommunitiesIds: [...state.subcommunitiesIds, payload.id],
      };
    }),
    [AT.SET_SUBCOMMUNITY_ID]: deepMerge(({ payload }, state) => {
      return {
        selectedSubcommunityId: payload,
      };
    }),
    [AT.UPDATE_SUBCOMMUNITY_SUCCESS]: deepMerge(({ payload }) => {
      return {
        subcommunitiesEntities: {
          [payload.id]: payload,
        },
      };
    }),
    [CLEAR_STATE]: () => {
      return initialState;
    },
  },
  initialState,
);

export default reducer;
