import api from './Api';

const getCategories = (groupId) => {
  return api.get(`/allCategories/${groupId}`);
};

const addCategory = (data) => {
  return api.post('/category', data);
};

const updateCategory = (data) => {
  return api.put('/category', data);
};

const reorderCategory = (data) => {
  return api.put('/reorderCategory', data);
};

const activateCategory = (data) => {
  return api.post('/activateCategory', data);
};

const deactivateCategory = (categoryId) => {
  return api.delete(`/category/${categoryId}`);
};

export default {
  getCategories,
  addCategory,
  updateCategory,
  reorderCategory,
  deactivateCategory,
  activateCategory,
};
