import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { getSelectedCommunityId } from '../community/selectors';
import api from '../../api/talents';
import { showErrorMessage } from '../ui/operations';
import { normalize } from '../../utils/store';
import {
  TALENTS_INITIAL_LIMIT,
  TALENTS_PAGE_LIMIT,
  DEFAULT_SEARCH_TERM,
} from '../../constants/talents';

function* onGetCommunityTalents({ payload = {} }) {
  try {
    const {
      isLoadMore = true,
      searchTerm = DEFAULT_SEARCH_TERM,
      nextPage = 0,
    } = payload;

    const communityId = yield select(getSelectedCommunityId);
    const currentTalentsOffset = yield select(selectors.getTalentsOffset);
    const isExistMore = yield select(selectors.getTalentsExistMore);
    const isNeedToFetchData = currentTalentsOffset <= (nextPage * TALENTS_PAGE_LIMIT);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const skip = isLoadMore
      ? currentTalentsOffset
      : 0;

    const limit = skip === 0
      ? TALENTS_INITIAL_LIMIT
      : TALENTS_PAGE_LIMIT;

    const talents = yield call(api.getCommunityTalents, { skip, limit, communityId, searchTerm });
    const hasMore = talents.length === limit;

    const normalizedTalents = normalize(talents);

    yield put(actions.getCommunityTalentsSuccess({
      ...normalizedTalents,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onHideTalent({ payload: talentId }) {
  try {
    yield call(api.hideTalent, talentId);
    yield put(actions.hideTalentSuccess({ talentId }));
    yield put(showNotification('message.success.action.hideTalent'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_COMMUNITY_TALENTS, onGetCommunityTalents),
    takeEvery(AT.HIDE_TALENT, onHideTalent),
  ]);
}
