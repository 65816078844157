const prefix = 'categories/';

export const SET_GROUP_ID = `${prefix}SET_GROUP_ID`;

export const SET_CATEGORIES = `${prefix}SET_CATEGORIES`;

export const ADD_CATEGORY = `${prefix}ADD_CATEGORY`;
export const ADD_CATEGORY_SUCCESS = `${prefix}ADD_CATEGORY_SUCCESS`;

export const SET_IS_CATEGORY_ACTIVE = `${prefix}SET_IS_CATEGORY_ACTIVE`;

export const UPDATE_CATEGORY = `${prefix}UPDATE_CATEGORY`;
export const UPDATE_CATEGORY_SUCCESS = `${prefix}UPDATE_CATEGORY_SUCCESS`;

export const REORDER_CATEGORY = `${prefix}REORDER_CATEGORY`;
