import React from 'react';
import Loader from '../../../../../components/Loader';

const RequestsBox = ({ users, onAccept, onDecline, isLoading }) => {
  return (
    <div className="w-full border-1 h-[190px] border-gray9 rounded-lg mt-7 px-4 py-2">
      <span className="text-lg font-medium text-dark1">Requests</span>

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="max-h-[300px] overflow-y-auto mt-4 pr-4 -mr-4">
          {users?.map((user, index) => (
            <div key={user.id}>
              <UserItem user={user} onAccept={onAccept} onDecline={onDecline} />
              {index < users?.length - 1 && <div className="border-b border-gray-300 my-2"></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const UserItem = ({ user, onAccept, onDecline }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-2 mt-4">
      <div className="flex flex-row items-center gap-2">
        <img src={user?.user_profile_image || ''} alt="avatar" className="w-12 h-12 rounded-full" />
        <span className="text-lg font-medium text-dark1">{user.user_name}</span>
      </div>

      <div className="flex flex-row items-center gap-2">
        <button
          className="bg-success px-2 py-1 rounded-[4px] text-sm font-medium text-white"
          onClick={() => onAccept(user)}
        >
          Accept
        </button>
        <button
          className="bg-white px-2 py-1 rounded-[4px] text-sm font-medium text-dark1 border-1 border-red2"
          onClick={() => onDecline(user)}
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default RequestsBox;
