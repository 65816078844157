import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  groupId: null,
  brandEntities: {},
  brandIds: [],
};

const reducer = handleActions({
  [AT.SET_GROUP_ID]: deepMerge(({ payload: groupId }) => {
    return { groupId };
  }),
  [AT.SET_BRANDS]: deepMerge(({ payload }) => {
    return {
      brandEntities: payload.brandEntities,
      brandIds: payload.brandIds,
    };
  }),
  [AT.ADD_BRAND_SUCCESS]: deepMerge(({ payload: newBrand }, state) => {
    return {
      brandEntities: {
        [newBrand.id]: newBrand,
      },
      brandIds: [...state.brandIds, newBrand.id],
    };
  }),
  [AT.UPDATE_BRAND_SUCCESS]: deepMerge(({ payload }) => {
    return {
      brandEntities: {
        [payload.id]: payload,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
