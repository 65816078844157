// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.SummaryStatistics_title__aNECK {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n.SummaryStatistics_container__1SJTn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n\n.SummaryStatistics_containerStatistics__3yZTI {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 10rem;\n}\n\n.SummaryStatistics_containerStatisticsItem__34sNI {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.SummaryStatistics_statisticsItemTitle__OtO7H {\n  font-size: 1.25rem;\n  font-weight: 500;\n  color: #4d4d4d;\n}\n\n.SummaryStatistics_statisticsItemValue__7QAQw {\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n\n.SummaryStatistics_containerBarChart__3F6dL {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  gap: 5rem;\n  margin-top: 20px;\n}\n\n.SummaryStatistics_containerBarChartColumn__3olu9 {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  gap: 5rem;\n  margin-top: 20px;\n}\n\n.SummaryStatistics_containerStatisticsChart__3ACm2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4rem;\n}\n\n.SummaryStatistics_containerPercentage__36Lkx {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding-left: 5rem;\n}", ""]);
// Exports
exports.locals = {
	"title": "SummaryStatistics_title__aNECK",
	"container": "SummaryStatistics_container__1SJTn",
	"containerStatistics": "SummaryStatistics_containerStatistics__3yZTI",
	"containerStatisticsItem": "SummaryStatistics_containerStatisticsItem__34sNI",
	"statisticsItemTitle": "SummaryStatistics_statisticsItemTitle__OtO7H",
	"statisticsItemValue": "SummaryStatistics_statisticsItemValue__7QAQw",
	"containerBarChart": "SummaryStatistics_containerBarChart__3F6dL",
	"containerBarChartColumn": "SummaryStatistics_containerBarChartColumn__3olu9",
	"containerStatisticsChart": "SummaryStatistics_containerStatisticsChart__3ACm2",
	"containerPercentage": "SummaryStatistics_containerPercentage__36Lkx"
};
module.exports = exports;
