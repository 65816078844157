import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const InvalidPinDialog = ({ onDialogAction }) => {
  const [open, setOpen] = useState(true);
  const translate = useTranslate();

  const handleClose = () => {
    setOpen(false);
  };

  const onHandleOk = () => {
    handleClose();
    onDialogAction(0);
  };

  const onHandleSendAnother = () => {
    handleClose();
    onDialogAction(1);
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          {translate('pages.handle_code.dialog_title')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{translate('pages.handle_code.dialog_message')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onHandleOk} color="primary">
            {translate('general.button.okay')}
          </Button>
          <Button autoFocus onClick={onHandleSendAnother} color="primary">
            {translate('general.button.send_another')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

InvalidPinDialog.propTypes = {
  onDialogAction: PropTypes.func.isRequired,
};

export default InvalidPinDialog;
