import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';
import { deepMerge } from '../../utils/store';

const initialState = {
  ids: [],
  entities: {},
  isLoading: false,
  isExistMore: true,
  analytics: {},
};

const reducer = handleActions({
  [AT.SET_IS_LOADING]: deepMerge(({ payload }) => ({
    isLoading: payload,
  })),

  [AT.GET_COMMUNITY_WALLETS_SUCCESS]: deepMerge(({ payload }, state) => ({
    entities: payload.entities,
    ids: payload.isLoadMore
      ? [...state.ids, ...payload.ids]
      : payload.ids,
    isExistMore: payload.isLoadMore
      ? payload.isExistMore
      : true,
  })),

  [AT.GET_WALLETS_ANALYTICS_SUCCESS]: deepMerge(({ payload })=> ({
    analytics: payload,
  })),

  [CLEAR_STATE]: () => initialState,
}, initialState);

export default reducer;
