import { createAction } from 'redux-actions';
import * as AT from './types';

export const setGroupId = createAction(AT.SET_GROUP_ID);

export const setBrands = createAction(AT.SET_BRANDS);

export const addBrand = createAction(AT.ADD_BRAND);
export const addBrandSuccess = createAction(AT.ADD_BRAND_SUCCESS);

export const updateBrand = createAction(AT.UPDATE_BRAND);
export const updateBrandSuccess = createAction(AT.UPDATE_BRAND_SUCCESS);

export const toggleBrandActive = createAction(AT.TOGGLE_BRAND_ACTIVE);
