export const getIsLoading = (state) => {
  return state.ui.isLoading;
};

export const getIsSidebarOpen = (state) => {
  return state.admin.ui.sidebarOpen;
};

export const getIsSidebarPinned = (state) => {
  return state.ui.isSidebarPinned;
};

export const getCurrentLocale = (state) => {
  return state.ui.locale;
};
