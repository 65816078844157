import * as React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Badge.module.scss';

export const badgeThemes = {
  primary: 'primary',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
};

const Badge = ({ title, theme, size, className }) => {
  return (
    <div className={cls(styles.badge, className, {
      [`bg-${theme}`]: theme,
    })}>
      <span className={cls('text-white', 'font-weight-bold')} style={{ fontSize: size }}>
        {title}
      </span>
    </div>
  );
};

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(badgeThemes)),
  size: PropTypes.number,
  className: PropTypes.string,
};

Badge.defaultProps = {
  theme: badgeThemes.primary,
  className: null,
  size: null,
};

export const badgeProps = {
  themes: badgeThemes,
};

export default Badge;
