import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as brandsActions from '../../store/brands/actions';
import * as brandsSelectors from '../../store/brands/selectors';
import ModalService from '../../modals/ModalService';
import { modalIds } from '../../modals/const';
import PageContent from '../../components/PageContent';
import PageDescription from '../../components/PageDescription';
import PageNavigation from '../../components/PageNavigation';
import GroupSelect from '../../components/GroupSelect';
import Button, { buttonProps } from '../../components/Button';
import Widget from '../../components/Widget';
import BrandsList from './BrandsList';
import styles from './Brands.module.scss';

const Brands = ({
  brands,
  setGroupId,
  toggleBrandActive,
}) => {
  const translate = useTranslate();

  const onGroupSelectChange = ({ value: groupId }) => {
    setGroupId(groupId);
  };

  const onCreateButtonClick = () => {
    ModalService.open(modalIds.BRANDS_MODAL);
  };

  const openModalWithOptions = (options) => {
    ModalService.open(modalIds.BRANDS_MODAL, options);
  };

  return (
    <PageContent
      title={translate('pages.brands.title.page')}
    >
      <Widget className={styles.brandsWidget}>
        <PageDescription path="pages.brands.description" />

        <PageNavigation
          className="mb-5"
        >
          <GroupSelect
            label={translate('pages.brands.labels.selectGroup')}
            onChange={onGroupSelectChange}
            className="flex-grow-1"
          />
          <Button
            theme={buttonProps.themes.link}
            onClick={onCreateButtonClick}
          >
            {translate('pages.brands.buttons.create')}
          </Button>
        </PageNavigation>

        <BrandsList
          items={brands}
          toggleBrandActive={toggleBrandActive}
          openModal={openModalWithOptions}
        />
      </Widget>
    </PageContent>
  );
};

Brands.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  setGroupId: PropTypes.func.isRequired,
  toggleBrandActive: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      brands: brandsSelectors.getBrands(state),
    };
  }, {
    setGroupId: brandsActions.setGroupId,
    toggleBrandActive: brandsActions.toggleBrandActive,
  },
)(Brands);
