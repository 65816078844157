import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';

const CommunityAboutField = ({
  initialValue,
  updateCommunityInfo,
}) => {
  const translate = useTranslate();
  const [about, setAbout] = useState(initialValue);
  useEffect(() => {
    setAbout(initialValue);
  }, [initialValue]);

  const onSave = () => {
    const data = new FormData();
    data.append('community_about', about);

    updateCommunityInfo(data);
  };

  return (
    <EditableTextarea
      value={initialValue}
      onInputChange={setAbout}
      onSave={onSave}
      id="communityAbout"
      type={inputTypes.textarea}
      label={translate('pages.communityInfo.labels.about')}
      marginTop={false}
      useCustomLabel
    />
  );
};

CommunityAboutField.propTypes = {
  initialValue: PropTypes.string,
  updateCommunityInfo: PropTypes.func.isRequired,
};

CommunityAboutField.defaultProps = {
  initialValue: null,
};

export default CommunityAboutField;
