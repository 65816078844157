// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.EditableTextarea_inputGroup__2FiIV {\n  grid-template: auto/repeat(3, 1fr);\n  grid-row-gap: 2rem;\n}\n.EditableTextarea_inputGroup__2FiIV .EditableTextarea_input__3JHo9 {\n  width: 100% !important;\n  min-height: 50px;\n  border-radius: 5px !important;\n  padding: 0.75rem 1.25rem;\n  grid-column: 1/span 3;\n}\n.EditableTextarea_inputGroup__2FiIV .EditableTextarea_textarea__3Er3V {\n  resize: none;\n  min-height: 200px !important;\n}\n.EditableTextarea_inputGroup__2FiIV .EditableTextarea_buttonGroup__3GegX {\n  grid-column: 3;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: 1rem;\n}\n.EditableTextarea_inputGroup__2FiIV .EditableTextarea_buttonGroup__3GegX > button {\n  height: 40px;\n  border-radius: 6px !important;\n}\n\n.EditableTextarea_customLabel__2OVf1 {\n  font-size: 1.5rem;\n  margin-bottom: 1.5rem;\n  color: #777A7E;\n}\n\n.EditableTextarea_validationMessage__2xeiB {\n  grid-column: 1/3;\n  font-size: 14px !important;\n}\n\n@media (max-width: 575.98px) {\n  .EditableTextarea_inputGroup__2FiIV {\n    grid-template-columns: 1fr;\n  }\n  .EditableTextarea_inputGroup__2FiIV .EditableTextarea_input__3JHo9 {\n    grid-column: 1;\n  }\n  .EditableTextarea_inputGroup__2FiIV .EditableTextarea_buttonGroup__3GegX {\n    grid-column: 1;\n  }\n}", ""]);
// Exports
exports.locals = {
	"inputGroup": "EditableTextarea_inputGroup__2FiIV",
	"input": "EditableTextarea_input__3JHo9",
	"textarea": "EditableTextarea_textarea__3Er3V",
	"buttonGroup": "EditableTextarea_buttonGroup__3GegX",
	"customLabel": "EditableTextarea_customLabel__2OVf1",
	"validationMessage": "EditableTextarea_validationMessage__2xeiB"
};
module.exports = exports;
