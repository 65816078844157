import api from './Api';

const loginByMobileNumber = (data) => {
  return api.post('/admin/login', data);
};

const sendVerificationCode = (data) => {
  return api.post('/admin/codeVerification', data);
};

const logout = () => {
  return api.post('/admin/logout');
};

export default {
  loginByMobileNumber,
  sendVerificationCode,
  logout,
};
