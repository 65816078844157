import { createSelector } from 'reselect';

export const getWalletIds = (state) => {
  return state.wallets.ids;
};

export const getWalletEntities = (state) => {
  return state.wallets.entities;
};

export const getIsWalletsLoading = (state) => {
  return state.wallets.isLoading;
};

export const getWalletsExistMore =  (state) => {
  return state.wallets.isExistMore;
};

export const getWalletsAnalytics = (state) => {
  return state.wallets.analytics;
};

export const getWallets = createSelector(
  [getWalletIds, getWalletEntities],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export const getWalletsOffset = createSelector(
  [getWalletIds],
  (ids) => {
    return ids.length;
  },
);
