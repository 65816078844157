import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';

const initialState = {
  errors: {},
};

const reducer = handleActions({
  [AT.VALIDATE_FIELD_ERROR]: deepMerge(({ payload }) => {
    return {
      errors: {
        [payload.fieldId]: payload.error,
      },
    };
  }),
  [AT.VALIDATE_FIELD_SUCCESS]: deepMerge(({ payload: fieldId }) => {
    return {
      errors: {
        [fieldId]: null,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
