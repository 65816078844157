import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import { useDebouncedCallback as debounce } from 'use-debounce';

import ModalService from '../../../modals/ModalService';
import { modalIds } from '../../../modals/const';
import { TRANSACTIONS_LIMIT_PER_PAGE, TRANSACTIONS_TYPES } from '../../../constants/transactions';
import { getExportToSCVFileName } from '../../../utils/transactions';
import { formatDateWithLocale } from '../../../utils/dateFns';
import { offerTypes } from '../../../constants/offers';
import Table from '../../../components/Table';
import Button, { buttonProps } from '../../../components/Button';
import ButtonWithPopover from '../../../components/Button/ButtonWithPopover';
import Search from '../../../components/Search';
import Text, { textProps } from '../../../components/Text';
import styles from './TransactionsTable.module.scss';

const TransactionsTable = ({
  items,
  type,
  isLoading,
  getItems,
  declineItemRequest,
  performRefund,
}) => {
  const translate = useTranslate();
  const locale = useLocale();
  const [searchTerm, setSearchTerm] = useState('');
  const [offerIdSearchTerm, setOfferIdSearchTerm] = useState('');

  const isIssuedTransactions = type === TRANSACTIONS_TYPES.issue;

  const idFormatter = (value) => {
    const [id] = value.split('-');

    return id;
  };

  const dateFormatter = (value) => {
    return formatDateWithLocale(value, locale);
  };

  const groupNameFormatter = (value) => {
    return value[locale];
  };

  const reasonFormatter = (value, row) => {
    if (type === TRANSACTIONS_TYPES.bonus) {
      return value[locale];
    }

    if (type === TRANSACTIONS_TYPES.transfer) {
      return (
        <div className={styles.itemInfoWrapper}>
          <Text size={textProps.sizes.small} weight={textProps.weights.semiBold}>
            {value}
          </Text>
          <Text size={textProps.sizes.xSmall}>
            {`(ID: ${row.itemId})`}
          </Text>
        </div>
      );
    }

    return value;
  };

  const senderFormatter = (value, row) => {
    if (type === TRANSACTIONS_TYPES.bonus) {
      return value;
    }

    return (
      <Link to={`/members/${row.senderId}`}>
        {value}
      </Link>
    );
  };

  const receiverFormatter = (value, row) => {
    return (
      <Link to={`/members/${row.receiverId}`}>
        {value}
      </Link>
    );
  };

  const offerInfoFormatter = (value, row) => {
    const statusId = parseInt(value, 10);

    const offerStatusMap = {
      [offerTypes.INTERESTED]: translate('offers.types.interested'),
      [offerTypes.PENDING_DECISION]: translate('offers.types.pendingDecision'),
      [offerTypes.OFFER_CONFIRMED]: translate('offers.types.offerConfirmed'),
      [offerTypes.ITEM_DELIVERED]: translate('offers.types.itemDelivered'),
      [offerTypes.NOT_AVAILABLE]: translate('offers.types.notAvailable'),
      [offerTypes.ITEM_SOLD]: translate('offers.types.itemSold'),
      [offerTypes.SELLER_LEFT_COMMUNITY]: translate('offers.types.sellerLeftCommunity'),
      [offerTypes.BUYER_LEFT_COMMUNITY]: translate('offers.types.buyerLeftCommunity'),
      [offerTypes.ITEM_REMOVED]: translate('offers.types.itemRemoved'),
    };

    return (
      <div className={styles.itemInfoWrapper}>
        <Text size={textProps.sizes.small} weight={textProps.weights.semiBold}>
          {`Status: ${offerStatusMap[statusId]}`}
        </Text>
        <Text size={textProps.sizes.xSmall}>
          {`(ID: ${row.offerId})`}
        </Text>
      </div>
    );
  };

  const actionsFormatter = (_, row) => {
    const onDeclineItemRequestClick = () => {
      declineItemRequest({ transactionId: row.id, offerId: row.offerId });
    };

    const onPerformRefundClick = () => {
      performRefund({ transactionId: row.id, offerId: row.offerId });
    };

    const isPendingDecision = parseInt(row.offerStatus, 10) === offerTypes.PENDING_DECISION;
    const isConfirmedOffer = parseInt(row.offerStatus, 10) === offerTypes.OFFER_CONFIRMED;

    return (
      <ButtonWithPopover
        id={`button-${row.id}`}
        title={
          <i className={cls('fa', 'fa-ellipsis-v')} />
        }
        disabled={!isPendingDecision && !isConfirmedOffer}
      >
        {isPendingDecision && (
          <Button
            onClick={onDeclineItemRequestClick}
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            marginTop={false}
          >
            {translate('pages.transactions.table.buttons.performDecline')}
          </Button>
        )}

        {isConfirmedOffer && (
          <Button
            onClick={onPerformRefundClick}
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            marginTop={false}
          >
            {translate('pages.transactions.table.buttons.performRefund')}
          </Button>
        )}
      </ButtonWithPopover>
    );
  };

  const onPageChange = (currentPage) => {
    getItems({ isLoadMore: true, searchTerm, nextPage: currentPage + 1, offerIdSearchTerm });
  };

  const onSearchChange = debounce((term, data) => {
    setSearchTerm(term);
    getItems({ isLoadMore: false, searchTerm: term, offerIdSearchTerm });

    return data;
  }, 300);

  const onOfferIdSearchChange = debounce((term, data) => {
    setOfferIdSearchTerm(term);
    getItems({ isLoadMore: false, searchTerm, offerIdSearchTerm: term });

    return data;
  }, 300);

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.transactions.table.columns.id'),
      csvFormatter: idFormatter,
      hidden: true,
    }, {
      dataField: 'createdAt',
      text: translate('pages.transactions.table.columns.createdAt'),
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    }, {
      dataField: 'receiverName',
      text: translate('pages.transactions.table.columns.receiverName'),
      formatter: receiverFormatter,
    }, {
      dataField: 'senderName',
      text: translate(`pages.transactions.table.columns.senderName.${type}`),
      formatter: senderFormatter,
    }, {
      dataField: 'groupName',
      text: translate('pages.transactions.table.columns.groupName'),
      formatter: groupNameFormatter,
      csvFormatter: groupNameFormatter,
      hidden: type !== TRANSACTIONS_TYPES.transfer,
    }, {
      dataField: 'amount',
      text: translate('pages.transactions.table.columns.amount'),
    }, {
      dataField: 'reason',
      text: translate(`pages.transactions.table.columns.reason.${type}`),
      formatter: reasonFormatter,
      csvFormatter: reasonFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'offerStatus',
      text: translate('pages.transactions.table.columns.offerInfo'),
      formatter: offerInfoFormatter,
      hidden: type !== TRANSACTIONS_TYPES.transfer,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: '',
      text: translate('pages.transactions.table.columns.actions'),
      formatter: actionsFormatter,
      hidden: type !== TRANSACTIONS_TYPES.transfer,
    },
  ];

  const paginationProps = {
    onPageChange,
    sizePerPage: TRANSACTIONS_LIMIT_PER_PAGE,
  };

  const exportCSVConfig = {
    fileName: getExportToSCVFileName(type),
    noAutoBOM: false,
    exportAll: false,
  };

  const onCreateTransactionClick = () => {
    ModalService.open(modalIds.TRANSACTIONS_MODAL);
  };

  return (
    <ToolkitProvider
      keyField="id"
      columns={columns}
      data={items}
      exportCSV={exportCSVConfig}
    >
      {({ csvProps, baseProps }) => {
        const onSCVButtonClick = () => {
          csvProps.onExport();
        };

        return (
          <>
            <div className={cls(styles.buttonsWrapper, 'mb-5')}>
              <Button
                onClick={onSCVButtonClick}
                theme={buttonProps.themes.success}
                marginTop={false}
                outline
              >
                {translate('pages.transactions.labels.export')}
              </Button>

              {isIssuedTransactions && (
                <Button
                  onClick={onCreateTransactionClick}
                  theme={buttonProps.themes.primary}
                  marginTop={false}
                  outline
                >
                  {translate('pages.transactions.labels.create')}
                </Button>
              )}
            </div>
            <div
              className={
                cls(
                  styles.searchWrapper,
                  type === TRANSACTIONS_TYPES.transfer
                    ? 'mb-2'
                    : 'mb-5',
                )
              }
            >
              <Search
                onChange={onSearchChange.callback}
                placeholder={translate('pages.transactions.labels.search.placeholder')}
                className={styles.searchPanel}
              />
            </div>
            {type === TRANSACTIONS_TYPES.transfer && (
              <div className={cls(styles.searchWrapper, 'mb-5')}>
                <Search
                  onChange={onOfferIdSearchChange.callback}
                  placeholder={translate('pages.transactions.labels.search.offerPlaceholder')}
                  className={styles.searchPanel}
                />
              </div>
            )}

            <Table
              {...baseProps}
              paginationProps={paginationProps}
              loading={isLoading}
              useOverlay={false}
            />
          </>
        );
      }}
    </ToolkitProvider>
  );
};

TransactionsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      senderId: PropTypes.string,
      senderName: PropTypes.string,
      receiverId: PropTypes.string,
      receiverName: PropTypes.string,
      groupName: PropTypes.shape({
        en: PropTypes.string,
        he: PropTypes.string,
      }),
      amount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      createdAt: PropTypes.string,
      reason: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ en: PropTypes.string, he: PropTypes.string }),
      ]),
      itemId: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getItems: PropTypes.func.isRequired,
  declineItemRequest: PropTypes.func.isRequired,
  performRefund: PropTypes.func.isRequired,
};

export default TransactionsTable;
