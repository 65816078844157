// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ConfirmationCodeInput_codeInputWrapper__19AHr {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  margin: 20px 0;\n}\n\n.ConfirmationCodeInput_codeInputGroup__U4baz {\n  display: flex !important;\n  flex-flow: row nowrap;\n  padding: 10px 20px;\n  border-radius: 50px;\n  background-color: #EFF2F5;\n}\n.ConfirmationCodeInput_codeInputGroup__U4baz input {\n  font-family: \"Montserrat\", sans-serif;\n  -moz-appearance: textfield;\n  border: none;\n  margin: 8px;\n  padding: 0;\n  text-align: center;\n  width: 30px;\n  height: 38px;\n  font-size: 32px;\n  box-sizing: border-box;\n  color: #47494C;\n  background-color: transparent;\n  border-bottom: 1px solid #47494C;\n  border-radius: 0;\n  outline: none;\n}\n\n.ConfirmationCodeInput_inputGroup__2kb7I {\n  direction: ltr !important;\n}", ""]);
// Exports
exports.locals = {
	"codeInputWrapper": "ConfirmationCodeInput_codeInputWrapper__19AHr",
	"codeInputGroup": "ConfirmationCodeInput_codeInputGroup__U4baz",
	"inputGroup": "ConfirmationCodeInput_inputGroup__2kb7I"
};
module.exports = exports;
