import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Input } from 'reactstrap';
import styles from './Search.module.scss';

const SearchInput = ({
  onChange,
  placeholder,
  className,
  innerRef,
  value,
}) => {
  const onInputChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Input
      type="search"
      name="search"
      placeholder={placeholder}
      className={cls(styles.input, className)}
      innerRef={innerRef}
      onChange={onInputChange}
      value={value}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

  onChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  placeholder: null,
  className: null,
  innerRef: null,
};

export default SearchInput;
