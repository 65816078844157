import { createAction } from 'redux-actions';
import * as AT from './types';

export const getCommunityWallets = createAction(AT.GET_COMMUNITY_WALLETS);
export const getCommunityWalletsSuccess = createAction(AT.GET_COMMUNITY_WALLETS_SUCCESS);

export const getWalletsAnalytics = createAction(AT.GET_WALLETS_ANALYTICS);
export const getWalletsAnalyticsSuccess = createAction(AT.GET_WALLETS_ANALYTICS_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);
