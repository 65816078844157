import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody as UIModalBody } from 'reactstrap';

const ModalBody = ({ children }) => {
  return (
    <UIModalBody>
      {children}
    </UIModalBody>
  );
};

ModalBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default ModalBody;
