import api from './Api';

const get = (groupId) => {
  return api.get(`/conditions/${groupId}`);
};

const add = (data) => {
  return api.post('/condition', data);
};

const update = (data) => {
  return api.put('/condition', data);
};

const activate = (groupId, conditionId) => {
  return api.put(`/condition/activate/${groupId}/${conditionId}`, { group_id: groupId });
};

const deactivate = (groupId, conditionId) => {
  return api.put(`/condition/deactivate/${groupId}/${conditionId}`, { group_id: groupId });
};

export default {
  get,
  add,
  update,
  activate,
  deactivate,
};
