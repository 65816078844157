import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';
import { deepMerge } from '../../utils/store';
import { itemStatuses } from '../../constants/items';

const initialState = {
  ids: [],
  entities: {},
  isLoading: false,
  isExistMore: true,
};

const reducer = handleActions({
  [AT.SET_IS_LOADING]: deepMerge(({ payload }) => ({
    isLoading: payload,
  })),

  [AT.GET_COMMUNITY_ITEMS_SUCCESS]: deepMerge(({ payload }, state) => ({
    entities: payload.entities,
    ids: payload.isLoadMore
      ? [...state.ids, ...payload.ids]
      : payload.ids,
    isExistMore: payload.isLoadMore
      ? payload.isExistMore
      : true,
  })),

  [AT.HIDE_ITEM_SUCCESS]: deepMerge(({ payload }) => ({
    entities: {
      [payload.itemId]: {
        status: itemStatuses.HIDDEN,
      },
    },
  })),

  [CLEAR_STATE]: () => initialState,
}, initialState);

export default reducer;
