import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import { deepMerge } from '../../utils/store';

const initialState = {
  groupId: null,
  sizeEntities: {},
  sizeIds: [],
};

const reducer = handleActions({
  [AT.SET_GROUP_ID]: deepMerge(({ payload }) => {
    return {
      groupId: payload,
    };
  }),
  [AT.SET_SIZES]: deepMerge(({ payload }) => {
    return {
      sizeEntities: payload.sizeEntities,
      sizeIds: payload.sizeIds,
    };
  }),
  [AT.ADD_SIZE_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      sizeEntities: {
        [payload.id]: payload,
      },
      sizeIds: [...state.sizeIds, payload.id],
    };
  }),
  [AT.UPDATE_SIZE_SUCCESS]: deepMerge(({ payload }) => {
    return {
      sizeEntities: {
        [payload.id]: payload,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
