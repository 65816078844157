import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  copyRight: {
    direction: 'ltr !important',
    unicodeBidi: 'bidi-override',
  },
});

export const CopyRights = () => {
  const classes = useStyles();

  return (
    <Typography variant="body2" color="textSecondary" align="center" className={classes.copyRight}>
      Copyright ©
      <Link color="inherit" href="https://www.shareitt.com/">
        Shareitt
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default CopyRights;
