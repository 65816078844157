import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/rules/actions';
import { modalModes } from '../../const';
import EditableTextarea from '../../../components/EditableTextarea';

const RulesModal = ({
  options,
  closeModal,
  addRule,
  updateRule,
}) => {
  const translate = useTranslate();
  const [title, setTitle] = useState(options.title);
  const [description, setDescription] = useState(options.description);

  const isSaveButtonDisabled = !title || !description;
  const isEditMode = options.mode === modalModes.EDIT;

  const onSaveClick = () => {
    const onSaveFunc = isEditMode
      ? updateRule
      : addRule;

    const data = isEditMode
      ? { ruleId: options.id, title, description }
      : { title, description };

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.rules.modal.header.edit')
          : translate('pages.rules.modal.header.create')
        }
      </Modal.Header>
      <Modal.Body>
        <EditableTextarea
          value={title}
          onInputChange={setTitle}
          label={translate('pages.rules.modal.inputs.labels.title')}
          maxLength={50}
          id="ruleTitle"
          showButtons={false}
        />
        <EditableTextarea
          value={description}
          onInputChange={setDescription}
          label={translate('pages.rules.modal.inputs.labels.description')}
          maxLength={150}
          id="ruleDescription"
          type="textarea"
          showButtons={false}
        />
      </Modal.Body>
      <Modal.Footer
        onSave={onSaveClick}
        onCancel={closeModal}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </>
  );
};

RulesModal.propTypes = {
  options: PropTypes.shape({
    mode: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  addRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
};

RulesModal.defaultProps = {
  options: {},
};

export default connect(null, {
  addRule: actions.addRule,
  updateRule: actions.updateRule,
})(RulesModal);
