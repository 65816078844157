import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import Widget from '../../../components/Widget';
import styles from './List.module.scss';

const SummaryList = memo(({ list, isLoading }) => {
  const translate = useTranslate();

  return (
    <div className={cls('d-grid', 'mb-3', styles.listWrapper)}>
      {list.map(({ label, value }) => {
        return (
          <Widget
            key={label}
            fetchingData={isLoading}
            title={translate(`pages.dashboard.summary.${label}`)}
          >
            <p
              className={cls(styles.listItemValue, 'mb-0')}
            >
              {value?.toLocaleString()}
            </p>
          </Widget>
        );
      })}
    </div>
  );
});

SummaryList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SummaryList;
