import {
  all,
  takeEvery,
  select,
  put,
  call,
} from 'redux-saga/effects';
import * as AT from './types';
import * as actions from './actions';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import * as selectors from './selectors';
import api from '../../api/sizes';
import { normalize, formatCase } from '../../utils/store';

function* onSetGroupId({ payload: groupId }) {
  yield put(setLoader(true));

  try {
    const sizes = yield call(api.get, groupId);
    const normalizedSizes = normalize(sizes, 'sizeIds', 'sizeEntities');

    yield put(actions.setSizes(normalizedSizes));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onAddSize({ payload }) {
  try {
    const groupId = yield select(selectors.getGroupId);

    const newSize = yield call(api.add, formatCase.toSnakeCase({ groupId, ...payload }));

    yield put(actions.addSizeSuccess(formatCase.toCamelCase(newSize)));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onUpdateSize({ payload }) {
  const groupId = yield select(selectors.getGroupId);

  yield call(api.update, formatCase.toSnakeCase({ groupId, ...payload }));

  const { sizeId: id, ...payloadRest } = payload;

  yield put(actions.updateSizeSuccess({ id, ...payloadRest }));
}

function* onToggleSizeActive({ payload }) {
  const { id, isActive } = payload;
  const groupId = yield select(selectors.getGroupId);

  const toggleFunc = isActive
    ? api.activate
    : api.deactivate;

  yield call(toggleFunc, groupId, id);

  yield put(actions.updateSizeSuccess(payload));
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.SET_GROUP_ID, onSetGroupId),
    takeEvery(AT.ADD_SIZE, onAddSize),
    takeEvery(AT.UPDATE_SIZE, onUpdateSize),
    takeEvery(AT.TOGGLE_SIZE_ACTIVE, onToggleSizeActive),
  ]);
}
