import React from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import { useTranslate } from 'react-admin';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import styles from './ActiveUsers.module.scss';
import { getActiveUsers } from '../../../../../store/reports/selectors';

const ActiveUsers = ({ activeUsers }) => {
  const translate = useTranslate();

  const activeUsersDau = activeUsers?.map((item) => {
    const dateFormatted = format(new Date(item.action_date), 'MMM dd');
    const dau_mau_percentage = (Number(item.dau) / Number(item.mau)) * 100;

    return {
      date: dateFormatted,
      DAU_MAU: Number(dau_mau_percentage.toFixed(1)),
    };
  });

  const activeUsersViews = activeUsers?.map((item) => {
    const dateFormatted = format(new Date(item.action_date), 'MMM dd');

    return {
      date: dateFormatted,
      user_views: item.user_views,
    };
  });

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{translate('pages.reports.active_users')}</h1>

      <div className={styles.containerStatistics}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={activeUsersDau}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[-5, 15]} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="DAU_MAU" stroke="#8884d8" label={{ position: 'top' }} />
          </LineChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={activeUsersViews}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="user_views"
              stroke="#8884d8"
              label={{ position: 'top' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    activeUsers: getActiveUsers(state),
  };
})(ActiveUsers);
