const prefix = 'rules/';

export const GET_RULES = `${prefix}GET_RULES`;
export const GET_RULES_SUCCESS = `${prefix}GET_RULES_SUCCESS`;

export const ADD_RULE = `${prefix}ADD_RULE`;
export const ADD_RULE_SUCCESS = `${prefix}ADD_RULE_SUCCESS`;

export const UPDATE_RULE = `${prefix}UPDATE_RULE`;
export const UPDATE_RULE_SUCCESS = `${prefix}UPDATE_RULE_SUCCESS`;

export const REMOVE_RULE = `${prefix}REMOVE_RULE`;

export const REORDER_RULE = `${prefix}REORDER_RULE`;

export const SET_SELECTED_KIND = `${prefix}SET_SELECTED_KIND`;
