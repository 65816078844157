import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarVisibility } from 'ra-core';
import { getIsSidebarOpen, getIsSidebarPinned } from '../../../store/ui/selectors';
import styles from './Sidebar.module.scss';

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const isSidebarPinned = useSelector(getIsSidebarPinned);

  const onMouseEnter = useCallback(() => {
    if (!isSidebarOpen && !isSidebarPinned) {
      dispatch(setSidebarVisibility(true));
    }
  }, [dispatch, isSidebarOpen, isSidebarPinned]);

  const onMouseLeave = useCallback(() => {
    if (isSidebarOpen && !isSidebarPinned) {
      dispatch(setSidebarVisibility(false));
    }
  }, [dispatch, isSidebarOpen, isSidebarPinned]);

  return (
    <div
      className={cls(styles.sidebarWrapper, {
        [styles.sidebarClose]: !isSidebarOpen && !isSidebarPinned,
      })}
    >
      <nav
        className={styles.root}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </nav>
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Sidebar;
