// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Loader_root__3_ny- {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 120;\n}\n.Loader_root__3_ny- i {\n  font-size: 21px;\n  margin: 0 1rem;\n  color: #798892;\n}", ""]);
// Exports
exports.locals = {
	"root": "Loader_root__3_ny-"
};
module.exports = exports;
