import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar  from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import cls from 'classnames';

import {
  useTranslate,
  hideNotification,
  getNotification,
  complete,
  undoableEventEmitter,
} from 'ra-core';

const useStyles = makeStyles(
  (theme) => ({
    error: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    undo: {
      color: theme.palette.primary.light,
    },
  }),
  { name: 'RaNotification' },
);

const Notification = (props) => {
  const {
    type,
    className,
    autoHideDuration,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const styles = useStyles(props);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    if (notification && notification.undoable) {
      dispatch(complete());
      undoableEventEmitter.emit('end', { isUndo: false });
    }
    dispatch(hideNotification());
  }, [dispatch, notification]);

  const getMessage = () => {
    if (notification && notification.message) {
      return notification.type === 'error'
        ? notification.message.toString()
        : translate(notification.message, notification.messageArgs);
    }

    return null;
  };

  return (
    <Snackbar
      open={open}
      message={getMessage()}
      autoHideDuration={
        (notification && notification.autoHideDuration) ||
        autoHideDuration
      }
      disableWindowBlurListener={notification && notification.undoable}
      onExited={handleExited}
      onClose={handleRequestClose}
      ContentProps={{
        className: cls(
          styles[(notification && notification.type) || type],
          className,
        ),
      }}
      {...rest}
    />
  );
};

Notification.propTypes = {
  type: PropTypes.string,
  autoHideDuration: PropTypes.number,
  className: PropTypes.string,
};

Notification.defaultProps = {
  type: 'error',
  autoHideDuration: 4000,
  className: null,
};

export default Notification;
