import { createAction } from 'redux-actions';
import * as AT from './types';

export const getCities = createAction(AT.GET_CITIES);
export const getCitiesSuccess = createAction(AT.GET_CITIES_SUCCESS);
export const removeCity = createAction(AT.REMOVE_CITY);
export const removeCitySuccess = createAction(AT.REMOVE_CITY_SUCCESS);
export const editCityImage = createAction(AT.EDIT_CITY_IMAGE);
export const editCityImageSuccess = createAction(AT.EDIT_CITY_IMAGE_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);
