import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import { modalModes } from '../../const';
import * as actions from '../../../store/conditions/actions';
import getIsRTL from '../../../utils/isRTL';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';
import styles from './ConditionsModal.module.scss';

const ConditionsModal = ({
  options,
  closeModal,
  addCondition,
  updateCondition,
}) => {
  const translate = useTranslate();
  const isEditMode = options.mode === modalModes.EDIT;
  const isRTL = getIsRTL();

  const [state, setState] = useState({
    titleEn: options.titleEn,
    subtitleEn: options.subtitleEn,
    titleHe: options.titleHe,
    subtitleHe: options.subtitleHe,
    priceCoefficient: options.priceCoefficient,
  });

  const setStateByFieldName = (fieldName) => {
    return (value) => {
      setState((prevState) => {
        return { ...prevState, [fieldName]: value };
      });
    };
  };

  const onSaveClick = () => {
    const onSaveFunc = isEditMode
      ? updateCondition
      : addCondition;

    const data = isEditMode
      ? { conditionId: options.id, ...state }
      : state;

    onSaveFunc(data);
    closeModal();
  };

  const isSaveButtonDisabled = !state.titleEn || !state.subtitleEn || !state.priceCoefficient;
  const numberInputProps = {
    maxLength: 3,
    min: 0,
    max: 10,
    step: 0.1,
  };

  return (
    <>
      <Modal.Header>
        {isEditMode
          ? translate('pages.conditions.modal.header.edit')
          : translate('pages.conditions.modal.header.create')
        }
      </Modal.Header>
      <Modal.Body>
        <fieldset className={cls(styles.fieldset, 'mb-5', 'p-3')}>
          <legend className={cls(styles.legend, 'mb-0')}>
            {translate('pages.conditions.labels.english')}
          </legend>

          <EditableTextarea
            value={state.titleEn}
            onInputChange={setStateByFieldName('titleEn')}
            label={translate('pages.conditions.labels.title')}
            maxLength={15}
            id="titleEn"
            showButtons={false}
            marginTop={false}
            isValid={Boolean(state.titleEn)}
            validationText={translate('pages.conditions.validation.any')}
            useValidation={!isRTL}
          />

          <EditableTextarea
            value={state.subtitleEn}
            onInputChange={setStateByFieldName('subtitleEn')}
            label={translate('pages.conditions.labels.subtitle')}
            maxLength={30}
            id="subtitleEn"
            showButtons={false}
            marginTop={false}
            isValid={Boolean(state.subtitleEn)}
            validationText={translate('pages.conditions.validation.any')}
            useValidation={!isRTL}
          />
        </fieldset>

        <fieldset className={cls(styles.fieldset, 'mb-5', 'p-3')}>
          <legend className={cls(styles.legend, 'mb-0')}>
            {translate('pages.conditions.labels.hebrew')}
          </legend>
          <EditableTextarea
            value={state.titleHe}
            onInputChange={setStateByFieldName('titleHe')}
            label={translate('pages.conditions.labels.title')}
            maxLength={15}
            id="titleHe"
            showButtons={false}
            marginTop={false}
            dir="rtl"
            isValid={Boolean(state.titleHe)}
            validationText={translate('pages.conditions.validation.any')}
            useValidation={isRTL}
          />

          <EditableTextarea
            value={state.subtitleHe}
            onInputChange={setStateByFieldName('subtitleHe')}
            label={translate('pages.conditions.labels.subtitle')}
            maxLength={30}
            id="subtitleHe"
            showButtons={false}
            marginTop={false}
            dir="rtl"
            isValid={Boolean(state.subtitleHe)}
            validationText={translate('pages.conditions.validation.any')}
            useValidation={isRTL}
          />
        </fieldset>

        <EditableTextarea
          value={state.priceCoefficient}
          onInputChange={setStateByFieldName('priceCoefficient')}
          label={translate('pages.conditions.labels.priceCoefficient')}
          type={inputTypes.number}
          id="priceCoefficient"
          showButtons={false}
          isValid={!!state.priceCoefficient}
          validationText={translate('pages.conditions.validation.any')}
          useValidation
          {...numberInputProps}
        />
      </Modal.Body>
      <Modal.Footer
        onSave={onSaveClick}
        onCancel={closeModal}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </>
  );
};

ConditionsModal.propTypes = {
  options: PropTypes.shape({
    mode: PropTypes.string,
    id: PropTypes.number,
    titleEn: PropTypes.string,
    subtitleEn: PropTypes.string,
    titleHe: PropTypes.string,
    subtitleHe: PropTypes.string,
    priceCoefficient: PropTypes.number,
  }),
  closeModal: PropTypes.func.isRequired,
  addCondition: PropTypes.func.isRequired,
  updateCondition: PropTypes.func.isRequired,
};

ConditionsModal.defaultProps = {
  options: {},
};

export default connect(null, {
  addCondition: actions.addCondition,
  updateCondition: actions.updateCondition,
})(ConditionsModal);
