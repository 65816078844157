import { select, takeEvery, put, call, all } from 'redux-saga/effects';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/brands';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { normalize, formatCase } from '../../utils/store';

function* onSetGroupId({ payload: groupId }) {
  yield put(setLoader(true));

  try {
    const brands = yield call(api.getBrands, groupId);
    const normalizedBrands = normalize(brands, 'brandIds', 'brandEntities');

    yield put(actions.setBrands(normalizedBrands));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onAddBrand({ payload: { name } }) {
  yield put(setLoader(true));

  try {
    const groupId = yield select(selectors.getGroupId);
    const newBrand = yield call(api.addBrand, formatCase.toSnakeCase({ name, groupId }));

    yield put(actions.addBrandSuccess(formatCase.toCamelCase(newBrand)));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onUpdateBrand({ payload }) {
  const groupId = yield select(selectors.getGroupId);
  yield call(api.updateBrand, formatCase.toSnakeCase({ ...payload, groupId }));
  yield put(actions.updateBrandSuccess(payload));
}

function* onToggleBrandActive({ payload }) {
  const { id, isActive } = payload;

  const groupId = yield select(selectors.getGroupId);
  const toggleFunction = isActive
    ? api.activateBrand
    : api.deactivateBrand;

  yield call(toggleFunction, id, formatCase.toSnakeCase({ groupId }));
  yield put(actions.updateBrandSuccess(payload));
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.SET_GROUP_ID, onSetGroupId),
    takeEvery(AT.ADD_BRAND, onAddBrand),
    takeEvery(AT.UPDATE_BRAND, onUpdateBrand),
    takeEvery(AT.TOGGLE_BRAND_ACTIVE, onToggleBrandActive),
  ]);
}
