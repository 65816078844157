const prefix = 'cities/';

export const GET_CITIES =`${prefix}GET_CITIES`;
export const GET_CITIES_SUCCESS = `${prefix}GET_CITIES_SUCCESS`;
export const REMOVE_CITY = `${prefix}REMOVE_CITY`;
export const REMOVE_CITY_SUCCESS = `${prefix}REMOVE_CITY_SUCCESS`;
export const EDIT_CITY_IMAGE = `${prefix}EDIT_CITY_IMAGE`;
export const EDIT_CITY_IMAGE_SUCCESS = `${prefix}EDIT_CITY_IMAGE_SUCCESS`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;
