import { createSelector } from 'reselect';
import { rulesKinds } from '../../constants';
import { sortByProp } from '../../utils/store';

export const getRulesEntities = (state) => {
  return state.rules.entities;
};

export const getSelectedRulesKind = (state) => {
  return state.rules.selectedKind;
};

export const getCommunityRulesIds = (state) => {
  return state.rules.communityRulesIds;
};

export const getItemRulesIds = (state) => {
  return state.rules.itemRulesIds;
};

export const getWishRulesIds = (state) => {
  return state.rules.wishRulesIds;
};

export const rulesIdsSelectorsMappedByKind = {
  [rulesKinds.community]: getCommunityRulesIds,
  [rulesKinds.item]: getItemRulesIds,
  [rulesKinds.wish]: getWishRulesIds,
};

export const getRulesIdsBySelectedKind = (state) => {
  const selectedRulesKind = getSelectedRulesKind(state);
  return rulesIdsSelectorsMappedByKind[selectedRulesKind](state);
};

export const getRulesByKind = createSelector(
  [getRulesEntities, getRulesIdsBySelectedKind],
  (entities, ids) => {
    const rules = ids.map((id) => {
      return entities[id];
    });

    return sortByProp('order')(rules);
  },
);
