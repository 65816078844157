import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { dateFormatter, getIsDateRangeUpdated, getIsSameDate } from '../../../utils/moment';
import DatePicker from '../../../components/Datepicker';
import Button, { buttonProps } from '../../../components/Button';
import styles from './DateRange.module.scss';

const DateRange = ({
  initialDateRange,
  getCommunityAnalytics,
}) => {
  const translate = useTranslate();

  const [startDate, setStartDate] =
    useState(dateFormatter.stringToDate(initialDateRange.startDate));
  const [endDate, setEndDate] =
    useState(dateFormatter.stringToDate(initialDateRange.endDate));

  const isDateRangeUpdated = getIsDateRangeUpdated(initialDateRange, { startDate, endDate });
  const isSameDate = getIsSameDate(startDate, endDate);
  const isUpdateButtonDisabled = isSameDate || !isDateRangeUpdated;

  const updateDatesOnStartDateChange = (newDate) => {
    // if newDate timestamp is bigger then the endDate one, the endDate will be invalid
    const isEndDateBecomesInvalid = newDate.getTime() > endDate.getTime();

    if (isEndDateBecomesInvalid) {
      setEndDate(newDate);
    }

    setStartDate(newDate);
  };

  const onUpdate = () => {
    const dateRangeToSend = {
      startDate: dateFormatter.dateToString(startDate),
      endDate: dateFormatter.dateToString(endDate),
    };

    getCommunityAnalytics(dateRangeToSend);
  };

  return (
    <div className={cls('d-flex', styles.dateRangeWrapper)}>
      <DatePicker
        value={startDate}
        onChange={updateDatesOnStartDateChange}
        id="startDate"
        label={translate('pages.dashboard.modal.labels.startDate')}
      />
      <DatePicker
        value={endDate}
        onChange={setEndDate}
        minDate={startDate}
        id="endDate"
        label={translate('pages.dashboard.modal.labels.endDate')}
      />

      <Button
        onClick={onUpdate}
        disabled={isUpdateButtonDisabled}
        theme={
          isUpdateButtonDisabled
            ? buttonProps.themes.gray
            : buttonProps.themes.primary
        }
        size={buttonProps.sizes.null}
        weight={buttonProps.weights.bold}
        marginTop={false}
      >
        {translate('pages.dashboard.buttons.updateDateRange')}
      </Button>
    </div>
  );
};

DateRange.propTypes = {
  initialDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  getCommunityAnalytics: PropTypes.func.isRequired,
};

export default DateRange;
