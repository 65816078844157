import api from './Api';

const getReports = ({ type, communityId, offset, limit, searchTerm }) => {
  const query = api.getQueryParamsFromObject({ skip: offset, limit });

  return api.post(`/reports?${query}`, {
    search_term: searchTerm,
    report_type: type,
    community_id: communityId,
  });
};

const getReporters = (communityId) => {
  return api.get(`/reporters/${communityId}`);
};

const getReportedUsers = (communityId) => {
  return api.get(`/reportedUsers/${communityId}`);
};

const getTopReports = (communityId) => {
  return api.get(`/topReports/${communityId}`);
};

const getDailyReport = () => {
  return api.get('admin/daily-report');
};

export default {
  getReports,
  getReporters,
  getReportedUsers,
  getTopReports,
  getDailyReport,
};
