import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import Modal from '../../components';
import {
  updateGroupsOrder as updateGroupsOrderAction,
} from '../../../store/community/actions';
import { getGroupsForSelect } from '../../../store/community/selectors';
import SortableGroupsGrid from './SortableGroupsGrid/SortableGroupsGrid';

const GroupInfoModal = ({
  closeModal,
  groups,
  updateGroupsOrder,
}) => {
  const translate = useTranslate();
  const [list, setList] = useState(groups);

  const onSaveClick = async () => {
    const group_ids = list.map(item => item.value);
    await updateGroupsOrder({ group_ids });
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {translate('pages.groupInfo.buttons.changeOrder')}
      </Modal.Header>
      <Modal.Body>
        <SortableGroupsGrid
          items={groups}
          list={list}
          setList={setList}
        />
      </Modal.Body>
      <Modal.Footer
        onSave={onSaveClick}
        onCancel={closeModal}
      />
    </>
  );
};

GroupInfoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.shape({
        en: PropTypes.string,
        he: PropTypes.string,
      }).isRequired,
      description: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  updateGroupsOrder: PropTypes.func.isRequired,
};

export default connect((state) => ({
  groups: getGroupsForSelect(state),
}), {
  updateGroupsOrder: updateGroupsOrderAction,
})(GroupInfoModal);
