import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { isNil } from 'ramda';
import ModalService from './ModalService';
import modalsMap from './wrappers/modalsMap';

const initialState = {
  id: null,
  options: {},
};

export default class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentWillUnmount() {
    ModalService.unmount();
  }

  openModal = (id, options) => {
    this.setState({ id, options });
  };

  closeModal = () => {
    this.setState(initialState);
  };

  render() {
    const { id, options } = this.state;
    const isOpen = !isNil(id);

    const ModalContentComponent = modalsMap[id];

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.closeModal}
        autoFocus
        backdrop
        centered
      >
        {isOpen && (
          <ModalContentComponent
            options={options}
            closeModal={this.closeModal}
          />
        )}
      </Modal>
    );
  }
}
