import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import cls from 'classnames';
import { ReactSortable as Sortable } from 'react-sortablejs';
import { getChildCategoriesById } from '../../../store/categories/selectors';
import Icon, { iconTypes, iconSets } from '../../../components/Icon';
import Button, { buttonProps } from '../../../components/Button';
import ButtonWithPopover from '../../../components/Button/ButtonWithPopover';
import TextLabel, { textLabelColors } from '../../../components/TextLabel';
import SortableGridNestedItem from './SortableGridNestedItem';
import { modalModes, itemTypes } from '../../../modals/const';
import { getIsListsEqual } from '../../../utils/store';
import styles from './SortableGridItem.module.scss';

const SortableGridItem = memo(({
  id,
  name,
  iconName,
  setIsCategoryActive,
  reorderCategory,
  openModal,
  isActive,
}) => {
  const translate = useTranslate();

  const childItems = useSelector((state) => {
    return getChildCategoriesById(state, id);
  });

  const [list, setList] = useState(childItems);
  const buttonId = `button-${id}`;
  const isDeactivated = !isActive;

  const onToggleActiveParentClick = () => {
    setIsCategoryActive({ id, isActive: !isActive });
  };

  const onAddChildClick = () => {
    const options = {
      mode: modalModes.ADD,
      itemType: itemTypes.CHILD,
      parentId: id,
    };
    openModal(options);
  };

  const onEditParentClick = () => {
    const options = {
      mode: modalModes.EDIT,
      itemType: itemTypes.PARENT,
      id,
      name,
      iconName,
    };

    openModal(options);
  };

  const setListWithOrder = (newList) => {
    const listWithUpdatedOrder = newList.map((item, index) => {
      return { ...item, order: index };
    });

    setList(listWithUpdatedOrder);
  };

  const onSortEnd = ({ item, newIndex: order }) => {
    const childId = item.getAttribute('data-id');

    reorderCategory({
      id: parseInt(childId, 10),
      parentId: id,
      order,
    });
  };

  useEffect(() => {
    const isListsEqual = getIsListsEqual(list, childItems, ['order', 'chosen']);

    if (!isListsEqual) {
      setListWithOrder(childItems);
    }
  }, [childItems, list]);

  return (
    <div
      className={cls('list-group-item', 'pb-4', styles.sortableGridItemWrapper, {
        [styles.deactivatedItem]: isDeactivated,
      })}
      data-id={id}
    >
      <div className={cls(styles.sortableGridItemContent, 'mb-3')}>
        <div className={styles.sortableGridItemInfo}>
          <h4 className={styles.itemInfoTitle}>
            <Icon
              type={iconTypes[iconName]}
              set={iconSets.main}
              width={25}
              height={25}
              className={cls('mr-3', 'p-2', styles.itemIcon)}
            />
            {name}
            {isDeactivated && (
              <TextLabel
                className={styles.deactivated}
                color={textLabelColors.danger}
              >
                {translate('pages.categories.labels.deactivated')}
              </TextLabel>
            )}
          </h4>
        </div>
        <div className={styles.sortableGridItemButtons}>
          <ButtonWithPopover
            id={buttonId}
            title={
              <i className={cls('fa', 'fa-ellipsis-v', styles.moreIcon)} />
            }
          >
            {isActive && (
              <>
                <Button
                  size={buttonProps.sizes.null}
                  theme={buttonProps.themes.link}
                  onClick={onAddChildClick}
                  marginTop={false}
                >
                  {translate('pages.categories.buttons.add')}
                </Button>
                <Button
                  size={buttonProps.sizes.null}
                  theme={buttonProps.themes.link}
                  onClick={onEditParentClick}
                  marginTop={false}
                >
                  {translate('pages.categories.buttons.edit')}
                </Button>
              </>
            )}
            <Button
              size={buttonProps.sizes.null}
              theme={buttonProps.themes.link}
              onClick={onToggleActiveParentClick}
              marginTop={false}
            >
              {isActive
                ? translate('pages.categories.buttons.deactivate')
                : translate('pages.categories.buttons.activate')
              }
            </Button>
          </ButtonWithPopover>
        </div>
      </div>
      <Sortable
        list={list}
        setList={setListWithOrder}
        className={cls('list-group-sortable', 'mt-xs')}
        direction="vertical"
        group="nested"
        forceFallback
        onSort={onSortEnd}
      >
        {list.map((child) => {
          return (
            <SortableGridNestedItem
              key={child.id}
              id={child.id}
              parentId={id}
              name={child.name}
              isActive={child.isActive}
              setIsCategoryActive={setIsCategoryActive}
              openModal={openModal}
            />
          );
        })}
      </Sortable>
    </div>
  );
});

SortableGridItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  isActive: PropTypes.bool.isRequired,

  setIsCategoryActive: PropTypes.func.isRequired,
  reorderCategory: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

SortableGridItem.defaultProps = {
  iconName: null,
};

export default SortableGridItem;
