import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate, useLogout } from 'react-admin';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styles from './UserMenu.module.scss';

const UserMenu = ({
  userInfo,
}) => {
  const translate = useTranslate();
  const logout = useLogout();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggleDropDown}
      id="user-dropdown"
      tag="div"
      nav
    >
      <DropdownToggle nav>
        <span className={cls(styles.avatar, 'rounded-circle', 'mr-2')}>
          <img
            src={userInfo.profileImage}
            alt={userInfo.name}
          />
        </span>
        <span className={cls('d-none', 'd-sm-block', 'font-weight-bold')}>
          {userInfo.name}
        </span>
      </DropdownToggle>
      <DropdownMenu right className={cls('py-0', 'animated', 'animated-fast', 'fadeInUp')}>
        <DropdownItem onClick={logout}>
          <i className="la la-sign-out" />
          {translate('general.label.logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

UserMenu.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    profileImage: PropTypes.string,
  }).isRequired,
};

export default UserMenu;
