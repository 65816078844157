import React from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import BarChartComponent from '../BarChart/BarChart';
import styles from './SummaryStatistics.module.scss';
import { getSummaryStatistics } from '../../../../../store/reports/selectors';

const SummaryStatistics = ({ summaryStatistics }) => {
  const translate = useTranslate();
  const itemsCountData = [
    {
      name: translate('pages.reports.uploaded'),
      count: summaryStatistics?.uploaded?.count,
      value: summaryStatistics?.uploaded?.value,
    },
    {
      name: translate('pages.reports.exchanged'),
      count: summaryStatistics?.exchanged?.count,
      value: summaryStatistics?.exchanged?.value,
    },
    {
      name: translate('pages.reports.shipped'),
      count: summaryStatistics?.shipped?.count,
      value: summaryStatistics?.shipped?.value,
    },
    {
      name: translate('pages.reports.refunded'),
      count: summaryStatistics?.refunded?.count,
      value: summaryStatistics?.refunded?.value,
    },
  ];

  const pointsData = [
    { name: translate('pages.reports.active_tokens'), value: summaryStatistics?.activetokens?.value },
    { name: translate('pages.reports.available_in_market'), value: summaryStatistics?.availableinmarket?.value },
  ];
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{translate('pages.reports.summary_statistics')}</h1>

      <div className={styles.containerStatistics}>
        <div className={styles.containerStatisticsItem}>
          <p className={styles.statisticsItemTitle}>{translate('pages.reports.members')}</p>
          <p className={styles.statisticsItemValue}>{summaryStatistics?.members?.count || 0}</p>
        </div>

        <div className={styles.containerStatisticsItem}>
          <p className={styles.statisticsItemTitle}>{translate('pages.reports.givers')}</p>
          <p className={styles.statisticsItemValue}>{summaryStatistics?.givers?.count || 0}</p>
        </div>

        <div className={styles.containerStatisticsItem}>
          <p className={styles.statisticsItemTitle}>{translate('pages.reports.receivers')}</p>
          <p className={styles.statisticsItemValue}>{summaryStatistics?.receivers?.count || 0}</p>
        </div>

        <div className={styles.containerStatisticsItem}>
          <p className={styles.statisticsItemTitle}>{translate('pages.reports.verified_members')}</p>
          <p className={styles.statisticsItemValue}>
            {summaryStatistics?.verifiedmembers?.count || 0}
          </p>
        </div>
      </div>

      <div className={styles.containerBarChart}>
        <div className={styles.containerBarChartColumn}>
          <BarChartComponent data={itemsCountData} label="Items (Count)" dataKey="count" />
          <div className={styles.containerStatisticsChart}>
            <div className={styles.containerStatisticsItem}>
              <p className={styles.statisticsItemTitle}>{translate('pages.reports.uploaded_value')}</p>
              <p className={styles.statisticsItemValue}>
                {summaryStatistics?.uploaded?.count || 0}
              </p>
            </div>

            <div className={styles.containerStatisticsItem}>
              <p className={styles.statisticsItemTitle}>{translate('pages.reports.exchanged_value')}</p>
              <p className={styles.statisticsItemValue}>
                {summaryStatistics?.exchanged?.count || 0}
              </p>
            </div>

            <div className={styles.containerStatisticsItem}>
              <p className={styles.statisticsItemTitle}>{translate('pages.reports.shipped_value')}</p>
              <p className={styles.statisticsItemValue}>{summaryStatistics?.shipped?.count || 0}</p>
            </div>

            <div className={styles.containerStatisticsItem}>
              <p className={styles.statisticsItemTitle}>{translate('pages.reports.refunded_value')}</p>
              <p className={styles.statisticsItemValue}>
                {summaryStatistics?.refunded?.count || 0}
              </p>
            </div>
          </div>
        </div>

        <div style={{ minWidth: '50%' }}>
          <div className={styles.containerBarChartColumn}>
            <BarChartComponent data={pointsData} label="Points Economy (Value)" dataKey="value" />

            <div className={styles.containerStatisticsChart}>
              <div className={styles.containerStatisticsItem}>
                <p className={styles.statisticsItemTitle}>{translate('pages.reports.items_in_market')}</p>
                <p className={styles.statisticsItemValue}>
                  {summaryStatistics?.availableinmarket?.value || 0}
                </p>
              </div>

              <div className={styles.containerStatisticsItem}>
                <p className={styles.statisticsItemTitle}>{translate('pages.reports.issued_points')}</p>
                <p className={styles.statisticsItemValue}>
                  {summaryStatistics?.activetokens?.value || 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    summaryStatistics: getSummaryStatistics(state),
  };
})(SummaryStatistics);
