import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './DailyReport.module.scss';

import SummaryStatistics from './Charts/SummaryStatistics/SummaryStatistics';
import ActiveUsers from './Charts/ActiveUsers/ActiveUsers';
import NewRegisterUsers from './Charts/NewRegisterUsers/NewRegisterUsers';
import UniqueParticipants from './Charts/UniqueParticipants/UniqueParticipants';

import { dailyReportStart } from '../../../store/reports/actions';
import Loader from '../../../components/Loader';
import WeeklyBoard from './Charts/WeeklyBoard/WeeklyBoard';
import TotalItemsUploaded from './Charts/TotalItemsUploaded/TotalItemsUploaded';

const DailyReport = ({ getDailyReport, dailyReportLoading }) => {
  useEffect(() => {
    getDailyReport();
  }, []);

  return (
    <div className={styles.container}>
      {dailyReportLoading ? (
        <Loader />
      ) : (
        <>
          <SummaryStatistics />
          <ActiveUsers />
          <NewRegisterUsers />
          <UniqueParticipants />
          <WeeklyBoard />
          <TotalItemsUploaded />
        </>
      )}
    </div>
  );
};

export default connect((state) => {
  return {
    dailyReportLoading: state.reports.dailyReportLoading,
  }
}, {
  getDailyReport: dailyReportStart,
})(DailyReport);
