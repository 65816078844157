import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { getReporters } from '../../../store/reports/selectors';
import Widget from '../../../components/Widget';
import Table from '../../../components/Table';
import styles from './ReportsContent.module.scss';

const ReportersTable = ({ items }) => {
  const translate = useTranslate();

  const idFormatter = (value) => {
    const [id] = value.split('-');
    return id;
  };

  const reporterNameFormatter = (value, cell) => {
    return (
      <div>
        <img src={cell.reporterImage} className={styles.avatar} alt={value} />
        <span>{value}</span>
      </div>
    );
  };

  const paginationProps = {
    sizePerPage: 10,
  };

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.reports.table.reporters.columns.id'),
      formatter: idFormatter,
    }, {
      dataField: 'reporterName',
      text: translate('pages.reports.table.reporters.columns.reporterName'),
      formatter: reporterNameFormatter,
      align: 'start',
      headerAlign: 'start',
    }, {
      dataField: 'value',
      text: translate('pages.reports.table.reporters.columns.value'),
    },
  ];

  return (
    <Table
      data={items}
      columns={columns}
      paginationProps={paginationProps}
    />
  );
};

const Reporters = ({ reporters }) => {
  const translate = useTranslate();

  return (
    <div className={cls('d-grid', styles.gridWrapper)}>
      <Widget
        title={translate('pages.reports.labels.table.reporters.item')}
        className={cls(styles.tableWidget, 'shadow-none')}
      >
        <ReportersTable items={reporters.item} />
      </Widget>

      <Widget
        title={translate('pages.reports.labels.table.reporters.user')}
        className={cls(styles.tableWidget, 'shadow-none')}
      >
        <ReportersTable items={reporters.user} />
      </Widget>
    </div>
  );
};

ReportersTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      reporterName: PropTypes.string,
      reporterImage: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
};

Reporters.propTypes = {
  reporters: PropTypes.shape({
    user: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        reporterName: PropTypes.string,
        reporterImage: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ),
    item: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        reporterName: PropTypes.string,
        reporterImage: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ),
  }).isRequired,
};

export default connect((state) => {
  return {
    reporters: getReporters(state),
  };
})(Reporters);
