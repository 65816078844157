import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import * as actions from '../../store/community/actions';
import * as selectors from '../../store/community/selectors';
import { LOCALE_TYPES } from '../../constants';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import PageNavigation from '../../components/PageNavigation';
import GroupSelect from '../../components/GroupSelect';
import GroupNameField from './Fields/GroupName';
import GroupDescriptionField from './Fields/GroupDescription';
import GroupBannerField from './Fields/GroupBanner';
import styles from './GroupInfo.module.scss';
import Button, { buttonProps } from '../../components/Button';
import ModalService from '../../modals/ModalService';
import { modalIds } from '../../modals/const';

const GroupInfo = ({ selectedGroup, setGroupId, updateGroupInfo }) => {
  const translate = useTranslate();

  const onGroupChange = ({ value: id }) => {
    setGroupId(id);
  };

  const onOpenGroupChangeOrderModal = () => {
    ModalService.open(modalIds.GROUP_INFO_MODAL);
  };

  const onOpenCreateModal = () => {
    ModalService.open(modalIds.GROUP_MODAL);
  };

  return (
    <PageContent title={translate('pages.groupInfo.title.page')}>
      <Widget className={styles.groupInfoWidget}>
        <PageNavigation className="mb-5">
          <GroupSelect onChange={onGroupChange} className="flex-grow-1" />

          <Button
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            onClick={onOpenGroupChangeOrderModal}
            marginTop={false}
          >
            {translate('pages.groupInfo.buttons.changeOrder')}
          </Button>
        </PageNavigation>

        {selectedGroup && (
          <div className={cls('d-grid', 'mb-5', styles.groupInfoGrid)}>
            <GroupBannerField
              initialValue={selectedGroup.backgroundImage}
              onUpdate={updateGroupInfo}
            />

            {/* {Object.values(LOCALE_TYPES).map((language) => {
              return (
                <GroupNameField
                  id={`groupName${LOCALE_TYPES_SUFFIX[language]}`}
                  initialValue={selectedGroup.groupName[language]}
                  groupNameMap={selectedGroup.groupName}
                  localeType={LOCALE_TYPES[language.toUpperCase()]}
                  onUpdate={updateGroupInfo}
                />
              );
            })} */}

            <GroupNameField
              id="groupNameEn"
              initialValue={selectedGroup.groupName.en}
              groupNameMap={selectedGroup.groupName}
              localeType={LOCALE_TYPES.EN}
              onUpdate={updateGroupInfo}
            />

            <GroupNameField
              id="groupNameHe"
              initialValue={selectedGroup.groupName.he}
              groupNameMap={selectedGroup.groupName}
              localeType={LOCALE_TYPES.HE}
              onUpdate={updateGroupInfo}
            />

            <GroupDescriptionField
              id="groupDescription"
              initialValue={selectedGroup.about}
              onUpdate={updateGroupInfo}
            />
          </div>
        )}
      </Widget>
    </PageContent>
  );
};

GroupInfo.propTypes = {
  selectedGroup: PropTypes.shape({
    groupName: PropTypes.shape({
      en: PropTypes.string,
      he: PropTypes.string,
    }).isRequired,
    about: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string,
  }),
  setGroupId: PropTypes.func.isRequired,
  updateGroupInfo: PropTypes.func.isRequired,
};

GroupInfo.defaultProps = {
  selectedGroup: null,
};

export default connect(
  (state) => {
    return {
      selectedGroup: selectors.getSelectedGroup(state),
    };
  },
  {
    setGroupId: actions.setGroupId,
    updateGroupInfo: actions.updateGroupInfo,
  },
)(GroupInfo);
