import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import ImageUploader from '../../../components/ImageUploader';

const WALLET_IMAGE_INPUT_ID = 'WALLET_IMAGE_INPUT';

const WalletImageField = ({
  initialValue,
  updateCommunityInfo,
}) => {
  const translate = useTranslate();

  const onImageUpload = (file) => {
    const data = new FormData();
    data.append('wallet_image', file);

    updateCommunityInfo(data);
  };

  return (
    <ImageUploader
      label={translate('pages.communityInfo.labels.walletImage')}
      defaultValue={initialValue}
      id={WALLET_IMAGE_INPUT_ID}
      onChange={onImageUpload}
    />
  );
};

WalletImageField.propTypes = {
  initialValue: PropTypes.string,
  updateCommunityInfo: PropTypes.func.isRequired,
};

WalletImageField.defaultProps = {
  initialValue: null,
};

export default WalletImageField;
