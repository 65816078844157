import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';

import api from '../../api/community';
import * as AT from './types';
import * as actions from './actions';

import { getSelectedCommunityId, getSelectedGroupId } from './selectors';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { normalize, formatCase } from '../../utils/store';

function* onSetCommunityId() {
  yield put(actions.getCommunityAnalytics());
  yield put(actions.getCommunityGroups());
}

function* onGetCommunities() {
  yield put(setLoader(true));

  try {
    const communities = yield call(api.getAdminCommunities);

    const normalizedCommunities = normalize(communities, 'communityIds', 'communityEntities');

    yield put(actions.getCommunitiesSuccess(normalizedCommunities));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onGetCommunityGroups() {
  yield put(setLoader(true));

  try {
    const communityId = yield select(getSelectedCommunityId);

    const groups = yield call(api.getCommunityGroups, communityId);
    const normalizedGroups = normalize(groups, 'groupIds', 'groupEntities');

    yield put(actions.getCommunityGroupsSuccess(normalizedGroups));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onGetCommunityAnalytics({ payload: dateRange }) {
  try {
    const communityId = yield select(getSelectedCommunityId);
    const analytics = yield call(
      api.getCommunityAnalytics,
      formatCase.toSnakeCase({ ...dateRange, communityId }),
    );

    yield put(actions.getCommunityAnalyticsSuccess(formatCase.toCamelCase(analytics)));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onUpdateCommunityInfo({ payload: data }) {
  const communityId = yield select(getSelectedCommunityId);
  data.append('community_id', communityId);

  const updatedCommunityInfo = yield call(api.updateCommunityInfo, data);

  yield put(actions.updateCommunityInfoSuccess(formatCase.toCamelCase(updatedCommunityInfo)));
  yield put(showNotification('message.success.action.community.info.save'));
}

export function* onUpdateGroupInfo({ payload: data }) {
  const groupId = yield select(getSelectedGroupId);
  data.append('group_id', groupId);

  const updatedGroupInfo = yield call(api.updateGroupInfo, data);
  yield put(actions.updateGroupInfoSuccess(formatCase.toCamelCase(updatedGroupInfo)));
  yield put(showNotification('message.success.action.community.info.save'));
}

function* onUpdateGroupsOrder({ payload: group_ids }) {
  try {
    yield call(api.updateGroupsOrder, group_ids);
    yield put(actions.getCommunityGroups());
    yield put(showNotification('message.success.action.community.info.save'));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.SET_COMMUNITY_ID, onSetCommunityId),
    takeEvery(AT.GET_COMMUNITIES, onGetCommunities),
    takeEvery(AT.GET_COMMUNITY_GROUPS, onGetCommunityGroups),
    takeEvery(AT.GET_COMMUNITY_ANALYTICS, onGetCommunityAnalytics),
    takeEvery(AT.UPDATE_COMMUNITY_INFO, onUpdateCommunityInfo),
    takeEvery(AT.UPDATE_GROUPS_ORDER, onUpdateGroupsOrder),
    takeEvery(AT.UPDATE_GROUP_INFO, onUpdateGroupInfo),
  ]);
}
