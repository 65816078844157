import React from 'react';
import PropTypes from 'prop-types';
import { REPORT_TABS } from '../../../constants/reports';
import TabsContent from '../../../components/TabsAccordion/TabsContent';
import UserReportsTable from './UserReportsTable';
import ItemReportsTable from './ItemReportsTable';
import Reporters from './Reporters';
import ReportedUsers from './ReportedUsers';
import TopReports from './TopReports';
import DailyReport from './DailyReport';

const reportContentMap = {
  [REPORT_TABS.USER]: UserReportsTable,
  [REPORT_TABS.ITEM]: ItemReportsTable,
  [REPORT_TABS.REPORTERS]: Reporters,
  [REPORT_TABS.REPORTED_USERS]: ReportedUsers,
  [REPORT_TABS.TOP_REPORTS]: TopReports,
  [REPORT_TABS.DAILY_REPORTS]: DailyReport,
};

const ReportsContent = ({ activeTab }) => {
  const ContentComponent = reportContentMap[activeTab];

  return (
    <TabsContent>
      <ContentComponent type={activeTab} />
    </TabsContent>
  );
};

ReportsContent.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default ReportsContent;
