import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as subcommunitiesSelectors from '../../../store/subcommunities/selectors';
import PageContent from '../../../components/PageContent';
import Widget from '../../../components/Widget';
import styles from './Subcommunities.module.scss';
import { getSelectedSubcommunityId } from '../../../store/subcommunities/selectors';
import SearchInput from '../../../components/Search';
import PlusIcon from '../../../assets/icons/plus-circle.svg';
import { getSubcommunities } from '../../../store/subcommunities/actions';
import SubcommunityTable from './components/SubcommunityTable';
import { useHistory } from 'react-router-dom';

const Subcommunities = ({ selectedSubcommunityId }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const subcommunities = useSelector(subcommunitiesSelectors.getSubcommunities);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubcommunities, setFilteredSubcommunities] = useState([]);

  const handleCreateNew = () => {
    history.push('/create-space');
  };

  const handleEditSpace = (subcommunityId) => {
    history.push(`/edit-space/${subcommunityId}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e);
  };

  useEffect(() => {
    dispatch(getSubcommunities());
  }, []);

  useEffect(() => {
    const results = subcommunities.filter((subcommunity) =>
      subcommunity.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubcommunities(results);
  }, [searchTerm, subcommunities]);

  return (
    <PageContent title={translate('pages.subcommunities.title.page')}>
      <Widget className={styles.groupInfoWidget}>
        <div className='flex flex-row items-center justify-between'>
          <p className='text-black text-[24px] font-semibold'>Your Spaces</p>

          <div className='flex flex-row items-center justify-center gap-4'>
            <SearchInput
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={'Search Space'}
              className={styles.searchPanel}
            />

            <button
              className='bg-bluePrimary border-none text-white w-[300px] px-4 py-2 h-[51px] rounded-lg text-[18px] font-semibold flex flex-row items-center justify-center gap-2'
              onClick={handleCreateNew}
            >
              <img src={PlusIcon} alt='plus' />
              Create New
            </button>
          </div>
        </div>

        <SubcommunityTable
          subcommunities={filteredSubcommunities}
          handleEditSpace={handleEditSpace}
        />
      </Widget>
    </PageContent>
  );
};

Subcommunities.propTypes = {
  selectedGroup: PropTypes.shape({
    groupName: PropTypes.shape({
      en: PropTypes.string,
      he: PropTypes.string,
    }).isRequired,
    about: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string,
  }),
};

Subcommunities.defaultProps = {
  selectedGroup: null,
};

export default connect((state) => {
  return {
    selectedSubcommunityId: getSelectedSubcommunityId(state),
  };
}, {})(Subcommunities);
