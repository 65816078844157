import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './PageNavigation.module.scss';

const PageNavigation = ({ children, className }) => {
  return (
    <div
      className={cls(className, styles.pageNavigation)}
    >
      {children}
    </div>
  );
};

PageNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};

PageNavigation.defaultProps = {
  className: null,
};

export default PageNavigation;
