import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { useTranslate, useLocale } from 'react-admin';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Table from '../../../components/Table';
import { reportsStart } from '../../../store/reports/actions';
import {
  REPORTS_LIMIT,
  REPORT_REASONS_MAP_BY_ID,
  REPORTS_REQUEST_DELAY,
  REPORT_TABS,
  initialSearchTerm,
} from '../../../constants/reports';
import { formatDateWithLocale } from '../../../utils/dateFns';
import { getReportsByType } from '../../../store/reports/selectors';

const ItemReportsTable = ({
  items,
  type,
  getItems,
}) => {
  const translate = useTranslate();
  const locale = useLocale();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const onPageChange = (requestedPage) => {
    getItems({ type, searchTerm, isLoadMore: true, nextPage: requestedPage + 1 });
  };

  const onFilterChange = (_, term) => {
    const updatedSearchTerm = Object.keys(term).reduce((acc, key) => {
      return {
        ...acc,
        [key]: pathOr(null, ['filterVal'], term[key]),
      };
    }, {});

    setSearchTerm(updatedSearchTerm);
    getItems({ type, searchTerm: updatedSearchTerm, isLoadMore: false });
  };

  const filterOptions = {
    delay: REPORTS_REQUEST_DELAY,
    placeholder: translate('pages.reports.labels.filter'),
  };

  const dateFormatter = (value) => {
    return formatDateWithLocale(value, locale);
  };

  const reasonFormatter = (value) => {
    return REPORT_REASONS_MAP_BY_ID[value] || value;
  };

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.reports.table.reports.columns.id'),
      hidden: true,
    }, {
      dataField: 'createdAt',
      text: translate('pages.reports.table.reports.columns.createdAt'),
      formatter: dateFormatter,
    }, {
      dataField: 'reportedUserName',
      text: translate('pages.reports.table.reports.columns.reportedUserName'),
      filter: textFilter(filterOptions),
    }, {
      dataField: 'reporterUserName',
      text: translate('pages.reports.table.reports.columns.reporterUserName'),
      filter: textFilter(filterOptions),
    }, {
      dataField: 'itemTitle',
      text: translate('pages.reports.table.reports.columns.itemTitle'),
      filter: textFilter(filterOptions),
    }, {
      dataField: 'reasonId',
      text: translate('pages.reports.table.reports.columns.reasonId'),
      formatter: reasonFormatter,
    },
  ];

  const paginationProps = {
    onPageChange,
    sizePerPage: REPORTS_LIMIT,
  };

  const filter = filterFactory({
    afterFilter: onFilterChange,
  });

  return (
    <Table
      data={items}
      columns={columns}
      paginationProps={paginationProps}
      filter={filter}
    />
  );
};

ItemReportsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      createdAt: PropTypes.string,
      reportedUserName: PropTypes.string,
      reporterUserName: PropTypes.string,
      itemTitle: PropTypes.string,
      reportReason: PropTypes.number,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  getItems: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      items: getReportsByType(state, REPORT_TABS.ITEM),
    };
  }, {
    getItems: reportsStart,
  },
)(ItemReportsTable);
