import api from './Api';

const getCommunityItems = ({ skip, limit, communityId, searchTerm }) => {
  const query = api.getQueryParamsFromObject({ skip, limit, search_term: searchTerm });

  return api.get(`/admin/items/${communityId}?${query}&item_types_ids=[1]`);
};

const hideItem = (itemId) => {
  return api.post('/admin/items/hideItem', { id: itemId });
};

export default {
  getCommunityItems,
  hideItem,
};
