// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.ItemsTable_infoWrapper__3lQrv {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.ItemsTable_infoText__FOozD {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 0.5rem;\n}\n\n.ItemsTable_avatar__10Ezn {\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  object-fit: cover;\n  margin-right: 14px;\n}\n\n.ItemsTable_itemImage__2WXuf {\n  width: 75px;\n  height: 75px;\n  border-radius: 2px;\n  object-fit: contain;\n  margin-right: 14px;\n}\n\n.ItemsTable_multipleSupplyIcon__3jWWy::before {\n  font-size: 2rem;\n}\n\n.ItemsTable_searchWrapper__3FfIH {\n  display: grid;\n  grid-template: 1fr/repeat(3, 1fr);\n  grid-gap: 1rem;\n}\n\n.ItemsTable_searchPanel__1Fm-A {\n  grid-column: 3;\n}", ""]);
// Exports
exports.locals = {
	"infoWrapper": "ItemsTable_infoWrapper__3lQrv",
	"infoText": "ItemsTable_infoText__FOozD",
	"avatar": "ItemsTable_avatar__10Ezn",
	"itemImage": "ItemsTable_itemImage__2WXuf",
	"multipleSupplyIcon": "ItemsTable_multipleSupplyIcon__3jWWy",
	"searchWrapper": "ItemsTable_searchWrapper__3FfIH",
	"searchPanel": "ItemsTable_searchPanel__1Fm-A"
};
module.exports = exports;
