import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import * as AT from './types';
import * as subcommunitiesActions from './actions';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { formatCase, normalize } from '../../utils/store';
import subcommunitiesApi from '../../domains/Subcommunities/subcommunities.api';
import { getSelectedCommunityId } from '../community/selectors';
import { getSelectedSubcommunity } from './selectors';
import { showNotification } from 'ra-core';

function* onAddSubcommunity({ payload }) {
  yield put(setLoader(true));

  try {
    const { community_id, ...payloadData } = payload;

    const response = yield call(subcommunitiesApi.addSubcommunity, community_id, payloadData);

    const subCommunity = formatCase.toCamelCase(payloadData);

    yield put(subcommunitiesActions.addSubcommunitySuccess({ ...subCommunity, id: response?.id }));

    yield put(showNotification('message.success.action.subcommunities.create'));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onGetSubcommunities() {
  yield put(setLoader(true));

  try {
    const communityId = yield select(getSelectedCommunityId);

    const subcommunities = yield call(subcommunitiesApi.getSubcommunities, communityId);

    const normalizedCommunities = normalize(
      subcommunities,
      'subcommunitiesIds',
      'subcommunitiesEntities',
    );

    yield put(subcommunitiesActions.getSubcommunitiesSuccess(normalizedCommunities));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onUpdateSubcommunity({ payload: data }) {
  yield put(setLoader(true));
  try {
    const communityId = yield select(getSelectedCommunityId);
    const subcommunity = yield select(getSelectedSubcommunity);

    yield call(subcommunitiesApi.updateSubcommunity, communityId, subcommunity?.id, data);

    const updated = {
      ...subcommunity,
      ...formatCase.toCamelCase(data),
    };

    yield put(subcommunitiesActions.updateSubcommunitySuccess(updated));
    yield put(showNotification('message.success.action.subcommunities.edit'));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.ADD_SUBCOMMUNITY, onAddSubcommunity),
    takeEvery(AT.UPDATE_SUBCOMMUNITY, onUpdateSubcommunity),
    takeEvery(AT.GET_SUBCOMMUNITIES, onGetSubcommunities),
  ]);
}
