import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import BackButton from '../Button/BackButton';
import styles from './PageContent.module.scss';

const PageContent = ({ children, title, useGoBack }) => {
  return (
    <div className={styles.contentWrapper}>
      {title && (
        <div className={styles.titleWrapper}>
          {useGoBack && (
            <BackButton />
          )}
          <h1 className={cls(styles.pageTitle, styles.fwSemiBold)}>
            {title}
          </h1>
        </div>
      )}
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
  useGoBack: PropTypes.bool,
};

PageContent.defaultProps = {
  title: null,
  useGoBack: false,
};

export default PageContent;
