import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  groupId: null,
  categoryEntities: {},
  categoryIds: [],
};

const reducer = handleActions({
  [AT.SET_GROUP_ID]: deepMerge(({ payload: groupId }) => {
    return { groupId };
  }),
  [AT.SET_CATEGORIES]: deepMerge(({ payload }) => {
    return {
      categoryEntities: payload.categoryEntities,
      categoryIds: payload.categoryIds,
    };
  }),
  [AT.ADD_CATEGORY_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      categoryEntities: {
        [payload.id]: payload,
      },
      categoryIds: [...state.categoryIds, payload.id],
    };
  }),
  [AT.UPDATE_CATEGORY_SUCCESS]: deepMerge(({ payload }) => {
    return {
      categoryEntities: {
        [payload.id]: payload,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
