// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Subcommunities_createButton__3Ngvh {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.Subcommunities_groupInfoWidget__8znqA {\n  min-height: 50vh;\n}\n\n.Subcommunities_groupInfoGrid__2GNnp {\n  grid-template: auto/70%;\n  grid-gap: 4rem;\n}\n\n@media (max-width: 991.98px) {\n  .Subcommunities_groupInfoGrid__2GNnp {\n    grid-template-columns: 1fr;\n  }\n}", ""]);
// Exports
exports.locals = {
	"createButton": "Subcommunities_createButton__3Ngvh",
	"groupInfoWidget": "Subcommunities_groupInfoWidget__8znqA",
	"groupInfoGrid": "Subcommunities_groupInfoGrid__2GNnp"
};
module.exports = exports;
