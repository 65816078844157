import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import * as R from 'ramda';
import IcoMoon from 'react-icomoon';
import iconSetSidebar from '../../styles/fonts/icomoon-shareitt/icomoon-shareitt.json';
import iconSetMain from '../../styles/fonts/icons/selection.json';
import styles from './Icon.module.scss';

export const getIconTypes = (iconSet) => {
  const data = R.prop('icons', iconSet);

  return R.map(
    R.path(['properties', 'name']),
    data,
  ).reduce((acc, icon) => {
    return {
      ...acc,
      [icon]: icon,
    };
  }, {});
};

export const iconTypes = {
  ...getIconTypes(iconSetMain),
  ...getIconTypes(iconSetSidebar),
};

export const iconSets = {
  main: iconSetMain,
  sidebar: iconSetSidebar,
};

const Icon = ({
  type,
  className,
  set,
  width,
  height,
}) => {
  return (
    <IcoMoon
      iconSet={set}
      icon={type}
      className={cls(className, {
        [styles.siIcon]: set === iconSets.sidebar,
      })}
      width={width}
      height={height}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.oneOf(Object.values(iconTypes)).isRequired,
  set: PropTypes.oneOf(Object.values(iconSets)),
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  set: iconSets.main,
  className: null,
  width: 15,
  height: 15,
};

export default Icon;
