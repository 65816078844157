import { createSelector } from 'reselect';
import moment from 'moment';
import * as R from 'ramda';
import { chartColors, sortListByValue, formatChartString } from '../../utils/charts';
import { summaryIds, communityInfoFields } from '../../constants';
import { getParamWithoutStateInSelector } from '../../utils/store';

export const getSelectedCommunityId = (state) => {
  return state.community.selectedCommunityId;
};

export const getSelectedGroupId = (state) => {
  return state.community.selectedGroupId;
};

export const getCommunityIds = (state) => {
  return state.community.communityIds;
};

export const getCommunityEntities = (state) => {
  return state.community.communityEntities;
};

export const getCommunityById = createSelector(
  [getCommunityEntities, getParamWithoutStateInSelector],
  (entities, id) => {
    return entities[id];
  },
);

export const getCurrentCommunity = createSelector(
  [getCommunityEntities, getSelectedCommunityId],
  (entities, id) => {
    return entities[id];
  },
);

export const getCommunityAnalytics = (state) => {
  return state.community.analytics.data;
};

export const getCommunityAnalyticsDateRange = (state) => {
  return state.community.analytics.dateRange;
};

export const getIsCommunityAnalyticsLoading = createSelector(
  [getCommunityAnalytics],
  (analytics) => {
    return R.isEmpty(analytics);
  },
);

export const getTopUploadedCategories = (state) => {
  return R.propOr([], ['topUploadedCategories'], getCommunityAnalytics(state));
};

export const getTopSoldCategories = (state) => {
  return R.propOr([], ['topSoldCategories'], getCommunityAnalytics(state));
};

export const getItemsUploadedWithinPeriodCount = (state) => {
  return R.propOr([], ['itemsUploadedWithinPeriod'], getCommunityAnalytics(state));
};

export const getItemsUploadedWithinPeriodValue = (state) => {
  return R.propOr([], ['itemsValueAddedWithinPeriod'], getCommunityAnalytics(state));
};

export const getItemsSoldWithinPeriodCount = (state) => {
  return R.propOr([], ['itemsSoldWithinPeriod'], getCommunityAnalytics(state));
};

export const getItemsSoldWithinPeriodValue = (state) => {
  return R.propOr([], ['itemsValueSoldWithinPeriod'], getCommunityAnalytics(state));
};

export const getMembersJoinedWithinPeriodCount = (state) => {
  return R.propOr([], ['membersJoinedWithinPeriod'], getCommunityAnalytics(state));
};

export const getMembersJoinedCountAnalytics = createSelector(
  [getMembersJoinedWithinPeriodCount],
  (data) => {
    const sortedData = sortListByValue(data, 'day');

    return sortedData.map(({ day, count }) => {
      return [moment(day).valueOf(), count];
    });
  },
);

export const getGroupEntities = (state) => {
  return state.community.groupEntities;
};

export const getGroupIds = (state) => {
  return state.community.groupIds;
};

export const getCommunities = createSelector(
  [getCommunityEntities, getCommunityIds],
  (entities, ids) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);

export const getItemsAnalyticsFactory = (uploadedSelector, soldSelector) => {
  return createSelector([uploadedSelector, soldSelector], (uploadedData, soldData) => {
    return [uploadedData, soldData].map((data) => {
      const sortedData = sortListByValue(data, 'day');

      return sortedData.map(({ day, count }) => {
        return [moment(day).valueOf(), count];
      });
    });
  });
};

export const getItemsCountAnalytics = getItemsAnalyticsFactory(
  getItemsUploadedWithinPeriodCount,
  getItemsSoldWithinPeriodCount,
);
export const getItemsValueAnalytics = getItemsAnalyticsFactory(
  getItemsUploadedWithinPeriodValue,
  getItemsSoldWithinPeriodValue,
);

export const getCategoriesAnalyticsFactory = (selector) => {
  return createSelector([selector], (data) => {
    return data.map(({ categoryName, value }, index) => {
      return {
        name: formatChartString(categoryName),
        data: [parseInt(value, 10)],
        color: chartColors[index],
      };
    });
  });
};

export const getTopUploadedCategoriesAnalytics = getCategoriesAnalyticsFactory(
  getTopUploadedCategories,
);
export const getTopSoldCategoriesAnalytics = getCategoriesAnalyticsFactory(getTopSoldCategories);

export const getSummaryList = createSelector([getCommunityAnalytics], (analytics) => {
  return [
    { label: summaryIds.totalCount, value: analytics.totalUploadedItemsCount },
    { label: summaryIds.totalValue, value: analytics.totalUploadedItemsValue },
    { label: summaryIds.soldCount, value: analytics.totalSoldItemsCount },
    { label: summaryIds.soldValue, value: analytics.totalSoldItemsValue },
    { label: summaryIds.marketCount, value: analytics.totalActiveItemsCount },
    { label: summaryIds.marketValue, value: analytics.totalActiveItemsValue },
    { label: summaryIds.members, value: analytics.membersJoinedCount },
    { label: summaryIds.issuedFunds, value: analytics.totalIssuedFunds },
    { label: summaryIds.remainingSupply, value: analytics.totalRemainingSupply },
  ];
});

export const getCommunitiesForSelect = createSelector([getCommunities], (communities) => {
  return communities.map(({ id, name, communityIcon }) => {
    return {
      value: id,
      label: name,
      icon: communityIcon,
    };
  });
});

export const getGroups = createSelector([getGroupEntities, getGroupIds], (entities, ids) => {
  return ids.map((id) => {
    return entities[id];
  });
});

export const getGroupById = (state, id) => {
  return getGroupEntities(state)[id];
};

export const getSelectedGroup = createSelector(
  [getGroupEntities, getSelectedGroupId],
  (entities, id) => {
    return entities[id];
  },
);

export const getGroupsForSelect = createSelector([getGroups], (groups) => {
  return groups.map(({ id, groupName, about, tokenIcon }) => {
    return {
      value: id,
      label: groupName,
      description: about,
      icon: tokenIcon,
    };
  });
});

export const getCommunityInfo = createSelector([getCurrentCommunity], (community) => {
  if (!community) {
    return {};
  }

  return R.pick(communityInfoFields, community);
});
