import * as React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import { offerTypes } from '../../../../constants/offers';
import { MEMBERS_PAGE_LIMIT, memberActivityTypes } from '../../../../utils/members';
import {
  formatDateWithLocale,
  getDistanceBetweenDates,
  getDifferenceInMinutes,
} from '../../../../utils/dateFns';
import Widget from '../../../../components/Widget';
import Table from '../../../../components/Table';
import Text, { textProps } from '../../../../components/Text';
import Button, { buttonProps } from '../../../../components/Button';
import ButtonWithPopover from '../../../../components/Button/ButtonWithPopover';
import styles from './ActivitiesTable.module.scss';

const MIN_MINUTES_PER_OFFER = 10;

const ActivitiesTable = ({
  items,
  type,
  userId,
  cancelItemArrival,
  declineItemRequest,
  performRefund,
}) => {
  const translate = useTranslate();
  const locale = useLocale();

  const dateFormatter = (value) => {
    return formatDateWithLocale(value, locale);
  };

  const durationFormatter = (_, row) => {
    if (parseInt(row.statusId, 10) !== offerTypes.ITEM_DELIVERED) {
      return (
        <Text
          size={textProps.sizes.small}
          weight={textProps.weights.semiBold}
          color={textProps.colors.secondary}
        >
          {translate('general.label.inProgress')}
        </Text>
      );
    }

    const differenceInMinutes = getDifferenceInMinutes(row.updatedAt, row.createdAt);
    const formattedDifference = getDistanceBetweenDates(row.updatedAt, row.createdAt, locale);

    return (
      <Text
        size={textProps.sizes.small}
        weight={textProps.weights.semiBold}
        color={
          differenceInMinutes < MIN_MINUTES_PER_OFFER
            ? textProps.colors.danger
            : textProps.colors.success
        }
      >
        {formattedDifference}
      </Text>
    );
  };

  const sellerFormatter = (value, row) => {
    return (
      <Link to={`/members/${row.sellerId}`}>
        <div className={styles.nameWrapper}>
          <img src={row.sellerProfileImage} className={styles.avatar} alt={row.sellerName} />
          <span>{`${row.sellerName} (ID ${value})`}</span>
        </div>
      </Link>
    );
  };

  const buyerFormatter = (value, row) => {
    return (
      <Link to={`/members/${row.buyerId}`}>
        <div className={styles.nameWrapper}>
          <img src={row.buyerProfileImage} className={styles.avatar} alt={row.buyerName} />
          <span>{`${row.buyerName} (ID ${value})`}</span>
        </div>
      </Link>
    );
  };

  const groupFormatter = (value, row) => {
    return (
      <span>{`${row.groupName[locale]} (#${value})`}</span>
    );
  };

  const itemFormatter = (value, row) => {
    return (
      <div className={styles.itemInfoWrapper}>
        <Text
          size={textProps.sizes.small}
          weight={textProps.weights.semiBold}
        >
          {value}
        </Text>
        <Text
          size={textProps.sizes.xSmall}
          color={textProps.colors.secondary}
        >
          {`(ID ${row.itemId})`}
        </Text>
      </div>
    );
  };

  const statusFormatter = (value) => {
    const offerTypesMap = {
      [offerTypes.PENDING_DECISION]: translate('pages.memberInfo.offerStatuses.pending'),
      [offerTypes.OFFER_CONFIRMED]: translate('pages.memberInfo.offerStatuses.confirmed'),
      [offerTypes.ITEM_DELIVERED]: translate('pages.memberInfo.offerStatuses.delivered'),
    };

    return (
      <Text
        size={textProps.sizes.small}
        weight={textProps.weights.semiBold}
        color={textProps.colors.primary}
      >
        {offerTypesMap[value]}
      </Text>
    );
  };

  const actionsFormatter = (_, row) => {
    const onCancelArrivalClick = () => {
      cancelItemArrival({ offerId: row.id, userId, activityType: type });
    };

    const onDeclineItemRequestClick = () => {
      declineItemRequest({ offerId: row.id });
    };

    const onPerformRefundClick = () => {
      performRefund({ offerId: row.id });
    };

    const isPendingDecision = parseInt(row.statusId, 10) === offerTypes.PENDING_DECISION;
    const isOfferConfirmed = parseInt(row.statusId, 10) === offerTypes.OFFER_CONFIRMED;
    const isItemDelivered = parseInt(row.statusId, 10) === offerTypes.ITEM_DELIVERED;

    return (
      <ButtonWithPopover
        id={`button-${row.id}`}
        title={
          <i className={cls('fa', 'fa-ellipsis-v')} />
        }
      >
        {isPendingDecision && (
          <Button
            onClick={onDeclineItemRequestClick}
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            marginTop={false}
          >
            {translate('pages.transactions.table.buttons.performDecline')}
          </Button>
        )}

        {isOfferConfirmed && (
          <Button
            onClick={onPerformRefundClick}
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            marginTop={false}
          >
            {translate('pages.transactions.table.buttons.performRefund')}
          </Button>
        )}

        {isItemDelivered && (
          <Button
            onClick={onCancelArrivalClick}
            size={buttonProps.sizes.null}
            theme={buttonProps.themes.link}
            marginTop={false}
          >
            {translate('pages.memberInfo.buttons.cancelArrival')}
          </Button>
        )}
      </ButtonWithPopover>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: translate('pages.memberInfo.table.columns.offerId'),
    },
    {
      dataField: 'sellerId',
      text: translate('pages.memberInfo.table.columns.seller'),
      formatter: sellerFormatter,
      align: 'start',
      headerAlign: 'start',
      hidden: type === memberActivityTypes.SELLING,
    },
    {
      dataField: 'buyerId',
      text: translate('pages.memberInfo.table.columns.buyer'),
      formatter: buyerFormatter,
      align: 'start',
      headerAlign: 'start',
      hidden: type === memberActivityTypes.BUYING,
    },
    {
      dataField: 'groupId',
      text: translate('pages.memberInfo.table.columns.group'),
      formatter: groupFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'itemTitle',
      text: translate('pages.memberInfo.table.columns.item'),
      formatter: itemFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'itemPrice',
      text: translate('pages.memberInfo.table.columns.price'),
    },
    {
      dataField: 'createdAt',
      text: translate('pages.memberInfo.table.columns.created'),
      formatter: dateFormatter,
    },
    {
      dataField: 'updatedAt',
      text: translate('pages.memberInfo.table.columns.updated'),
      formatter: dateFormatter,
    },
    {
      dataField: 'statusId',
      text: translate('pages.memberInfo.table.columns.status'),
      formatter: statusFormatter,
    },
    {
      dataField: '',
      text: translate('pages.memberInfo.table.columns.duration'),
      formatter: durationFormatter,
    },
    {
      dataField: '',
      text: translate('pages.memberInfo.table.columns.actions'),
      formatter: actionsFormatter,
    },
  ];

  return (
    <Widget
      title={
        type === memberActivityTypes.BUYING
          ? translate('pages.memberInfo.table.title.buying')
          : translate('pages.memberInfo.table.title.selling')
      }
      className={cls(styles.wrapper, 'shadow-none')}
    >
      <Table
        data={items}
        columns={columns}
        paginationProps={{ sizePerPage: MEMBERS_PAGE_LIMIT }}
        useOverlay
      />
    </Widget>
  );
};

ActivitiesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    sellerId: PropTypes.string,
    sellerName: PropTypes.string,
    sellerProfileImage: PropTypes.string,
    buyerId: PropTypes.string,
    buyerName: PropTypes.string,
    buyerProfileImage: PropTypes.string,
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    groupId: PropTypes.string,
    groupName: PropTypes.shape({
      en: PropTypes.string,
      he: PropTypes.string,
    }),
    itemId: PropTypes.string,
    itemTitle: PropTypes.string,
    itemPrice: PropTypes.string,

  })).isRequired,
  type: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cancelItemArrival: PropTypes.func.isRequired,
  declineItemRequest: PropTypes.func.isRequired,
  performRefund: PropTypes.func.isRequired,
};

export default ActivitiesTable;
