import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styles from './TabsPanel.module.scss';

const TabsPanel = memo(({
  options,
  activeTab,
  onTabClick,
}) => {
  return (
    <Nav
      className={cls('flex-column', 'flex-sm-row', styles.navTabs)}
      tabs
    >
      {options.map(({ value, label }) => {
        const isActiveTab = value === activeTab;
        const onClick = () => {
          onTabClick(value);
        };

        return (
          <NavItem
            key={value}
            className={cls(styles.navItem, {
              [styles.active]: isActiveTab,
            })}
          >
            <NavLink
              className={cls('font-weight-bold', 'p-4', {
                active: isActiveTab,
              })}
              onClick={onClick}
            >
              <span>{label}</span>
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
});

TabsPanel.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default TabsPanel;
