const prefix = 'features/';

export const SET_COMMUNITY_FEATURES = `${prefix}SET_COMMUNITY_FEATURES`;
export const SET_GROUP_FEATURES = `${prefix}SET_GROUP_FEATURES`;

export const SET_CURRENT_GROUP_ID = `${prefix}SET_CURRENT_GROUP_ID`;

export const UPDATE_COMMUNITY_FEATURES = `${prefix}UPDATE_COMMUNITY_FEATURES`;
export const UPDATE_COMMUNITY_FEATURES_SUCCESS = `${prefix}UPDATE_COMMUNITY_FEATURES_SUCCESS`;

export const UPDATE_GROUP_FEATURES = `${prefix}UPDATE_GROUP_FEATURES`;
export const UPDATE_GROUP_FEATURES_SUCCESS = `${prefix}UPDATE_GROUP_FEATURES_SUCCESS`;
