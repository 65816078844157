import api from './Api';

const getCommunityGroups = (communityId) => {
  return api.get(`/admin/getCommunityGroups/${communityId}`);
};

const getAdminCommunities = () => {
  return api.get('/admin/getCommunities');
};

const getCommunityAnalytics = (data) => {
  return api.post('/admin/getAnalytics', data);
};

const verifyCommunityInfoField = (data) => {
  return api.post('admin/verifyCommunityInfoField', data);
};

const updateCommunityInfo = (data) => {
  return api.put('/admin/updateCommunityInfo', data);
};

const updateGroupInfo = (data) => {
  return api.put('/admin/updateGroupInfo', data);
};

const updateGroupsOrder = (group_ids) => {
  return api.post('/admin/groups/updateOrder', group_ids);
};

const updateCommunityLocation = ({ default_location, communityId }) => {
  return api.patch(`/v2/admin/community/${communityId}`, {
    default_location,
  });
};

export default {
  getCommunityGroups,
  getAdminCommunities,
  getCommunityAnalytics,
  verifyCommunityInfoField,
  updateCommunityInfo,
  updateGroupInfo,
  updateGroupsOrder,
  updateCommunityLocation,
};
