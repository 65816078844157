import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale, useSetLocale } from 'react-admin';
import { changeLocale } from '../store/ui/actions';
import getIsRTLMode from './isRTL';

const rtlBasedLangs = ['he'];

const contentModes = {
  ltr: 'ltr',
  rtl: 'rtl',
};

const LocaleProvider = () => {
  const dispatch = useDispatch();

  const currentLocale = useLocale();
  const setLocale = useSetLocale();

  useEffect(() => {
    const locale = localStorage.getItem('lang');

    if (!locale) {
      localStorage.setItem('lang', currentLocale);
      return;
    }

    if (locale !== currentLocale) {
      setLocale(locale);
      dispatch(changeLocale(locale));
    }
  });

  useEffect(() => {
    const isRTLMode = getIsRTLMode();
    const isRTLLang = rtlBasedLangs.indexOf(currentLocale) !== -1;

    if (isRTLLang && !isRTLMode) {
      document.body.setAttribute('dir', contentModes.rtl);
    }

    if (!isRTLLang && isRTLMode) {
      document.body.setAttribute('dir', contentModes.ltr);
    }
  }, [currentLocale]);

  return null;
};

export default LocaleProvider;
