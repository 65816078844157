class ModalService {
  constructor() {
    this._modal = null;
  }

  init(ref) {
    if (this._modal) {
      return;
    }

    this._modal = ref;
  }

  unmount() {
    this._modal = null;
  }

  open(id, options = {}) {
    this._modal.openModal(id, options);
  }
}

const modalService = new ModalService();

export default modalService;
