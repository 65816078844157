import * as React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Modal from '../../components';
import Text, { textProps } from '../../../components/Text';
import styles from './ReferralUsersModal.module.scss';

const ReferralUsersModal = ({ options, closeModal }) => {
  const translate = useTranslate();

  return (
    <>
      <Modal.Header toggle={closeModal} className={styles.header}>
        {translate('pages.memberInfo.modals.title.referralUsers')}
      </Modal.Header>
      <Modal.Body>
        <div className={cls(styles.listContainer)}>
          {options.users.map(({ id, name, profileImage }) => {
            return (
              <Link to={`/members/${id}`} key={id} className="w-100" onClick={closeModal}>
                <div className={styles.listItem}>
                  <img src={profileImage} alt={name} className={cls(styles.profileImage, 'mr-2')} />

                  <Text size={textProps.sizes.large}>{`${name} (ID: ${id})`}</Text>
                </div>
              </Link>
            );
          })}
        </div>
      </Modal.Body>
    </>
  );
};

ReferralUsersModal.propTypes = {
  options: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      profileImage: PropTypes.string,
    })).isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ReferralUsersModal;
