/* eslint-disable max-len */
export default {
  not_found: 'מידע לא נמצא',
  general: {
    label: {
      phone_number: 'מספר טלפון',
      app_name: 'שם האפליקציה',
      enter_pin: 'תכניס קוד',
      help: 'עֶזרָה',
      privacy: 'פְּרָטִיוּת',
      terms: 'תנאים',
      code: 'קוד',
      logout: 'להתנתק',
      enter_code: 'להזין את הקוד',
      deactivated: 'מושבת',
      selectGroup: 'בחר קבוצה',
      uploaded: 'הועלה',
      sold: 'נמכר',
      joined: 'הצטרף',
      noImage: 'אין תמונה',
      recommendedSize: '%{size} מידה מומלצת',
      loading: 'טעינה',
      noData: 'אין מידע',
      admin: 'מנהל',
      superAdmin: 'מנהל סופר',
      inProgress: 'בתהליך',
      active: 'פָּעִיל',
      shipper: 'סוֹכֵן הוֹבָלָה',
    },
    button: {
      send: 'לִשְׁלוֹחַ',
      confirm: 'לְאַשֵׁר',
      send_another: 'תשלח אחר',
      okay: 'בסדר',
      save: 'להציל',
      cancel: 'לְבַטֵל',
      create: 'לִיצוֹר',
      back: 'לְגַבּוֹת',
    },
    validation: {
      notEmpty: 'השדה לא אמור להיות ריק',
    },
    alert: {
      phone: 'אנא אשר את קוד המדינה שלך והזן את מספר הטלפון שלך',
      confirmation: 'אנא הזן את קוד האימות ששלחנו למספר שלך',
    },
    hints: {
      sidebarButton: 'הפעל / כבה את סרגל הצד',
    },
  },
  pages: {
    login: {
      title: 'אנא היכנס בכדי להמשיך',
    },
    handle_code: {
      dialog_title: 'סיכה שגויה',
      dialog_message: 'הסיכה בארבע הספרות בה השתמשת אינה חוקית. נסה שוב או בקש סיכה חדשה.',
    },
    dashboard: {
      title: {
        page: 'ניתוח קהילתי',
        menu: 'לוּחַ מַחווָנִים',
      },
      itemsCountAnalytics: 'ספירת פריטים, הועלתה ונמכרה',
      itemsValueAnalytics: 'ערך הפריטים, הועלה ונמכר',
      membersJoinedCountAnalytics: 'ספירת החברים הצטרפה',
      topUploadedCategories: 'הקטגוריות המועלות ביותר',
      topSoldCategories: 'קטגוריות הנמכרות ביותר',
      summary: {
        totalCount: 'פריטים שהועלו מספרים',
        totalValue: 'ערך פריטים שהועלה',
        soldCount: 'פריטים שנמכרו סופרים',
        soldValue: 'פריטים שנמכרו',
        marketCount: 'פריטים בשוק מספרים',
        marketValue: 'פריטים לפי שווי השוק',
        members: 'חברים הצטרפו',
        issuedFunds: 'כספים שהונפקו',
        remainingSupply: 'ההיצע שנותר',
      },
      buttons: {
        updateDateRange: 'טווח עדכונים',
      },
      modal: {
        header: 'עדכן את טווח התאריכים של ניתוחים',
        labels: {
          startDate: 'מִן',
          endDate: 'עַד',
        },
      },
    },
    communityInfo: {
      title: {
        page: 'מידע קהילתי',
        menu: 'מידע קהילתי',
      },
      labels: {
        name: 'שם קהילה',
        about: 'תיאור הקהילה',
        icon: 'סמל קהילה',
        tokenName: 'שם אסימון',
        tokenIcon: 'סמל אסימון',
        walletImage: 'תמונת ארנק',
        addLocation: 'הוסף מיקום לקהילה',
        searchLocation: 'חפש מיקום...',
        saveLocation: 'שמור מיקום',
      },
      validation: {
        errors: {
          tooShort: 'השם קצר מדי (מינימום 3 תווים)',
          notUnique: 'השם כבר תפוס',
        },
      },
    },
    members: {
      title: {
        page: 'חברי הקהילה',
        menu: 'חברים',
      },
      labels: {
        types: {
          all: 'חברים',
          suspended: 'השעיות',
          suspicious: 'חשדות',
        },
      },
      table: {
        search: {
          placeholder: 'לחפש',
        },
        switcher: {
          title: 'פעילים בלבד',
        },
        columns: {
          profileImage: 'תמונה',
          name: 'הצג שם',
          nickname: 'הצג שם',
          id: 'תְעוּדַת זֶהוּת',
          lastAccessDate: 'תאריך הכניסה האחרון',
          createdAtDate: 'תאריך יצירת חשבון',
          status: 'סטָטוּס',
          actions: 'פעולות',
          phoneNumber: 'מספר טלפון',
          initiator: 'מזהה יוזם',
          reason: 'סיבה להפעלה',
          rehabNote: 'הערת השבתה',
          activationDate: 'הופעל בשעה',
          deactivationDate: 'מושבת בשעה',
          gotItems: 'Got items',
          gaveItems: 'Gave items',
          uploadedItems: 'Uploaded items',
          totalPoints: 'Total points',
          referralId: 'Referral Id',
        },
        statuses: {
          member: 'חבר',
          newMember: 'חבר חדש',
          admin: 'מנהל',
          pendingApproval: 'ממתין לאישור',
          declined: 'סירב',
        },
      },
      show_members_filter: 'הצג מסנני חברים',
      filter_members_by: 'סנן חברים לפי',
      members_created_before: 'חברים שנוצרו לפני',
      members_created_after: 'חברים שנוצרו אחרי',
      members_last_login_before: 'חברי כניסה אחרונה לפני',
      members_last_login_after: 'חברי כניסה אחרונה אחרי',
      members_got_more_than: 'חברים שקיבלו יותר מ',
      members_got_less_than: 'חברים שקיבלו פחות מ',
      members_with_more_items_than: 'חברים עם יותר פריטים מ',
      members_with_fewer_items_than: 'חברים עם פחות פריטים מ',
      members_with_more_points_than: 'חברים עם יותר נקודות מ',
      members_with_fewer_points_than: 'חברים עם פחות נקודות מ',
      members_gave_more_than: 'חברים שנתנו יותר מ',
      members_gave_less_than: 'חברים שנתנו פחות מ',
      cancel: 'ביטול',
      clear_filters: 'נקה מסננים',
      apply_filters: 'החל מסננים',
    },
    memberInfo: {
      title: 'פרופיל חבר',
      cardTitle: 'מידע',
      activitiesTitle: 'פעילויות',
      joinedAt: 'הצטרף ב %{date}',
      lastSeen: 'נראה לאחרונה: %{distance}',
      phoneNumber: 'מספר טלפון: %{phoneNumber}',
      suspended: 'משתמש זה הושעה על ידי מנהלי הקהילה (%{distance}) ',
      markedAsSuspicious: 'משתמש זה סומן כחשוד על ידי מנהלי מערכת',
      suspensionsCount: 'השעיות',
      suspiciousCount: 'זמנים שסומנו כחשודים',
      walletBalance: 'איזון ארנק',
      reason: 'סיבה: %{reason}',
      buttons: {
        suspend: 'לְהַשְׁעוֹת',
        unsuspend: 'הסר את המתלה',
        mark: 'סמן כחשוד',
        unmark: 'הסר מחשד',
        activateShipper: 'הגדר כשולח',
        deactivateShipper: 'הסר את סטטוס המשלח',
        promoteToAdmin: 'קדם למנהל',
      },
      tooltips: {
        sameUser: 'המשתמש אינו יכול להשעות / לסמן כחשוד בעצמו',
        superAdmin: 'לא ניתן להשעות את סופר-מנהל / לסמן כחשוד',
        noAccess: 'רק מנהלי על יכולים להשעות מנהל אחר',
        whiteList: 'לא ניתן להשעות / לסמן משתמש זה כחשוד',
      },
      modals: {
        title: {
          suspicious: {
            add: 'סמן משתמש זה כחשוד',
            delete: 'האם אתה בטוח?',
          },
          suspension: {
            add: 'השעיית המשתמש',
            delete: 'משתמש שאינו מושעה',
          },
        },
        inputs: {
          labels: {
            reason: 'סיבה (חובה)',
          },
        },
      },
      table: {
        title: {
          buying: 'קְנִיָה',
          selling: 'מוכרים',
        },
        columns: {
          offerId: 'מזהה הצעה',
          buyer: 'קוֹנֶה',
          seller: 'מוֹכֵר',
          created: 'נוצר',
          updated: 'עודכן לאחרונה',
          status: 'סטָטוּס',
          duration: 'E2E',
          group: 'קְבוּצָה',
          item: 'פריט',
          price: 'מחיר',
        },
      },
      offerStatuses: {
        pending: 'Ask to receive',
        confirmed: 'מְאוּשָׁר',
        delivered: 'הפריט נמסר',
      },
    },
    transactions: {
      title: {
        page: 'עסקאות קהילתיות',
        menu: 'עסקאות',
      },
      labels: {
        types: {
          transfer: 'מְכִירָה',
          bonus: 'מַעֲנָק',
          issue: 'נושא',
        },
        search: {
          placeholder: 'חפש לפי שם או מזהה משתמש',
          offerPlaceholder: 'Search by offer ID',
        },
        export: 'ייצא ל- CSV',
        create: 'עסקה חדשה',
        filter: 'לְסַנֵן',
      },
      table: {
        columns: {
          id: 'תְעוּדַת זֶהוּת',
          receiverName: 'מַקְלֵט',
          senderName: {
            transfer: 'שׁוֹלֵחַ',
            issue: 'שׁוֹלֵחַ',
            bonus: 'צִבּוּר',
          },
          groupName: 'קְבוּצָה',
          amount: 'כמות',
          createdAt: 'תַאֲרִיך',
          reason: {
            transfer: 'כותרת פריט',
            issue: 'סיבה',
            bonus: 'כותרת הפרס',
          },
          type: 'סוּג',
          offerInfo: 'Offer info',
          actions: 'Actions',
        },
        buttons: {
          performDecline: 'Decline request',
          performRefund: 'Perform refund',
        },
      },
      modal: {
        title: 'צור עסקה',
        select: {
          placeholder: 'בחר מקלטים',
          label: 'מקלטים',
        },
        amount: {
          label: 'כמות',
          validation: 'הסכום חייב להיות גדול מ- 0',
        },
        note: {
          label: 'הערה',
          validation: 'שדה ההערות לא יכול להיות ריק',
        },
      },
    },
    groupInfo: {
      title: {
        page: 'מידע קבוצתי',
        menu: 'מידע קבוצתי',
      },
      inputs: {
        groupName: {
          label: {
            en: 'שם הקבוצה (אנגלית)',
            he: 'שם הקבוצה (עברית)',
          },
        },
        groupDescription: {
          label: 'תיאור קבוצה',
        },
        imageUploader: {
          label: 'הצג באנר קבוצתי',
        },
      },
      buttons: {
        save: 'להציל',
        cancel: 'לְבַטֵל',
        select: 'בחר תמונה',
        changeOrder: 'Change order',
      },
    },
    rules: {
      title: {
        page: 'כללים',
        menu: 'כללים',
      },
      labels: {
        types: {
          community: 'קהילה',
          item: 'פריט',
          wish: 'בַּקָשָׁה',
        },
      },
      buttons: {
        create: 'לִיצוֹר',
        edit: 'לַעֲרוֹך',
        delete: 'לִמְחוֹק',
      },
      modal: {
        header: {
          create: 'צור כלל',
          edit: 'ערוך את הכלל',
        },
        inputs: {
          labels: {
            title: 'כותרת הכלל (מקסימום 50 תווים)',
            description: 'תיאור הכלל (מקסימום 150 תווים)',
          },
        },
        footer: {
          buttons: {
            cancel: 'לְבַטֵל',
            save: 'להציל',
          },
        },
      },
    },
    features: {
      title: {
        page: 'תכונות',
        menu: 'תכונות',
      },
      sections: {
        community: {
          title: 'תכונות קהילתיות',
        },
        group: {
          title: 'תכונות קבוצתיות',
        },
      },
      options: {
        hasFollowers: {
          title: 'עוקבים או עוקבים',
          description:
            'אפשר לחברים לעקוב אחר חברי קהילה אחרים. הפעל תכונה זו כדי להפעיל את הסינון וההודעה.',
        },
        hasWishes: {
          title: 'משאלות',
          description: 'אפשר לחברים להעלות משאלות לפריטים שהם מחפשים.',
        },
        hasDiscounts: {
          title: 'הנחות',
          description:
            'אפשר לחברים להוזיל פריטים לאחר שהם זמינים במשך שבוע. הנחה על פריט תביא אותו לראש העדכון הקבוצתי.',
        },
        hasFavorites: {
          title: 'פריטים שמורים',
          description:
            'אפשר לחברים לשמור פריטים שהם מעוניינים בהם. חברים יוכלו לראות את הפריטים השמורים שלהם בעמוד אחד. חברים יקבלו הודעה כאשר הפריטים השמורים שלהם כבר לא יהיו זמינים.',
        },
        hasBrands: {
          title: 'אוֹת קָלוֹן',
          description:
            'אפשר לחברים לבחור מותגים עבור הפריטים שהם מעלים. חברים יכולים לסנן עבור מותגים ספציפיים בשוק.',
        },
        hasSizes: {
          title: 'מִדָה',
          description: 'אפשר לחברים לבחור גודל בעת העלאת פריט.',
        },
        hasQuestions: {
          title: 'שאלות חברות',
          description: 'השתמש בשאלות חברים כדי לזהות את האנשים המתאימים לקהילה שלך.',
        },
        hasSlider: {
          title: 'קרוסלה קהילתית',
          description:
            'צור כניסה ייחודית לקהילה שלך באמצעות הקרוסלה. תן לחברים מידע נוסף על הקהילה שלך לפני שהם מצטרפים.',
        },
        hasSellerItemRepost: {
          title: 'מוכרים יותר מאחד',
          description:
            'אפשר לחברים למכור מספר כמויות מאותו פריט. הפריט יישאר פעיל בשוק עד שהחבר יבחר להסירו.',
        },
        hasBuyerItemRepost: {
          title: 'פרסם פריט שנרכש',
          description: 'אפשר לחברים להוסיף פריט שנרכש לשוק.',
        },
        isPrivate: {
          title: 'פְּרָטִי',
          description: 'הסט הוא פרטי בקהילה',
        },
        isOpenForVisitors: {
          title: 'פתוח למבקרים',
          description: 'סט הוא קהילה הפתוחה למבקרים',
        },
      },
    },
    categories: {
      title: {
        page: 'קטגוריות',
        menu: 'קטגוריות',
      },
      buttons: {
        create: 'לִיצוֹר',
        add: 'לְהוֹסִיף',
        edit: 'לַעֲרוֹך',
        deactivate: 'השבת',
        activate: 'לְהַפְעִיל',
        save: 'להציל',
        cancel: 'לְבַטֵל',
      },
      labels: {
        selectGroup: 'בחר קבוצה',
        deactivated: 'מושבת',
        categoryName: 'שם קטגוריה',
        iconName: 'שם אייקון',
      },
      modal: {
        header: {
          create: 'צור קטגוריה',
          edit: 'ערוך קטגוריה',
        },
      },
    },
    brands: {
      title: {
        page: 'אוֹת קָלוֹן',
        menu: 'אוֹת קָלוֹן',
      },
      description: {
        left:
          'אפשר לחברים לבחור מותגים עבור הפריטים שהם מעלים. חברים יכולים לסנן עבור מותגים ספציפיים בשוק. כדי להפעיל או לכבות את המותגים בקבוצה, עבור אל ',
        link: 'תכונות',
        right: ' עמוד',
      },
      labels: {
        selectGroup: 'בחר קבוצה',
        deactivated: 'מושבת',
        brandName: 'שם מותג',
      },
      buttons: {
        create: 'לִיצוֹר',
        cancel: 'לְבַטֵל',
        save: 'להציל',
      },
      modal: {
        header: {
          add: 'הוסף מותג',
          edit: 'ערוך מותג',
        },
      },
      validation: {
        brandName: 'שם המותג חייב להיות ייחודי ולא ריק',
      },
    },
    conditions: {
      title: {
        page: 'תנאי הפריט',
        menu: 'תנאים',
      },
      labels: {
        selectGroup: 'בחר קבוצה',
        english: 'אנגלית',
        hebrew: 'עִברִית',
        priceCoefficient: 'מקדם מחירים',
        title: 'כותרת',
        subtitle: 'כתוביות',
      },
      buttons: {
        create: 'לִיצוֹר',
        cancel: 'לְבַטֵל',
        save: 'להציל',
      },
      modal: {
        header: {
          create: 'צור תנאי',
          edit: 'ערוך תנאי',
        },
      },
      validation: {
        any: 'השדה לא אמור להיות ריק',
      },
    },
    sizes: {
      title: {
        page: 'מידות',
        menu: 'מידות',
      },
      modal: {
        header: {
          create: 'צור גודל',
          edit: 'ערוך גודל',
        },
      },
      labels: {
        titleEn: 'כותרת גודל (אנגלית)',
        titleHe: 'כותרת גודל (עברית)',
      },
    },
    reports: {
      title: {
        page: 'דוחות קהילתיים',
        menu: 'דיווחים',
      },
      labels: {
        types: {
          user: 'דוחות משתמשים',
          item: 'דוחות פריטים',
          reporters: 'פַּרטָר',
          reportedUsers: 'משתמשים מדווחים',
          top: 'דיווחים מובילים',
          daily: 'דוחות יומיים',
        },
        filter: 'לְסַנֵן',
        charts: {
          titles: {
            item: 'דוחות פריטים מובילים (%{total} סה"כ)',
            user: 'דוחות משתמשים מובילים (%{total} סה"כ)',
          },
        },
        table: {
          reporters: {
            item: 'לפי פריט',
            user: 'לפי משתמש',
          },
        },
      },
      active_users: 'משתמשים פעילים',
      new_registered_users: 'משתמשים חדשים שנרשמו',
      uploaded: 'הועלה',
      exchanged: 'הוחלף',
      shipped: 'נשלח',
      refunded: 'הוחזר',
      active_tokens: 'אסימונים פעילים',
      available_in_market: 'זמין בשוק',
      summary_statistics: 'סטטיסטיקה מסכמת',
      members: 'חברים',
      givers: 'נותנים',
      receivers: 'מקבלים',
      verified_members: 'חברים מאומתים',
      uploaded_value: 'ערך שהועלה',
      exchanged_value: 'ערך שהוחלף',
      shipped_value: 'ערך שנשלח',
      refunded_value: 'ערך שהוחזר',
      items_in_market: 'פריטים בשוק',
      issued_points: 'נקודות שהונפקו',
      total_items_uploaded: 'סה"כ פריטים שהועלו',
      count: 'ספירה',
      value: 'ערך',
      unique_participants: 'משתתפים ייחודיים',
      unique_givers: 'נותנים ייחודיים',
      unique_uploaders: 'מעלים ייחודיים',
      unique_receivers: 'מקבלים ייחודיים',
      weekly_view: 'תצוגה שבועית',
      table: {
        reports: {
          columns: {
            id: 'תְעוּדַת זֶהוּת',
            createdAt: 'תַאֲרִיך',
            reportedUserName: 'אדם מדווח',
            reporterUserName: 'כַתָב',
            reasonId: 'סיבה',
            itemTitle: 'פריט מדווח',
          },
        },
        reporters: {
          columns: {
            id: 'תעודת זהות של משתמש',
            reporterName: 'מידע על המשתמש',
            value: 'ספירת דוחות',
          },
        },
      },
    },
    items: {
      title: {
        page: 'פריטים',
        menu: 'פריטים',
      },
      table: {
        columns: {
          item: 'פריט',
          seller: 'מוֹכֵר',
          createdAt: 'נוצר ב',
          price: 'מחיר נוכחי',
          originalPrice: 'מחיר מקורי',
          hasMultipleSupply: 'יש אספקה מרובה',
          status: 'סטָטוּס',
          actions: 'פעולות',
        },
        statuses: {
          active: 'פָּעִיל',
          sold: 'נמכר',
          pending: 'ממתין ל',
          hidden: 'מוּסתָר',
          removed: 'הוסר',
        },
        buttons: {
          hideItem: 'הסתר פריט',
        },
        search: {
          placeholder: 'חפש לפי מזהה פריט / מזהה מוכר / כותרת פריט',
        },
      },
    },
    talents: {
      title: {
        page: 'Talents',
        menu: 'Talents',
      },
      table: {
        columns: {
          talent: 'Talent',
          seller: 'מוֹכֵר',
          createdAt: 'נוצר ב',
          price: 'מחיר נוכחי',
          originalPrice: 'מחיר מקורי',
          hasMultipleSupply: 'יש אספקה מרובה',
          status: 'סטָטוּס',
          actions: 'פעולות',
        },
        statuses: {
          active: 'פָּעִיל',
          sold: 'נמכר',
          pending: 'ממתין ל',
          hidden: 'מוּסתָר',
          removed: 'הוסר',
        },
        buttons: {
          hideItem: 'הסתר פריט',
        },
        search: {
          placeholder: 'חפש לפי מזהה פריט / מזהה מוכר / כותרת פריט',
        },
      },
    },
    cities: {
      title: {
        menu: 'Cities',
      },
      table: {
        search: {
          placeholder: 'Search',
        },
        columns: {
          city: 'City',
          changeImage: 'Change Image',
          deleteCity: 'Delete city',
          editName: 'Change name',
          editImage: 'Change image',
        },
      },
      modal: {
        titleEn: 'Edit name (English)',
        titleHe: 'Edit name (Hebrew)',
        titleBr: 'Edit name (Brazil)',
        titleDe: 'Edit name (German)',
      },
    },
  },
  offers: {
    types: {
      interested: 'Interested',
      pendingDecision: 'Pending decision',
      offerConfirmed: 'Offer confirmed',
      itemDelivered: 'Item delivered',
      notAvailable: 'Not available',
      itemSold: 'Item sold',
      sellerLeftCommunity: 'Seller left community',
      buyerLeftCommunity: 'Buyer left community',
      itemRemoved: 'Item removed',
    },
  },
  message: {
    error: {
      invalid_code: 'הזן קוד חוקי',
      invalid_mobile: 'הזן את המספר הנייד התקף',
      only_admin_access: 'רק למנהלים יש גישה לפאנל',
      invalid_group_name: 'הערך חייב להיות מינימלי של 3 תווים',
      action: {
        login: {
          not_authorized: 'רק למנהלים יש גישה לפאנל',
          internal_error: 'לא ניתן לעבד את בקשתך',
        },
        verify_code: {
          not_authorized: 'רק למנהלים יש גישה לפאנל',
          internal_error: 'לא ניתן לעבד את בקשתך',
        },
      },
    },
    success: {
      action: {
        hideItem: 'Item successfully hidden from marketplace',
        hideTalent: 'Talent successfully hidden from marketplace',
        performDecline: 'Item request successfully declined',
        performRefund: 'Refund performed successfully',
        promoteToAdmin: 'המשתמש קודם בהצלחה למנהל',
        saveLocation: 'המיקום עודכן',
        transaction: {
          send: 'נשלח בהצלחה',
        },
        community: {
          info: {
            saved: 'נשמר בהצלחה',
          },
        },
        login: {
          code_sent: 'קוד אימות נשלח',
        },
        cities: {
          removed: 'City removed successfully',
        },
      },
    },
  },
  resources: {},
  ra: {
    action: {
      add_filter: 'הוסף סינון',
      add: 'הוסף',
      back: 'חזור',
      bulk_actions: 'פריט אחד נבחר |||| %{smart_count} פריטים נבחרו',
      cancel: 'ביטול',
      clear_input_value: 'נקה ערך',
      clone: 'שכפל',
      confirm: 'אשר',
      create: 'צור',
      delete: 'מחק',
      edit: 'יצא',
      list: 'רשימה',
      refresh: 'רענן',
      remove_filter: 'הסר סינון',
      remove: 'מחק',
      save: 'שמור',
      search: 'חפש',
      show: 'הצג',
      sort: 'מיין',
      undo: 'בטל',
    },
    boolean: {
      true: 'כן',
      false: 'לא',
    },
    page: {
      create: 'צור %{name}',
      dashboard: 'לוח המחוונים',
      edit: '%{name} #%{id}',
      error: 'שגיאה התרחשה',
      list: 'רשימת %{name}',
      loading: 'טוען',
      not_found: 'לא קיים',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several: 'גרור מספר קבצים להעלאה או לחץ לבחור אחד.',
        upload_single: 'גרור קובץ להעלאה או לחץ לבחור אחד.',
      },
      image: {
        upload_several: 'גרור מספר תמונות להעלאה או לחץ לבחור אחת.',
        upload_single: 'גרור תמונה להעלאה או לחץ לבחור אחת.',
      },
      references: {
        all_missing: 'ישויות הקשר לא נמצאו.',
        many_missing: 'לפחות אחת מישויות הקשר אינה זמינה יותר.',
        single_missing: 'ישות הקשר אינה זמינה יותר.',
      },
    },
    message: {
      about: 'אודות',
      are_you_sure: 'האם את/ה בטוח/ה ?',
      bulk_delete_content:
        'האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?',
      bulk_delete_title: 'מחק %{name} |||| מחק %{smart_count} %{name} פריטים',
      delete_content: 'האם אתה בטוח שברצונך למחוק את הפריט הזה?',
      delete_title: 'מחק %{name} #%{id}',
      details: 'פרטים',
      error: 'קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.',
      invalid_form: 'הטופס אינו תקין. אנא תקן את שגיאותיך',
      loading: 'העמוד טוען, אנא המתן',
      no: 'לא',
      yes: 'כן',
      not_found: 'הכנסת כתובת שגויה או לחצת על קישור לא תקין',
    },
    navigation: {
      no_results: 'לא נמצאו תוצאות',
      no_more_results: 'עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.',
      page_out_of_boundaries: 'עמוד %{page} מחוץ לתחום',
      page_out_from_end: 'לא ניתן להמשיך מעבר לעמוד האחרון',
      page_out_from_begin: 'לא ניתן לגשת לפני העמוד הראשון',
      page_range_info: '%{offsetBegin}-%{offsetEnd} מתוך %{total}',
      page_rows_per_page: 'מספר שורות לעמוד:',
      next: 'הבא',
      prev: 'הקודם',
    },
    auth: {
      user_menu: 'פרופיל',
      auth_check_error: 'אנא התחבר על מנת להמשיך',
      username: 'שם משתמש',
      password: 'סיסמא',
      sign_in: 'כניסה',
      sign_in_error: 'הכניסה נכשלה, אנא נסו שנית',
      logout: 'התנתקות',
    },
    notification: {
      updated: 'פריט עודכן בהצלחה',
      created: 'פריט נוצר בהצלחה',
      deleted: 'פריט נמחק בהצלחה',
      bad_item: 'פריט לא תקין',
      item_doesnt_exist: 'פריט לא קיים',
      http_error: 'שגיאה בהתקשרות מול השרת',
      data_provider_error: 'שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.',
      canceled: 'הפעולה בוטלה',
      logged_out: 'הנך מנותק, אנא התחבר מחדש.',
    },
    validation: {
      required: 'חובה',
      minLength: 'חייב להכיל לפחות %{min} תווים',
      maxLength: 'יכול להכיל לכל היותר %{max} תווים',
      minValue: 'חייב להיות לפחות %{min}',
      maxValue: 'חייב להיות %{max} או פחות',
      number: 'חייב להיות מספר',
      email: 'כתובת דוא"ל לא תקנית',
      oneOf: 'חייב להיות מאחת האפשרויות הבאות: %{options}',
      regex: 'חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}',
    },
  },
};
