import { createSelector } from 'reselect';

export const getSelectedTransactionsType = (state) => {
  return state.transactions.selectedType;
};

export const getTransactionIds = (state) => {
  return state.transactions.ids;
};

export const getTransactionEntities = (state) => {
  return state.transactions.entities;
};

export const getIsLoading = (state) => {
  return state.transactions.isLoading;
};

export const getIsExistMore = (state) => {
  return state.transactions.isExistMore;
};

export const getTransactions = createSelector(
  [getTransactionIds, getTransactionEntities],
  (ids, entities) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);

export const getTransactionsByType = createSelector(
  [getTransactions, getSelectedTransactionsType],
  (transactions, selectedType) => {
    return transactions.filter(({ type }) => {
      return type === selectedType;
    });
  },
);

export const getTransactionsCountByType = createSelector(
  [getTransactionsByType],
  (transactions) => {
    return transactions.length;
  },
);
