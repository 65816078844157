import {
  all,
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { compose } from 'ramda';
import rulesAPI from '../../api/rules';
import * as AT from './types';
import * as actions from './actions';
import { getSelectedRulesKind } from './selectors';
import { getSelectedCommunityId } from '../community/selectors';
import { setLoader } from '../ui/actions';
import { showErrorMessage } from '../ui/operations';
import { combineDataWithNormalize, formatCase } from '../../utils/store';

function* updateRules() {
  const selectedCommunityId = yield select(getSelectedCommunityId);
  const rulesRaw = yield call(rulesAPI.getRules, selectedCommunityId);

  const rules = compose(
    combineDataWithNormalize,
    formatCase.toCamelCase,
  )(rulesRaw);

  yield put(actions.getRulesSuccess(rules));
}

function* onGetRules() {
  yield put(setLoader(true));
  try {
    yield* updateRules();
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(setLoader(false));
  }
}

function* onReorderRule({ payload }) {
  const selectedCommunityId = yield select(getSelectedCommunityId);
  yield call(
    rulesAPI.reorderRule,
    formatCase.toSnakeCase({ communityId: selectedCommunityId, ...payload }),
  );

  yield put(actions.updateRuleSuccess({ id: payload.ruleId, order: payload.newOrder }));
  yield* updateRules();
}

function* onAddRule({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    const kind = yield select(getSelectedRulesKind);

    const newRule = yield call(
      rulesAPI.addRule,
      formatCase.toSnakeCase({ communityId: selectedCommunityId, kind, ...payload }),
    );

    yield put(actions.addRuleSuccess(formatCase.toCamelCase(newRule)));
    yield* updateRules();
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onUpdateRule({ payload }) {
  const selectedCommunityId = yield select(getSelectedCommunityId);
  const kind = yield select(getSelectedRulesKind);

  yield call(
    rulesAPI.updateRule,
    formatCase.toSnakeCase({ communityId: selectedCommunityId, kind, ...payload }),
  );

  const { ruleId: id, ...payloadRest } = payload;

  yield put(actions.updateRuleSuccess({ id, ...payloadRest }));
}

function* onRemoveRule({ payload: ruleId }) {
  try {
    const communityId = yield select(getSelectedCommunityId);

    yield call(rulesAPI.removeRule, communityId, ruleId);
    yield* updateRules();
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_RULES, onGetRules),
    takeEvery(AT.ADD_RULE, onAddRule),
    takeEvery(AT.UPDATE_RULE, onUpdateRule),
    takeEvery(AT.REORDER_RULE, onReorderRule),
    takeEvery(AT.REMOVE_RULE, onRemoveRule),
  ]);
}

