import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { isNil } from 'ramda';
import * as AT from './types';
import * as actions from './actions';
import { getSelectedCommunityId } from '../community/selectors';
import communityAPI from '../../api/community';
import {
  errors as validationErrors,
} from '../../constants/validation';
import { formatCase } from '../../utils/store';

function* onValidateCommunityInfoField({ payload }) {
  const { fieldId, value, minLength } = payload;

  if (isNil(value) || value.length < minLength) {
    yield put(actions.validateFieldError({
      fieldId,
      error: validationErrors.tooShort,
    }));
    return;
  }

  const communityId = yield select(getSelectedCommunityId);

  const { is_available: isAvailable } = yield call(
    communityAPI.verifyCommunityInfoField,
    formatCase.toSnakeCase({ fieldId, value, communityId }),
  );

  if (!isAvailable) {
    yield put(actions.validateFieldError({
      fieldId,
      error: validationErrors.notUnique,
    }));
  } else {
    yield put(actions.validateFieldSuccess(fieldId));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.VALIDATE_FIELD, onValidateCommunityInfoField),
  ]);
}
