import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button, { buttonProps } from './index';
import Popover, { popoverPositions } from '../Popover';

const ButtonWithPopover = ({
  children,
  title,
  id,
  theme,
  position,
  size,
  color,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(isOpen);
  };

  return (
    <>
      <Button
        id={id}
        theme={theme}
        size={size}
        color={color}
        onClick={toggleIsOpen}
        disabled={disabled}
        marginTop={false}
      >
        {title}
      </Button>
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={id}
        position={position}
        classNames="tooltip-inner"
      >
        {children}
      </Popover>
    </>
  );
};

ButtonWithPopover.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(buttonProps.themes)),
  position: PropTypes.oneOf(Object.values(popoverPositions)),
  size: PropTypes.oneOf(Object.values(buttonProps.sizes)),
  color: PropTypes.oneOf(Object.values(buttonProps.colors)),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

ButtonWithPopover.defaultProps = {
  children: null,
  theme: buttonProps.themes.link,
  position: popoverPositions.bottom,
  size: buttonProps.sizes.large,
  color: buttonProps.colors.null,
  disabled: false,
};

export default ButtonWithPopover;
