// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.GroupSelect_selectWrapper__AzSKC {\n  display: flex !important;\n  max-width: 200px;\n  height: 50px;\n}\n\n.GroupSelect_selectMenu__I-mTr {\n  width: 100%;\n  max-width: 200px;\n  overflow: hidden;\n}\n\n.GroupSelect_selectPlaceholder__chvD0 {\n  display: flex !important;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  color: #494A4C !important;\n  background-color: #F5F6F7 !important;\n  border: 1px solid #BFC0C2 !important;\n  font-weight: 600 !important;\n}\n\n.GroupSelect_caret__IRq7I {\n  font-size: 2rem !important;\n}\n\n.GroupSelect_emptyImage__umIuQ {\n  border-radius: 50%;\n  background-color: #798892;\n}", ""]);
// Exports
exports.locals = {
	"selectWrapper": "GroupSelect_selectWrapper__AzSKC",
	"selectMenu": "GroupSelect_selectMenu__I-mTr",
	"selectPlaceholder": "GroupSelect_selectPlaceholder__chvD0",
	"caret": "GroupSelect_caret__IRq7I",
	"emptyImage": "GroupSelect_emptyImage__umIuQ"
};
module.exports = exports;
