const prefix = 'sizes/';

export const SET_GROUP_ID = `${prefix}SET_GROUP_ID`;

export const SET_SIZES = `${prefix}SET_SIZES`;

export const ADD_SIZE = `${prefix}ADD_SIZE`;
export const ADD_SIZE_SUCCESS = `${prefix}ADD_SIZE_SUCCESS`;

export const UPDATE_SIZE = `${prefix}UPDATE_SIZE`;
export const UPDATE_SIZE_SUCCESS = `${prefix}UPDATE_SIZE_SUCCESS`;

export const TOGGLE_SIZE_ACTIVE = `${prefix}TOGGLE_SIZE_ACTIVE`;
