import React, { useState, useEffect } from 'react';
import { useLogin, useTranslate, useNotify, Notification } from 'react-admin';
import cls from 'classnames';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Container, Alert } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiPhoneNumber from 'material-ui-phone-number';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Fingerprint2 from 'fingerprintjs2';

import { Select, InputLabel, FormControl } from '@material-ui/core';
import Copyrights from '../../components/CopyRights';
import ConfirmationCodeInput from '../../components/ConfirmationCodeInput';
import InvalidPinDialog from './InvalidPinDialog';
import { IMAGES_URL } from '../../constants/Images';
import { ENTER_KEY } from '../../constants';

import loginAPI from '../../api/login';
import styles from './LoginPage.module.scss';
import Widget from '../../components/Widget';
import LocaleSelect from '../../components/LocaleSelect';
import LocaleProvider from '../../utils/localeProvider';

const LoginPage = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [fingerPrint, setFingerPrint] = useState(null);
  const [mobileNumber, setMobileNumber] = useState('+972549214539');
  const [appName, setAppName] = useState('shareitt');
  const [code, setCode] = useState(undefined);
  const [isOTPFieldActive, setOTPFieldActive] = useState(false);
  const [isSendActive, setSendActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidPinDialogState, setInvalidPinDialogState] = useState(false);

  const login = useLogin();

  useEffect(() => {
    Fingerprint2.get({}, (components) => {
      const values = components.map(({ value }) => value);
      setFingerPrint(Fingerprint2.x64hash128(values.join(''), 31));
    });
  }, []);

  const onHandleLogin = () => {
    setLoading(true);
    loginAPI
      .loginByMobileNumber({ phone_number: mobileNumber, app_name: appName })
      .then(() => {
        setOTPFieldActive(true);
        setLoading(false);
        notify('message.success.action.login.code_sent', 'info');
      })
      .catch((error) => {
        setLoading(false);
        const messageKey = `message.error.action.login.${error.message}`;
        notify(messageKey, 'error');
      });
  };

  const onPhoneInputKeyDown = (event) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();

      if (isSendActive) {
        onHandleLogin();
      }
    }
  };

  const onVerifyCode = () => {
    const loginInfo = {
      phone_number: mobileNumber,
      code,
      user_device_id: fingerPrint,
    };
    setLoading(true);
    login(loginInfo, '/').catch(() => {
      setInvalidPinDialogState(true);
    });
  };

  const onPhoneNumberChange = (value) => {
    const phoneNumber = parsePhoneNumberFromString(value);
    setSendActive(phoneNumber && phoneNumber.isValid());
    if (phoneNumber) {
      setMobileNumber(phoneNumber.number);
    }
  };

  const onCodeChange = (value) => {
    setCode(value);
  };

  const onDialogAction = (buttonIndex) => {
    setOTPFieldActive(buttonIndex !== 0);
    setLoading(buttonIndex !== 0);
    setCode(undefined);
    if (buttonIndex === 1) {
      onHandleLogin();
    }
  };

  return (
    <div className={styles.authPage}>
      <Container>
        <h5 className={styles.authLogo}>
          <img src={IMAGES_URL.LOGO} alt="Shareitt" width={240} height={80} />
        </h5>
        <Widget
          className={cls(styles.widgetAuth, 'mx-auto')}
          title={<h3 className="mt-0">{translate('pages.login.title')}</h3>}
        >
          <p className={styles.widgetAuthInfo}>
            <img
              src={!isOTPFieldActive ? IMAGES_URL.LOGIN : IMAGES_URL.CONFIRMATION}
              alt="login"
              width={120}
              height={65}
            />
          </p>
          <Alert className={cls(styles.authAlert, 'alert-sm', 'text-center')} color="secondary">
            {!isOTPFieldActive
              ? translate('general.alert.phone')
              : translate('general.alert.confirmation')}
          </Alert>
          <form className={cls(styles.authForm, 'mt')} autoComplete="off" noValidate>
            {!isOTPFieldActive ? (
              <>
                <FormControl variant="outlined" margin="normal" required fullWidth>
                  <InputLabel htmlFor="app_name">{translate('general.label.app_name')}</InputLabel>
                  <Select
                    native
                    label={translate('general.label.app_name')}
                    inputProps={{
                      name: 'app_name',
                      id: 'app_name',
                    }}
                    value={appName}
                    onChange={(value) => setAppName(value.target.value)}
                    required
                  >
                    <option value="shareitt">Shareitt</option>
                    <option value="floweavers">Shareitt Edu</option>
                  </Select>
                </FormControl>
                <MuiPhoneNumber
                  defaultCountry="il"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={mobileNumber || ''}
                  id="phone_number"
                  label={translate('general.label.phone_number')}
                  name="phone_number"
                  onChange={(value) => onPhoneNumberChange(value)}
                  onKeyDown={onPhoneInputKeyDown}
                  helperText={
                    mobileNumber && !isSendActive ? translate('message.error.invalid_mobile') : ' '
                  }
                  error={mobileNumber && !isSendActive}
                  disabled={loading}
                  autoFocus
                />
              </>
            ) : (
              <ConfirmationCodeInput value={code} onChange={(value) => onCodeChange(value)} />
            )}
            <Button
              disabled={
                !isSendActive ||
                loading ||
                (isOTPFieldActive && (!code || (code && code.length !== 4)))
              }
              onClick={isOTPFieldActive ? onVerifyCode : onHandleLogin}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              style={{ height: 50, margin: '24px 0' }}
            >
              {loading ? (
                <CircularProgress size={25} thickness={2} />
              ) : (
                translate(isOTPFieldActive ? 'general.button.confirm' : 'general.button.send')
              )}
            </Button>

            <Grid container direction="row" justify="space-between" alignItems="baseline">
              <Grid item>
                <LocaleSelect />
              </Grid>
              <Grid item>
                <Link href="/#"> {translate('general.label.help')} </Link>
              </Grid>
              <Grid item>
                <Link href="/#"> {translate('general.label.privacy')} </Link>
              </Grid>
              <Grid item>
                <Link href="/#"> {translate('general.label.terms')} </Link>
              </Grid>
            </Grid>
          </form>
        </Widget>
      </Container>
      <footer className={styles.authFooter}>
        <Box mt={8}>
          <Copyrights />
        </Box>
        <Notification />
        {invalidPinDialogState && <InvalidPinDialog onDialogAction={onDialogAction} />}
      </footer>
      <LocaleProvider />
    </div>
  );
};
export default LoginPage;
