import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import Modal from '../../components';
import * as actions from '../../../store/members/actions';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';

const SuspiciousModal = ({ options, closeModal, markAsSuspicious, unmarkAsSuspicious }) => {
  const translate = useTranslate();
  const [reason, setReason] = React.useState('');

  const isSaveButtonDisabled = !options.isMarkedAsSuspicious && !reason;

  const onSaveClick = () => {
    const onSaveFunc = options.isMarkedAsSuspicious
      ? unmarkAsSuspicious
      : markAsSuspicious;

    const data = options.isMarkedAsSuspicious
      ? { id: options.id }
      : { id: options.id, reason };

    onSaveFunc(data);
    closeModal();
  };

  return (
    <>
      <Modal.Header>
        {options.isMarkedAsSuspicious
          ? translate('pages.memberInfo.modals.title.suspicious.delete')
          : translate('pages.memberInfo.modals.title.suspicious.add')
        }
      </Modal.Header>

      {!options.isMarkedAsSuspicious && (
        <Modal.Body>
          <EditableTextarea
            value={reason}
            onInputChange={setReason}
            type={inputTypes.textarea}
            label={translate('pages.memberInfo.modals.inputs.labels.reason')}
            id="suspiciousReason"
            showButtons={false}
          />
        </Modal.Body>
      )}

      <Modal.Footer
        onSave={onSaveClick}
        isSaveButtonDisabled={isSaveButtonDisabled}
        onCancel={closeModal}
      />
    </>
  );
};

SuspiciousModal.propTypes = {
  options: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isMarkedAsSuspicious: PropTypes.bool,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  markAsSuspicious: PropTypes.func.isRequired,
  unmarkAsSuspicious: PropTypes.func.isRequired,
};

export default connect(null, {
  markAsSuspicious: actions.markMemberAsSuspicious,
  unmarkAsSuspicious: actions.unmarkMemberAsSuspicious,
})(SuspiciousModal);
