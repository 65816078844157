import { createAction } from 'redux-actions';
import * as AT from './types';

export const getCommunityItems = createAction(AT.GET_COMMUNITY_ITEMS);
export const getCommunityItemsSuccess = createAction(AT.GET_COMMUNITY_ITEMS_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);

export const hideItem = createAction(AT.HIDE_ITEM);
export const hideItemSuccess = createAction(AT.HIDE_ITEM_SUCCESS);
