import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import * as memberActions from '../../store/members/actions';
import * as memberSelectors from '../../store/members/selectors';
import { memberTypes } from '../../constants/members';
import PageContent from '../../components/PageContent';
import TabsPanel from '../../components/TabsAccordion/TabsPanel';
import TabsContent from '../../components/TabsAccordion/TabsContent';
import MembersTable from './MembersTable';

const CommunityMembers = ({
  items,
  isLoading,
  filterType,
  getMembers,
  getSuspensions,
  getSuspicions,
  setMemberFilterType,
}) => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const makeGetItemsFunc = () => {
    if (filterType === memberTypes.SUSPENDED) {
      return getSuspensions;
    }

    if (filterType === memberTypes.SUSPICIOUS) {
      return getSuspicions;
    }

    return getMembers;
  };

  const getItems = makeGetItemsFunc();

  useEffect(() => {
    if (queryParams.type) {
      setMemberFilterType(queryParams.type);
    } else {
      getItems({ isLoadMore: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getItems({ isLoadMore: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  const tabOptions = [
    {
      value: memberTypes.ALL,
      label: translate('pages.members.labels.types.all'),
    },
    {
      value: memberTypes.SUSPICIOUS,
      label: translate('pages.members.labels.types.suspicious'),
    },
    {
      value: memberTypes.SUSPENDED,
      label: translate('pages.members.labels.types.suspended'),
    },
  ];

  const onTabClick = (value) => {
    setMemberFilterType(value);
  };

  return (
    <PageContent
      title={translate('pages.members.title.page')}
    >
      <TabsPanel options={tabOptions} activeTab={filterType} onTabClick={onTabClick} />

      <TabsContent>
        <MembersTable
          items={items}
          getItems={getItems}
          type={filterType}
          isLoading={isLoading}
          searchTerm={queryParams.searchTerm}
        />
      </TabsContent>
    </PageContent>
  );
};

CommunityMembers.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  filterType: PropTypes.oneOf(Object.values(memberTypes)).isRequired,

  getMembers: PropTypes.func.isRequired,
  getSuspensions: PropTypes.func.isRequired,
  getSuspicions: PropTypes.func.isRequired,
  setMemberFilterType: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      items: memberSelectors.getItemsByType(state),
      isLoading: memberSelectors.getIsLoading(state),
      filterType: memberSelectors.getMemberFilterType(state),
    };
  }, {
    getMembers: memberActions.getCommunityMembers,
    getSuspensions: memberActions.getCommunitySuspensions,
    getSuspicions: memberActions.getCommunitySuspicions,
    setMemberFilterType: memberActions.setMemberFilterType,
  },
)(CommunityMembers);
