import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/cities';
import { showErrorMessage } from '../ui/operations';
import { normalize } from '../../utils/store';
import {
  ITEMS_PAGE_LIMIT,
  DEFAULT_SEARCH_TERM, ITEMS_INITIAL_LIMIT,
} from '../../constants/items';

function* onGetCities({ payload = {} }) {
  yield put(actions.setIsLoading(true));
  try {
    const {
      isLoadMore = true,
      searchTerm = DEFAULT_SEARCH_TERM,
      nextPage = 0,
    } = payload;

    const currentCitiesOffset = yield select(selectors.getCitiesOffset);
    const isExistMore = yield select(selectors.getCitiesExistMore);
    const isNeedToFetchData = currentCitiesOffset <= (nextPage * ITEMS_PAGE_LIMIT);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const skip = isLoadMore
      ? currentCitiesOffset
      : 0;

    const limit = skip === 0
      ? ITEMS_INITIAL_LIMIT
      : ITEMS_PAGE_LIMIT;

    const cities = yield call(api.getCities, { offset: skip, limit, searchTerm });
    const hasMore = cities.length === limit;

    const normalizedItems = normalize(cities);

    yield put(actions.getCitiesSuccess({
      ...normalizedItems,
      isLoadMore,
      isExistMore: hasMore,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onDeleteCity({ payload: cityId }) {
  yield put(actions.setIsLoading(true));
  try {
    yield call(api.deleteCity, { cityId });
    yield put(actions.removeCitySuccess({ cityId }));
    yield put(showNotification('message.success.action.cities.removed'));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onEditCityImage({ payload: { data, info } }) {
  yield put(actions.setIsLoading(true));
  try {
    yield call(api.updateCityImage, data);
    yield put(actions.editCityImageSuccess({ cityId: info.cityId, image: info.image }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_CITIES, onGetCities),
    takeEvery(AT.REMOVE_CITY, onDeleteCity),
    takeEvery(AT.EDIT_CITY_IMAGE, onEditCityImage),
  ]);
}
