import moment from 'moment';
import { equals } from 'ramda';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_RANGE_IN_DAYS = 7;

export const getInitialDateRanges = () => {
  const endDate = moment().format(DEFAULT_DATE_FORMAT);
  const startDate = moment(endDate)
    .subtract(DEFAULT_DATE_RANGE_IN_DAYS, 'days')
    .format(DEFAULT_DATE_FORMAT);

  return { startDate, endDate };
};

export const fromDateToString = (date) => {
  return moment(date).format(DEFAULT_DATE_FORMAT);
};

export const fromStringToDate = (dateString) => {
  return new Date(dateString);
};

export const dateFormatter = {
  dateToString: fromDateToString,
  stringToDate: fromStringToDate,
};

export const getIsDateRangeUpdated = (initialRange, currentRange) => {
  return !equals(
    initialRange,
    {
      startDate: fromDateToString(currentRange.startDate),
      endDate: fromDateToString(currentRange.endDate),
    },
  );
};

export const getIsSameDate = (startDate, endDate) => {
  return fromDateToString(startDate) === fromDateToString(endDate);
};

export const getAmountOfDaysFromDateToNow = (date) => {
  return moment().diff(moment(date).format(DEFAULT_DATE_FORMAT), 'days');
};
