import { createSelector } from 'reselect';
import { getParamWithoutStateInSelector } from '../../utils/store';
import { getMemberStatus } from '../../utils/members';
import { memberTypes } from '../../constants/members';

export const getMemberIds = (state) => {
  return state.members.ids;
};

export const getMemberEntities = (state) => {
  return state.members.entities;
};

export const getMemberById = createSelector(
  [getMemberEntities, getParamWithoutStateInSelector],
  (entities, id) => {
    return entities[id];
  },
);

export const getIsExistMore = (state) => {
  return state.members.isExistMore;
};

export const getIsLoading = (state) => {
  return state.members.isLoading;
};

export const getMembers = createSelector(
  [getMemberIds, getMemberEntities],
  (ids, entities) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);

export const getMembersForTable = createSelector(
  [getMembers],
  (members) => {
    return members.map((member) => {
      return {
        ...member,
        status: getMemberStatus(member),
      };
    });
  },
);

export const getMembersForSelect = createSelector(
  [getMembers],
  (members) => {
    return members.map(({ id, name, nickname, profileImage }) => {
      const label = nickname
        ? `${name} (@${nickname})`
        : name;

      return {
        value: id,
        image: profileImage,
        label,
      };
    });
  },
);

export const getMembersOffset = createSelector(
  [getMemberIds],
  (ids) => {
    return ids.length;
  },
);

export const getMemberFilterType = (state) => {
  return state.members.filterType;
};

export const getMemberSuspensions = (state) => {
  return state.members.suspensions;
};

export const getMemberSuspicions = (state) => {
  return state.members.suspicions;
};

export const getItemsByType = createSelector(
  [
    getMembersForTable,
    getMemberSuspensions,
    getMemberSuspicions,
    getMemberFilterType,
  ],
  (members, suspensions, suspicions, filterType) => {
    if (filterType === memberTypes.SUSPENDED) {
      return suspensions;
    }

    if (filterType === memberTypes.SUSPICIOUS) {
      return suspicions;
    }

    return members;
  },
);
