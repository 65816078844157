import { all, takeEvery, select, call, put } from 'redux-saga/effects';
import { compose, isEmpty } from 'ramda';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/reports';
import { getSelectedCommunityId } from '../community/selectors';
import {
  REPORTS_INITIAL_LIMIT,
  REPORTS_LIMIT,
  initialSearchTerm,
  REPORT_TABS,
} from '../../constants/reports';
import { combineDataWithNormalize, formatCase, normalize } from '../../utils/store';
import { updateReportIds } from '../../utils/reports';
import format from 'date-fns/format';

function* onReportsStart({ payload }) {
  const { type, searchTerm = initialSearchTerm, isLoadMore = true, nextPage = 0 } = payload;

  const isExistMore = yield select(selectors.getIsReportsExistMoreByType, type);
  const isLoading = yield select(selectors.getIsReportsLoadingByType, type);
  const currentReportsCount = yield select(selectors.getReportsOffsetByType, type);
  const isNeedToFetchData = currentReportsCount <= nextPage * REPORTS_LIMIT;

  if (isLoading || (isLoadMore && (!isExistMore || !isNeedToFetchData))) {
    return;
  }

  yield put(actions.setIsLoading({ isLoading: true, type }));

  const communityId = yield select(getSelectedCommunityId);
  const offset = isLoadMore ? currentReportsCount : 0;

  const limit = offset === 0 ? REPORTS_INITIAL_LIMIT : REPORTS_LIMIT;

  const reports = yield call(api.getReports, {
    communityId,
    type,
    offset,
    limit,
    searchTerm: formatCase.toSnakeCase(searchTerm),
  });
  const hasMore = reports.length === limit;

  const normalizedReports = normalize(reports);

  yield put(
    actions.reportsSuccess({
      ...normalizedReports,
      isExistMore: hasMore,
      type,
      isLoadMore,
    }),
  );

  yield put(actions.setIsLoading({ isLoading: false, type }));
}

function* onReportersStart() {
  const communityId = yield select(getSelectedCommunityId);

  const reporters = yield call(api.getReporters, communityId);

  const normalizedReporters = compose(
    combineDataWithNormalize,
    updateReportIds,
    formatCase.toCamelCase,
  )(reporters);

  yield put(actions.reportersSuccess(normalizedReporters));
}


function* onDailyReportStart() {
  yield put(actions.dailyReportLoading(true));
  const dailyReport = yield call(api.getDailyReport);

  const summaryStatistics = dailyReport['1'] || [];
  const activeUsers = dailyReport['3'] || [];
  const registeredNewUsers = dailyReport['4'] || [];
  const uniqueParticipants = dailyReport['5'] || [];
  const weeklyReport = dailyReport['6'] || [];
  const totalItemsUploaded = dailyReport['7'] || [];

  const SummaryStatisticsTypes = [
    'Members',
    'Givers',
    'Receivers',
    'Verified Members',
    'Available In Market',
    'Exchanged',
    'Refunded',
    'Shipped',
    'Uploaded',
    'Active Tokens',
    'Issued Tokens',
  ];

  const summaryStatisticsByType = summaryStatistics?.reduce((acc, item) => {
    const key = item.type.replace(/\s+/g, '').toLowerCase();

    if (SummaryStatisticsTypes.includes(item.type)) {
      if (!acc[key]) {
        acc[key] = {
          count: null,
          value: null,
        };
      }

      if (item.measure === 'Count') {
        acc[key].count = item.number;
      } else if (item.measure === 'Value') {
        acc[key].value = item.number;
      }
    }

    return acc;
  }, {});

  const uniqueParticipantsData = Object.keys(uniqueParticipants)?.reduce((acc, key) => {
    const item = uniqueParticipants[key];

    const dateFormatted = format(new Date(item.date), 'MMM dd');
    const existingEntry = acc.find(entry => entry.date === dateFormatted);

    const uniqueSeller = Number(item.unique_seller) || 0;
    const uniqueUploader = Number(item.unique_uploader) || 0;
    const uniqueBuyer = Number(item.unique_buyer) || 0;

    if (existingEntry) {
      existingEntry[item.type] = {
        unique_seller: (Number(existingEntry[item.type]?.unique_seller) || 0) + uniqueSeller,
        unique_uploader: (Number(existingEntry[item.type]?.unique_uploader) || 0) + uniqueUploader,
        unique_buyer: (Number(existingEntry[item.type]?.unique_buyer) || 0) + uniqueBuyer,
      };
    } else {
      acc.push({
        date: dateFormatted,
        [item.type]: {
          unique_seller: uniqueSeller,
          unique_uploader: uniqueUploader,
          unique_buyer: uniqueBuyer,
        }
      });
    }

    return acc;
  }, []);

  const weeklyReportData = Object.keys(weeklyReport)?.reduce((acc, key) => {
    const item = weeklyReport[key];

    const dateFormatted = format(new Date(item.week_start), 'MMM dd');
    const existingEntry = acc.find(entry => entry.week_start === dateFormatted);

    const uniqueSeller = Number(item.unique_seller) || 0;
    const uniqueBuyer = Number(item.unique_buyer) || 0;

    if (existingEntry) {
      existingEntry[item.type] = {
        unique_seller: (Number(existingEntry[item.type]?.unique_seller) || 0) + uniqueSeller,
        unique_buyer: (Number(existingEntry[item.type]?.unique_buyer) || 0) + uniqueBuyer,
      };
    } else {
      acc.push({
        date: dateFormatted,
        [item.type]: {
          unique_seller: uniqueSeller,
          unique_buyer: uniqueBuyer,
        }
      });
    }

    return acc;
  }, []);

  const formattedDailyReport = {
    summaryStatistics: summaryStatisticsByType,
    activeUsers,
    registeredNewUsers,
    uniqueParticipants: uniqueParticipantsData,
    weeklyReport: weeklyReportData,
    totalItemsUploaded,
  };

  yield put(actions.dailyReportSuccess(formattedDailyReport));
  yield put(actions.dailyReportLoading(false));
}

function* onReportedUsersStart() {
  const communityId = yield select(getSelectedCommunityId);

  const reportedUsers = yield call(api.getReportedUsers, communityId);

  const normalizedReportedUsers = compose(
    combineDataWithNormalize,
    updateReportIds,
    formatCase.toCamelCase,
  )(reportedUsers);

  yield put(actions.reportedUsersSuccess(normalizedReportedUsers));
}

function* onTopReportsStart() {
  const communityId = yield select(getSelectedCommunityId);

  const topReports = yield call(api.getTopReports, communityId);

  yield put(actions.topReportsSuccess(formatCase.toCamelCase(topReports)));
}

function* onSetReportsTab({ payload: type }) {
  switch (type) {
    case REPORT_TABS.TOP_REPORTS: {
      const topReportsData = yield select(selectors.getTopReports);

      if (isEmpty(topReportsData)) {
        yield put(actions.topReportsStart());
      }

      break;
    }

    case REPORT_TABS.REPORTERS: {
      yield put(actions.reportersStart());
      break;
    }

    case REPORT_TABS.REPORTED_USERS: {
      yield put(actions.reportedUsersStart());
      break;
    }

    case REPORT_TABS.USER:
    case REPORT_TABS.ITEM: {
      yield put(actions.reportsStart({ type }));
      break;
    }

    default:
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.REPORTS_START, onReportsStart),
    takeEvery(AT.REPORTERS_START, onReportersStart),
    takeEvery(AT.REPORTED_USERS_START, onReportedUsersStart),
    takeEvery(AT.TOP_REPORTS_START, onTopReportsStart),
    takeEvery(AT.SET_REPORTS_TAB, onSetReportsTab),
    takeEvery(AT.DAILY_REPORT_START, onDailyReportStart),
  ]);
}
