import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import * as AT from './types';
import {
  UPDATE_COMMUNITY_FEATURES_SUCCESS,
  UPDATE_GROUP_FEATURES_SUCCESS,
} from '../features/types';
import { deepMerge } from '../../utils/store';
import { getInitialDateRanges } from '../../utils/moment';

const initialState = {
  selectedCommunityId: null,
  selectedGroupId: null,
  communityEntities: {},
  communityIds: [],
  analytics: {
    dateRange: getInitialDateRanges(),
    data: {},
  },
  groupEntities: {},
  groupIds: [],
};

const reducer = handleActions({
  [AT.SET_COMMUNITY_ID]: deepMerge(({ payload }) => {
    return {
      selectedCommunityId: payload,
    };
  }),
  [AT.SET_GROUP_ID]: deepMerge(({ payload }) => {
    return {
      selectedGroupId: payload,
    };
  }),
  [AT.GET_COMMUNITIES_SUCCESS]: deepMerge(({ payload }) => {
    return {
      communityEntities: payload.communityEntities,
      communityIds: payload.communityIds,
    };
  }),
  [AT.GET_COMMUNITY_GROUPS_SUCCESS]: deepMerge(({ payload }) => {
    return {
      groupEntities: payload.groupEntities,
      groupIds: payload.groupIds,
    };
  }),
  [AT.GET_COMMUNITY_ANALYTICS]: (state, { payload }) => {
    return {
      ...state,
      analytics: {
        dateRange: payload || initialState.analytics.dateRange,
        data: {},
      },
    };
  },
  [AT.GET_COMMUNITY_ANALYTICS_SUCCESS]: deepMerge(({ payload }) => {
    return {
      analytics: {
        data: payload,
      },
    };
  }),
  [AT.UPDATE_COMMUNITY_INFO_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      communityEntities: {
        [state.selectedCommunityId]: payload,
      },
    };
  }),
  [AT.UPDATE_GROUP_INFO_SUCCESS]: deepMerge(({ payload }, state) => {
    return {
      groupEntities: {
        [state.selectedGroupId]: payload,
      },
    };
  }),
  [UPDATE_COMMUNITY_FEATURES_SUCCESS]: deepMerge(({ payload }) => {
    return {
      communityEntities: {
        [payload.id]: {
          communitySettings: payload.patch,
        },
      },
    };
  }),
  [UPDATE_GROUP_FEATURES_SUCCESS]: deepMerge(({ payload }) => {
    return {
      groupEntities: {
        [payload.id]: payload.patch,
      },
    };
  }),
  [CLEAR_STATE]: () => {
    return initialState;
  },
}, initialState);

export default reducer;
