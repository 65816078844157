import { createAction } from 'redux-actions';
import * as AT from './types';

export const getCommunityMembers = createAction(AT.GET_COMMUNITY_MEMBERS);
export const getCommunityMembersSuccess = createAction(AT.GET_COMMUNITY_MEMBERS_SUCCESS);

export const getCommunitySuspensions = createAction(AT.GET_COMMUNITY_SUSPENSIONS);
export const getCommunitySuspensionsSuccess = createAction(AT.GET_COMMUNITY_SUSPENSIONS_SUCCESS);

export const getCommunitySuspicions = createAction(AT.GET_COMMUNITY_SUSPICIONS);
export const getCommunitySuspicionsSuccess = createAction(AT.GET_COMMUNITY_SUSPICIONS_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);

export const getCommunityMemberInfo = createAction(AT.GET_COMMUNITY_MEMBER_INFO);
export const getCommunityMemberInfoRequest = createAction(AT.GET_COMMUNITY_MEMBER_INFO_REQUEST);
export const getCommunityMemberInfoSuccess = createAction(AT.GET_COMMUNITY_MEMBER_INFO_SUCCESS);

export const suspendMember = createAction(AT.SUSPEND_MEMBER);
export const unsuspendMember = createAction(AT.UNSUSPEND_MEMBER);
export const markMemberAsSuspicious = createAction(AT.MARK_MEMBER_AS_SUSPICIOUS);
export const unmarkMemberAsSuspicious = createAction(AT.UNMARK_MEMBER_AS_SUSPICIOUS);

export const setMemberFilterType = createAction(AT.SET_MEMBER_FILTER_TYPE);

export const toggleMemberShipperStatus = createAction(AT.TOGGLE_MEMBER_SHIPPER_STATUS);

export const cancelItemArrival = createAction(AT.CANCEL_ITEM_ARRIVAL);

export const promoteToAdmin = createAction(AT.PROMOTE_TO_ADMIN);
