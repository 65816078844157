import { createSelector } from 'reselect';
import {
  sortByProp,
  getParamWithoutStateInSelector,
  convertStringToNumber,
} from '../../utils/store';

export const getSelectedGroupId = (state) => {
  return state.categories.groupId;
};

export const getCategoryEntities = (state) => {
  return state.categories.categoryEntities;
};

export const getCategoryIds = (state) => {
  return state.categories.categoryIds;
};

export const getCategories = createSelector(
  [getCategoryIds, getCategoryEntities],
  (ids, entities) => {
    return ids.map((id) => {
      const entity = entities[id];

      return {
        ...entity,
        parentId: convertStringToNumber(entity.parentId),
      };
    });
  },
);

export const getCategoryById = createSelector(
  [getCategoryEntities, getParamWithoutStateInSelector],
  (entities, id) => {
    return entities[id];
  },
);

export const getParentCategories = createSelector(
  [getCategories],
  (categories) => {
    const filteredCategories = categories.filter(({ parentId }) => {
      return parentId === null;
    });

    return sortByProp('order')(filteredCategories);
  },
);

export const getNextParentCategoryOrder = createSelector(
  [getParentCategories],
  (categories) => {
    return categories.length;
  },
);

export const getChildCategoriesById = createSelector(
  [getCategories, getParamWithoutStateInSelector],
  (categories, id) => {
    const filteredCategories = categories.filter(({ parentId }) => {
      return parentId === id;
    });

    return sortByProp('order')(filteredCategories);
  },
);

export const getNextChildCategoryOrderById = createSelector(
  [getChildCategoriesById],
  (categories) => {
    return categories.length;
  },
);
