import React from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTranslate } from 'react-admin';
import styles from './NewRegisterUsers.module.scss';
import { getRegisteredNewUsers } from '../../../../../store/reports/selectors';

const NewRegisterUsers = ({ registeredUsers }) => {
  const translate = useTranslate();

  const registeredNewUsers = registeredUsers?.map(item => {
    const dateFormatted = format(new Date(item.date), 'MMM dd');

    return {
      date: dateFormatted,
      new_users: item.number
    };
  });

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{translate('pages.reports.new_registered_users')}</h1>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={registeredNewUsers}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="new_users" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};



export default connect((state) => {
  return {
    registeredUsers: getRegisteredNewUsers(state),
  };
})(NewRegisterUsers);
