import * as R from 'ramda';
import _deepmerge from 'deepmerge';
import {
  camelCase as toCamelCase,
  snakeCase as toSnakeCase,
} from 'change-case-object';

export const formatCase = { toSnakeCase, toCamelCase };

export const normalize = (
  data,
  idsKey = 'ids',
  entitiesKey = 'entities',
) => {
  const formattedData = formatCase.toCamelCase(data);

  const entities = R.indexBy(R.prop('id'), formattedData);
  const ids = formattedData.map(({ id }) => {
    return id;
  });

  return {
    [entitiesKey]: entities,
    [idsKey]: ids,
  };
};

export const sortByProp = (prop) => {
  return R.sortBy(R.prop(prop));
};

export const combineDataWithNormalize = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const idsKey = `${key}Ids`;
    const normalized = normalize(obj[key], idsKey);

    return {
      ...acc,
      entities: {
        ...acc.entities,
        ...normalized.entities,
      },
      [idsKey]: normalized[idsKey],
    };
  }, {});
};

export const getIsListsEqual = (listFromState, listFromProps, excludedValues = []) => {
  const [oldList, newList] = [listFromState, listFromProps].map((list) => {
    return list.map((item) => {
      return R.omit(excludedValues, item);
    });
  });

  // need to sort lists by unique value
  // because R.equals will return `true` if elements in list are same, but in different order
  return R.equals(
    sortByProp('id')(oldList),
    sortByProp('id')(newList),
  );
};

export const getParamWithoutStateInSelector = (_, param) => {
  return param;
};

export const convertStringToNumber = (prop) => {
  return R.isNil(prop)
    ? prop
    : parseInt(prop, 10);
};

const processChange = (change, state, action) => {
  return typeof change === 'object'
    ? change
    : change(action, state);
};

const returnSourceInArrayMerge = (destination, source) => {
  return source;
};

export const deepMerge = (change) => {
  return (state, action) => {
    return {
      ..._deepmerge(
        state,
        processChange(change, state, action),
        {
          arrayMerge: returnSourceInArrayMerge,
        },
      ),
    };
  };
};
