import api from './Api';

const updateCommunityFeatures = (data) => {
  return api.put('/admin/updateCommunitySettings', data);
};

const updateGroupFeatures = (data) => {
  return api.put('/admin/updateFunctionalitySettings', data);
};

export default {
  updateCommunityFeatures,
  updateGroupFeatures,
};
