export const ITEMS_INITIAL_LIMIT = 75;
export const ITEMS_PAGE_LIMIT = 25;
export const DEFAULT_SEARCH_TERM = '';
export const SEARCH_DELAY_TIME = 300;

export const itemStatuses = {
  ACTIVE: 'active',
  SOLD: 'sold',
  PENDING: 'pending',
  HIDDEN: 'hidden',
  REMOVED: 'removed',
};
