import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNowStrict';
import formatDistance from 'date-fns/formatDistance';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { enUS as en, he } from 'date-fns/locale';

const TABLE_DATE_FORMAT = 'dd MMM yyyy HH:mm';

const localeMap = { en, he };

export const formatDateWithLocale = (dateString, locale, dateFormat = TABLE_DATE_FORMAT) => {
  if (!dateString) {
    return null;
  }

  return format(
    new Date(dateString),
    dateFormat,
    { locale: localeMap[locale] },
  );
};

export const getDistanceFromDateToNow = (dateString, locale) => {
  if (!dateString) {
    return null;
  }

  return formatDistanceToNow(
    new Date(dateString),
    { locale: localeMap[locale], addSuffix: true },
  );
};

export const getDistanceBetweenDates = (currentDate, baseDate, locale) => {
  if (!currentDate || !baseDate) {
    return null;
  }

  return formatDistance(
    new Date(currentDate),
    new Date(baseDate),
    { locale: localeMap[locale], addSuffix: false, unit: 'minute' },
  );
};

export const getDifferenceInMinutes = (currentDate, baseDate) => {
  if (!currentDate || !baseDate) {
    return null;
  }

  return differenceInMinutes(
    new Date(currentDate),
    new Date(baseDate),
  );
};
