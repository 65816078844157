import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import HighChartsReact from 'highcharts-react-official';
import getIsRTL from '../../utils/isRTL';
import { getItemsLineChartOptions, getMembersLineChartOptions } from '../../utils/charts';
import { LINE_CHART_TYPES } from '../../constants';
import Widget from '../Widget';
import styles from './Chart.module.scss';

const LineChart = ({
  data,
  type,
  title,
  isLoading,
}) => {
  const isRTL = getIsRTL();

  const translate = useTranslate();
  const itemsChartTitles = [
    translate('general.label.uploaded'),
    translate('general.label.sold'),
  ];

  const chartOptions = type === LINE_CHART_TYPES.ITEMS
    ? getItemsLineChartOptions(data, itemsChartTitles, isRTL)
    : getMembersLineChartOptions(data, translate('general.label.joined'), isRTL);

  return (
    <Widget
      title={title}
      fetchingData={isLoading}
      className={styles.chartWrapper}
    >
      <HighChartsReact options={chartOptions} />
    </Widget>
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  type: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isLoading: PropTypes.bool.isRequired,
};

LineChart.defaultProps = {
  title: null,
  type: LINE_CHART_TYPES.ITEMS,
};

export default LineChart;
