import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import * as R from 'ramda';
import featuresAPI from '../../api/features';
import * as AT from './types';
import * as actions from './actions';
import * as featuresSelectors from './selectors';
import { SET_COMMUNITY_ID } from '../community/types';
import { getGroupById, getSelectedCommunityId, getCommunityById } from '../community/selectors';
import { showErrorMessage } from '../ui/operations';
import { formatCase } from '../../utils/store';
import { groupFeaturesKeys, communityFeaturesKeys } from '../../constants';

function* onSetCommunityId({ payload: communityId }) {
  const community = yield select(getCommunityById, communityId);
  const communityFeatures = R.pick(communityFeaturesKeys, community.communitySettings);

  yield put(actions.setCommunityFeatures(communityFeatures));
}

function* onSetCurrentGroupId({ payload: id }) {
  const group = yield select(getGroupById, id);
  const groupFeatures = R.pick(groupFeaturesKeys, group);

  yield put(actions.setGroupFeatures(groupFeatures));
}

function* onUpdateGroupFeatures({ payload: patch }) {
  try {
    const groupId = yield select(featuresSelectors.getCurrentGroupId);

    yield call(featuresAPI.updateGroupFeatures, formatCase.toSnakeCase({
      groupId,
      ...patch,
    }));

    yield put(actions.updateGroupFeaturesSuccess({ id: groupId, patch }));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

function* onUpdateCommunityFeatures({ payload: patch }) {
  try {
    const communityId = yield select(getSelectedCommunityId);

    yield call(featuresAPI.updateCommunityFeatures, formatCase.toSnakeCase({
      communityId,
      ...patch,
    }));

    yield put(actions.updateCommunityFeaturesSuccess({ id: communityId, patch }));
  } catch (error) {
    yield* showErrorMessage(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(SET_COMMUNITY_ID, onSetCommunityId),
    takeEvery(AT.SET_CURRENT_GROUP_ID, onSetCurrentGroupId),
    takeEvery(AT.UPDATE_GROUP_FEATURES, onUpdateGroupFeatures),
    takeEvery(AT.UPDATE_COMMUNITY_FEATURES, onUpdateCommunityFeatures),
  ]);
}
