import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import api from '../../api/knowledgeLibrary';
import { getSelectedCommunityId } from '../community/selectors';
import * as AT from './types';
import * as actions from './actions';


function* onGetPosts() {
    try {
        const selectedCommunityId = yield select(getSelectedCommunityId);
        const posts = yield call(api.getPosts, selectedCommunityId);
        yield put(actions.getPostsSuccess(posts));
    } catch (error) {
        yield put(showNotification('Error fetching posts', 'error'));
    }
}

function* onApprovePost(id) {
    try {
        yield call(api.approvePost, id);
        yield put(actions.approvePostSuccess());
    } catch (error) {
        yield put(showNotification('Error approving post', 'error'));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(AT.GET_POSTS, onGetPosts),
        takeEvery(AT.APPROVE_POST, onApprovePost),
    ])
}