import { createSelector } from 'reselect';

export const getCommunityFeatures = (state) => {
  return state.features.community;
};

export const getGroupFeatures = (state) => {
  return state.features.group;
};

export const getCurrentGroupId = (state) => {
  return state.features.currentGroupId;
};

const getFeaturesForListFactory = (selector) => {
  return createSelector(
    [selector],
    (features) => {
      return Object.keys(features).map((key) => {
        return {
          id: key,
          enabled: features[key],
        };
      });
    },
  );
};

export const getCommunityFeaturesForList = getFeaturesForListFactory(getCommunityFeatures);
export const getGroupFeaturesForList = getFeaturesForListFactory(getGroupFeatures);
