import { createSelector } from 'reselect';

export const getTalentIds = (state) => {
  return state.talents.ids;
};

export const getTalentEntities = (state) => {
  return state.talents.entities;
};

export const getTalents = createSelector(
  [getTalentIds, getTalentEntities],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export const getTalentsExistMore = (state) => {
  return state.items.isExistMore;
};

export const getIsTalentsLoading = (state) => {
  return state.items.isLoading;
};

export const getTalentsOffset = createSelector(
  [getTalentIds],
  (ids) => {
    return ids.length;
  },
);
