const prefix = 'transactions/';

export const GET_TRANSACTIONS = `${prefix}GET_TRANSACTIONS`;
export const GET_TRANSACTIONS_SUCCESS = `${prefix}GET_TRANSACTIONS_SUCCESS`;

export const SET_TRANSACTIONS_TYPE = `${prefix}SET_TRANSACTIONS_TYPE`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;

export const CREATE_ISSUE_TRANSACTION = `${prefix}CREATE_ISSUE_TRANSACTION`;

export const DECLINE_ITEM_REQUEST = `${prefix}DECLINE_ITEM_REQUEST`;
export const DECLINE_ITEM_REQUEST_SUCCESS = `${prefix}DECLINE_ITEM_REQUEST_SUCCESS`;

export const PERFORM_REFUND = `${prefix}PERFORM_REFUND`;
export const PERFORM_REFUND_SUCCESS = `${prefix}PERFORM_REFUND_SUCCESS`;
