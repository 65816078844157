import { createAction } from 'redux-actions';
import * as AT from './types';

export const setGroupId = createAction(AT.SET_GROUP_ID);

export const setConditions = createAction(AT.SET_CONDITIONS);

export const addCondition = createAction(AT.ADD_CONDITION);
export const addConditionSuccess = createAction(AT.ADD_CONDITION_SUCCESS);

export const updateCondition = createAction(AT.UPDATE_CONDITION);
export const updateConditionSuccess = createAction(AT.UPDATE_CONDITION_SUCCESS);

export const toggleConditionActive = createAction(AT.TOGGLE_CONDITION_ACTIVE);
