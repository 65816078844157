import { createAction } from 'redux-actions';
import * as AT from './types';

export const setGroupId = createAction(AT.SET_GROUP_ID);

export const setSizes = createAction(AT.SET_SIZES);

export const addSize = createAction(AT.ADD_SIZE);
export const addSizeSuccess = createAction(AT.ADD_SIZE_SUCCESS);

export const updateSize = createAction(AT.UPDATE_SIZE);
export const updateSizeSuccess = createAction(AT.UPDATE_SIZE_SUCCESS);

export const toggleSizeActive = createAction(AT.TOGGLE_SIZE_ACTIVE);
