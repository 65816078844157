import { createSelector } from 'reselect';

export const getItemIds = (state) => {
  return state.items.ids;
};

export const getItemEntities = (state) => {
  return state.items.entities;
};

export const getItems = createSelector(
  [getItemIds, getItemEntities],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export const getItemsExistMore = (state) => {
  return state.items.isExistMore;
};

export const getIsItemsLoading = (state) => {
  return state.items.isLoading;
};

export const getItemsOffset = createSelector(
  [getItemIds],
  (ids) => {
    return ids.length;
  },
);
