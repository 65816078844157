import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { equals, isEmpty } from 'ramda';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { getCommunitiesForSelect } from '../../store/community/selectors';
import styles from './CommunitySelect.module.scss';

const DEFAULT_OPTION = {
  value: 'default',
  label: '',
};

const CommunitySelect = memo(({
  options,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION);
  const onSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
  };
  const isSelectDisabled = options.length === 0;

  useEffect(() => {
    if (equals(selectedOption, DEFAULT_OPTION) && !isEmpty(options)) {
      onSelect(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedOption]);

  return (
    <UncontrolledButtonDropdown className={cls(styles.selectWrapper, 'd-flex')}>
      <DropdownToggle
        className={cls(styles.selectPlaceholder)}
        disabled={isSelectDisabled}
        color="default"
        size="lg"
      >
        {selectedOption.label}

        {selectedOption.icon && (
          <span className={cls(styles.selectIcon, 'rounded-circle')}>
            <img
              src={selectedOption.icon}
              alt={selectedOption.label}
            />
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className={styles.selectMenu}>
        {options.map((option) => {
          const onClick = () => {
            onSelect(option);
          };

          return (
            <DropdownItem onClick={onClick} key={option.value}>
              {option.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
});

CommunitySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      communityIcon: PropTypes.string,
    }),
  ).isRequired,

  onChange: PropTypes.func.isRequired,
};

export default connect((state) => {
  return {
    options: getCommunitiesForSelect(state),
  };
})(CommunitySelect);
