import englishMessages from 'ra-language-english';

/* eslint-disable max-len */
export default Object.assign(englishMessages, {
  not_found: 'Data not found',
  general: {
    label: {
      phone_number: 'Phone Number',
      app_name: 'App Name',
      enter_pin: 'Enter Pin',
      help: 'Help',
      privacy: 'Privacy',
      terms: 'Terms',
      code: 'Code',
      logout: 'Logout',
      enter_code: 'Enter Code',
      deactivated: 'Deactivated',
      selectGroup: 'Select group',
      uploaded: 'Uploaded',
      sold: 'Sold',
      joined: 'Joined',
      noImage: 'No image',
      recommendedSize: 'Recommended size %{size}',
      loading: 'Loading',
      noData: 'No data',
      admin: 'Admin',
      superAdmin: 'Super Admin',
      shipper: 'Shipper',
      inProgress: 'In progress',
      active: 'Active',
    },
    button: {
      send: 'Send',
      confirm: 'Confirm',
      send_another: 'Send Another',
      okay: 'Okay',
      save: 'Save',
      cancel: 'Cancel',
      create: 'Create',
      back: 'Back',
    },
    validation: {
      notEmpty: 'The field should not be empty',
    },
    alert: {
      phone: 'Please confirm your country code and enter your phone number',
      confirmation: 'Please enter verification code we’ve sent to your number',
    },
    hints: {
      sidebarButton: 'Turn on/off sidebar collapsing',
    },
  },
  pages: {
    login: {
      title: 'Sign in',
    },
    handle_code: {
      dialog_title: 'Incorrect Pin',
      dialog_message: 'The 4 digit pin you used is invalid.Please try again or request a new pin.',
    },
    dashboard: {
      title: {
        page: 'Community analytics',
        menu: 'Dashboard',
      },
      itemsCountAnalytics: 'Count of items, uploaded and sold',
      itemsValueAnalytics: 'Value of items, uploaded and sold',
      membersJoinedCountAnalytics: 'Count of members joined',
      topUploadedCategories: 'Top uploaded categories',
      topSoldCategories: 'Top sold categories',
      summary: {
        totalCount: 'Items uploaded count',
        totalValue: 'Items uploaded value',
        soldCount: 'Items sold count',
        soldValue: 'Items sold value',
        marketCount: 'Items in the market count',
        marketValue: 'Items in the market value',
        members: 'Members joined',
        issuedFunds: 'Issued funds',
        remainingSupply: 'Remaining supply',
      },
      buttons: {
        updateDateRange: 'Update range',
      },
      modal: {
        header: 'Update analytics date range',
        labels: {
          startDate: 'From:',
          endDate: 'To:',
        },
      },
    },
    communityInfo: {
      title: {
        page: 'Community info',
        menu: 'Community info',
      },
      labels: {
        name: 'Community Name',
        about: 'Community description',
        icon: 'Community icon',
        tokenName: 'Token name',
        tokenIcon: 'Token icon',
        walletImage: 'Wallet image',
        addLocation: 'Add Location to Community',
        searchLocation: 'Search for a location...',
        saveLocation: 'Save Location',
      },
      validation: {
        errors: {
          tooShort: 'Name is too short (min. 3 characters)',
          notUnique: 'Name is already taken',
        },
      },
    },
    members: {
      title: {
        page: 'Community members',
        menu: 'Members',
      },
      labels: {
        types: {
          all: 'Members',
          suspended: 'Suspensions',
          suspicious: 'Suspicions',
        },
      },
      table: {
        search: {
          placeholder: 'Search',
        },
        switcher: {
          title: 'Only Active',
        },
        columns: {
          profileImage: 'Photo',
          name: 'Display name',
          nickname: 'Username',
          id: 'Member ID',
          lastAccessDate: 'Last login date',
          createdAtDate: 'Created date',
          status: 'Status',
          actions: 'Actions',
          phoneNumber: 'Phone number',
          initiator: 'Initiator ID',
          reason: 'Activation Reason',
          rehabNote: 'Deactivation Note',
          activationDate: 'Activated at',
          deactivationDate: 'Deactivated at',
          gotItems: 'Got items',
          gaveItems: 'Gave items',
          uploadedItems: 'Uploaded items',
          totalPoints: 'Total points',
          referralId: 'Referral Id',
        },
        statuses: {
          member: 'Member',
          newMember: 'New member',
          admin: 'Admin',
          pendingApproval: 'Pending approval',
          declined: 'Declined',
        },
      },
      show_members_filter: 'Show Members Filters',
      filter_members_by: 'Filter Members By',
      members_created_before: 'Members Created Before',
      members_created_after: 'Members Created After',
      members_last_login_before: 'Members Last Login Before',
      members_last_login_after: 'Members Last Login After',
      members_got_more_than: 'Members Got More Than',
      members_got_less_than: 'Members Got Less Than',
      members_with_more_items_than: 'Members With More Items Than',
      members_with_fewer_items_than: 'Members With Fewer Items Than',
      members_with_more_points_than: 'Members With More Points Than',
      members_with_fewer_points_than: 'Members With Fewer Points Than',
      members_gave_more_than: 'Members Gave More Than',
      members_gave_less_than: 'Members Gave Less Than',
      cancel: 'Cancel',
      clear_filters: 'Clear Filters',
      apply_filters: 'Apply Filters',
    },
    memberInfo: {
      title: 'Member Profile',
      cardTitle: 'Info',
      activitiesTitle: 'Activities',
      joinedAt: 'Joined at %{date}',
      lastSeen: 'Last seen: %{distance}',
      phoneNumber: 'Phone number: %{phoneNumber}',
      suspended: 'This user suspended by community admins (%{distance}) ',
      markedAsSuspicious: 'This user marked as suspicious by admins',
      referredInfo: 'Has joined the app by referral from',
      hasReferred: 'Has reffered ',
      andOthers: 'and %{count} others',
      suspensionsCount: 'Suspensions',
      suspiciousCount: 'Times marked as suspicious',
      walletBalance: 'Wallet balance',
      reason: 'Reason: %{reason}',
      buttons: {
        suspend: 'Suspend',
        unsuspend: 'Remove suspension',
        mark: 'Mark as suspicious',
        unmark: 'Remove from suspicious',
        activateShipper: 'Set as shipper',
        deactivateShipper: 'Remove shipper status',
        cancelArrival: 'Cancel arrival',
        promoteToAdmin: 'Promote to admin',
      },
      tooltips: {
        sameUser: 'User cannot suspend/mark as suspicious themselves',
        superAdmin: 'Super Admin cannot be suspended/marked as suspicious',
        noAccess: 'Only Super Admins can suspend another admin',
        whiteList: 'This user cannot be suspended / marked as suspicious',
      },
      modals: {
        title: {
          suspicious: {
            add: 'Mark this user as suspicious',
            delete: 'Are you sure?',
          },
          suspension: {
            add: 'Suspend user',
            delete: 'Unsuspend user',
          },
          referralUsers: 'Users reffered by this member',
        },
        inputs: {
          labels: {
            reason: 'Reason (required)',
          },
        },
      },
      table: {
        title: {
          buying: 'Buying',
          selling: 'Selling',
        },
        columns: {
          offerId: 'Offer ID',
          buyer: 'Buyer',
          seller: 'Seller',
          created: 'Created',
          updated: 'Last updated',
          status: 'Status',
          duration: 'E2E',
          group: 'Group',
          item: 'Item',
          price: 'Price',
          actions: 'Actions',
        },
      },
      offerStatuses: {
        pending: 'Ask to receive',
        confirmed: 'Confirmed',
        delivered: 'Item delivered',
      },
    },
    transactions: {
      title: {
        page: 'Community transactions',
        menu: 'Transactions',
      },
      labels: {
        types: {
          transfer: 'Exchanges',
          bonus: 'Bonus',
          issue: 'Issued',
        },
        search: {
          placeholder: 'Search by name / user ID / item ID',
          offerPlaceholder: 'Search by offer ID',
        },
        export: 'Export to CSV',
        create: 'New transaction',
        filter: 'filter',
      },
      table: {
        columns: {
          id: 'ID',
          receiverName: 'Receiver',
          senderName: {
            transfer: 'Sender',
            issue: 'Sender',
            bonus: 'Community',
          },
          groupName: 'Group',
          amount: 'Amount',
          createdAt: 'Date',
          reason: {
            transfer: 'Item info',
            issue: 'Reason',
            bonus: 'Reward title',
          },
          type: 'Type',
          offerInfo: 'Offer info',
          actions: 'Actions',
        },
        buttons: {
          performDecline: 'Decline request',
          performRefund: 'Perform refund',
        },
      },
      modal: {
        title: 'Create a token transaction',
        select: {
          placeholder: 'Select receivers',
          label: 'Receivers',
        },
        amount: {
          label: 'Amount',
          validation: 'The amount must be greater than 0',
        },
        note: {
          label: 'Note',
          validation: 'The note field cannot be empty',
        },
      },
    },
    groupInfo: {
      title: {
        page: 'Group info',
        menu: 'Group info',
      },
      inputs: {
        groupName: {
          label: {
            en: 'Group name (English)',
            he: 'Group name (Hebrew)',
            br: 'Group name (Portuguese)',
            de: 'Group name (Deutsch)',
          },
        },
        groupDescription: {
          label: 'Group description',
        },
        imageUploader: {
          label: 'Display group banner',
        },
      },
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
        select: 'Select image',
        changeOrder: 'Change order',
        create: 'Create',
      },
    },
    subcommunities: {
      title: {
        page: 'Spaces',
        menu: 'Spaces',
      },
      modal: {
        header: {
          create: 'Create Subcommunity',
          edit: 'Edit Subcommunity',
        },
      },
      inputs: {
        name: {
          label: 'Name',
        },
        isPrivate: {
          label: 'Private',
        },
        joinCode: {
          label: 'Join code',
        },
        backgroundImage: {
          label: 'Background link',
        },
      },
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
        select: 'Select image',
        create: 'Create',
      },
    },
    rules: {
      title: {
        page: 'Rules',
        menu: 'Rules',
      },
      labels: {
        types: {
          community: 'Community',
          item: 'Item',
          wish: 'Wish',
        },
      },
      buttons: {
        create: 'Create',
        edit: 'Edit',
        delete: 'Delete',
      },
      modal: {
        header: {
          create: 'Create rule',
          edit: 'Edit rule',
        },
        inputs: {
          labels: {
            title: 'Rule title (max 50 characters)',
            description: 'Rule description (max 150 characters)',
          },
        },
        footer: {
          buttons: {
            cancel: 'Cancel',
            save: 'Save',
          },
        },
      },
    },
    features: {
      title: {
        page: 'Features',
        menu: 'Features',
      },
      sections: {
        community: {
          title: 'Community features',
        },
        group: {
          title: 'Group features',
        },
      },
      options: {
        hasFollowers: {
          title: 'Following/followers',
          description:
            'Allow members to follow other community members. Turn this feature on to enable the filter and notification.',
        },
        hasWishes: {
          title: 'Wishes',
          description: 'Allow members to upload wishes for items they are looking for.',
        },
        hasDiscounts: {
          title: 'Discounts',
          description:
            'Allow members to discount items after they have been available for one week. Discounting an item will bring it to the top of the group feed.',
        },
        hasFavorites: {
          title: 'Saved items',
          description:
            'Allow members to save items they are interested in. Members will be able to see their saved items on one page. Members will be notified when their saved items are no longer available.',
        },
        hasBrands: {
          title: 'Brands',
          description:
            'Allow members to select brands for the items they upload. Members can filter for specific brands in the marketplace.',
        },
        hasSizes: {
          title: 'Sizes',
          description: 'Allow members to select a size when uploading an item.',
        },
        hasQuestions: {
          title: 'Membership questions',
          description: 'Use member questions to identify the right people for your community.',
        },
        hasSlider: {
          title: 'Community carousel',
          description:
            'Create a unique entrance to your community with the Carousel. Give members more information about your community before they join.',
        },
        hasSellerItemRepost: {
          title: 'Selling more than one',
          description:
            'Allow members to sell multiple quantities of the same item. The item will stay active in the marketplace until the member chooses to remove it.',
        },
        hasBuyerItemRepost: {
          title: 'Post a purchased item',
          description: 'Allow members to add a purchased item to the marketplace.',
        },
        isPrivate: {
          title: 'Private',
          description: 'Set is community private',
        },
        isOpenForVisitors: {
          title: 'Open for visitors',
          description: 'Set is community open for visitors',
        },
      },
    },
    categories: {
      title: {
        page: 'Categories',
        menu: 'Categories',
      },
      buttons: {
        create: 'Create',
        add: 'Add',
        edit: 'Edit',
        deactivate: 'Deactivate',
        activate: 'Activate',
        save: 'Save',
        cancel: 'Cancel',
      },
      labels: {
        selectGroup: 'Select group',
        deactivated: 'Deactivated',
        categoryName: 'Category name',
        iconName: 'Icon name',
      },
      modal: {
        header: {
          create: 'Create category',
          edit: 'Edit category',
        },
      },
    },
    brands: {
      title: {
        page: 'Brands',
        menu: 'Brands',
      },
      description: {
        left:
          'Allow members to select brands for the items they upload. Members can filter for specific brands in the marketplace. To turn Brands On or Off for a group, please go to the ',
        link: 'Features',
        right: ' page',
      },
      labels: {
        selectGroup: 'Select Group',
        deactivated: 'Deactivated',
        brandName: 'Brand name',
      },
      buttons: {
        create: 'Create',
        cancel: 'Cancel',
        save: 'Save',
      },
      modal: {
        header: {
          add: 'Add brand',
          edit: 'Edit brand',
        },
      },
      validation: {
        brandName: 'Brand name must be unique and not empty',
      },
    },
    conditions: {
      title: {
        page: 'Item conditions',
        menu: 'Conditions',
      },
      labels: {
        selectGroup: 'Select group',
        english: 'English',
        hebrew: 'Hebrew',
        priceCoefficient: 'Price coefficient',
        title: 'Title',
        subtitle: 'Subtitle',
      },
      buttons: {
        create: 'Create',
        cancel: 'Cancel',
        save: 'Save',
      },
      modal: {
        header: {
          create: 'Create condition',
          edit: 'Edit condition',
        },
      },
      validation: {
        any: 'The field should not be empty',
      },
    },
    sizes: {
      title: {
        page: 'Sizes',
        menu: 'Sizes',
      },
      modal: {
        header: {
          create: 'Create size',
          edit: 'Edit size',
        },
      },
      labels: {
        titleEn: 'Size title (English)',
        titleHe: 'Size title (Hebrew)',
      },
    },
    reports: {
      title: {
        page: 'Community reports',
        menu: 'Reports',
      },
      labels: {
        types: {
          user: 'User reports',
          item: 'Item reports',
          reporters: 'Reporters',
          reportedUsers: 'Reported users',
          top: 'Top reports',
          daily: 'Daily reports',
        },
        table: {
          reporters: {
            item: 'By item',
            user: 'By user',
          },
        },
        filter: 'filter',
        charts: {
          titles: {
            item: 'Top item reports (%{total} total)',
            user: 'Top user reports (%{total} total)',
          },
        },
      },
      active_users: 'Active Users',
      new_registered_users: 'New Registered Users',
      uploaded: 'Uploaded',
      exchanged: 'Exchanged',
      shipped: 'Shipped',
      refunded: 'Refunded',
      active_tokens: 'Active tokens',
      available_in_market: 'Available in market',
      summary_statistics: 'Summary Statistics',
      members: 'Members',
      givers: 'Givers',
      receivers: 'Receivers',
      verified_members: 'Verified Members',
      uploaded_value: 'Uploaded Value',
      exchanged_value: 'Exchanged Value',
      shipped_value: 'Shipped Value',
      refunded_value: 'Refunded Value',
      items_in_market: 'Items in Market',
      issued_points: 'Issued Points',
      total_items_uploaded: 'Total Items Uploaded',
      count: 'Count',
      value: 'Value',
      unique_participants: 'Unique Participants',
      unique_givers: 'Unique Givers',
      unique_uploaders: 'Unique Uploaders',
      unique_receivers: 'Unique Receivers',
      weekly_view: 'Weekly View',
      table: {
        reports: {
          columns: {
            id: 'ID',
            createdAt: 'Date',
            reportedUserName: 'Reported person',
            reporterUserName: 'Reporter',
            reasonId: 'Reason',
            itemTitle: 'Reported item',
          },
        },
        reporters: {
          columns: {
            id: 'User ID',
            reporterName: 'User info',
            value: 'Reports count',
          },
        },
      },
    },
    items: {
      title: {
        page: 'Items',
        menu: 'Items',
      },
      table: {
        columns: {
          item: 'Item',
          seller: 'Seller',
          createdAt: 'Created at',
          price: 'Current price',
          originalPrice: 'Original price',
          hasMultipleSupply: 'Has multiple supply',
          status: 'Status',
          actions: 'Actions',
        },
        statuses: {
          active: 'Active',
          sold: 'Sold',
          pending: 'Pending',
          hidden: 'Hidden',
          removed: 'Removed',
        },
        buttons: {
          hideItem: 'Hide item',
        },
        search: {
          placeholder: 'Search by item ID/seller ID/item title',
        },
      },
    },
    talents: {
      title: {
        page: 'Talents',
        menu: 'Talents',
      },
      table: {
        columns: {
          talent: 'Talent',
          seller: 'Seller',
          createdAt: 'Created at',
          price: 'Current price',
          originalPrice: 'Original price',
          hasMultipleSupply: 'Has multiple supply',
          status: 'Status',
          actions: 'Actions',
        },
        statuses: {
          active: 'Active',
          sold: 'Sold',
          pending: 'Pending',
          hidden: 'Hidden',
          removed: 'Removed',
        },
        buttons: {
          hideItem: 'Hide talent',
        },
        search: {
          placeholder: 'Search by talent ID/seller ID/talent title',
        },
      },
    },
    knowledgeLibrary: {
      show_filters: 'Show Filters',
      title: {
        page: 'Knowledge Library',
        menu: 'Knowledge Library',
      },
      labels: {
        adm: 'Adm',
        pending: 'Pending Approval',
      },
      table: {
        columns: {
          post: 'Post',
          writer: 'Writer',
          created: 'Created At',
          status: 'Status',
          actions: 'Actions',
        },
        search: {
          placeholder: 'Search by topic, theme, writter',
        },
      },
    },
    wallets: {
      title: {
        page: 'Community Wallets',
        menu: 'Wallets',
      },
      analytics: {
        checkbox: 'Last seen in %{count} days',
        totalSupply: 'Total supply',
        activeWallets: 'Active Wallets',
        activeItems: 'Active items',
      },
      table: {
        search: {
          placeholder: 'Search by user ID / phone number',
        },
        columns: {
          id: 'User ID',
          phoneNumber: 'Phone number',
          createdAtDate: 'Joined at',
          lastAccessDate: 'Last seen at',
          balanceAmount: 'Balance',
          rewardAmount: 'Reward amount',
        },
      },
    },
    cities: {
      title: {
        menu: 'Cities',
      },
      table: {
        search: {
          placeholder: 'Search',
        },
        columns: {
          city: 'City',
          changeImage: 'Change Image',
          deleteCity: 'Delete city',
          editName: 'Change name',
          editImage: 'Change image',
        },
      },
      modal: {
        titleEn: 'Edit name (English)',
        titleHe: 'Edit name (Hebrew)',
        titleBr: 'Edit name (Brazil)',
        titleDe: 'Edit name (German)',
      },
    },
  },
  offers: {
    types: {
      interested: 'Interested',
      pendingDecision: 'Pending decision',
      offerConfirmed: 'Offer confirmed',
      itemDelivered: 'Item delivered',
      notAvailable: 'Not available',
      itemSold: 'Item sold',
      sellerLeftCommunity: 'Seller left community',
      buyerLeftCommunity: 'Buyer left community',
      itemRemoved: 'Item removed',
    },
  },
  message: {
    error: {
      invalid_code: 'Enter valid code',
      invalid_mobile: 'Enter valid mobile number',
      only_admin_access: 'Only admins have access to the panel',
      invalid_group_name: 'The value must be a minimum of 3 characters',
      action: {
        login: {
          not_authorized: 'Only admins have access to the panel',
          internal_error: 'Unable to process your request',
        },
        verify_code: {
          not_authorized: 'Only admins have access to the panel',
          internal_error: 'Unable to process your request',
        },
      },
    },
    success: {
      action: {
        cancelArrival: 'Item status successfully updated',
        hideItem: 'Item successfully hidden from marketplace',
        hideTalent: 'Talent successfully hidden from marketplace',
        performDecline: 'Item request successfully declined',
        performRefund: 'Refund performed successfully',
        promoteToAdmin: 'User successfully promoted to admin',
        saveLocation: 'Location updated successfully',
        transaction: {
          send: 'Successfully sent',
        },
        login: {
          code_sent: 'Verification code sent',
        },
        community: {
          info: {
            save: 'Successfully saved',
          },
          questions: {
            added: 'Community question added.',
            updated: 'Community question updated.',
            deleted: 'Community question deleted.',
            error: 'Unable to process the request.',
          },
        },
        cities: {
          removed: 'City removed successfully',
        },
        subcommunities: {
          edit: 'Subcommunity successfully updated',
          create: 'Subcommunity successfully created',
        },
      },
    },
  },
  resources: {},
});
