import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const SubcommunityTable = ({ subcommunities, handleEditSpace }) => {
  return (
    <div className="w-full mx-auto mt-20">
      <table className="min-w-full bg-white">
        <tbody>
          {subcommunities?.map((group, index) => (
            <tr key={index} className="border-b">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-14 w-14">
                    <img
                      className="h-14 w-14 rounded-full"
                      src={group?.backgroundImage || group?.background_image}
                      alt="background image"
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-lg font-semibold text-dark1">{group?.name}</div>
                    <div className="text-lg text-dark1">{group?.totalMembers || 0} members</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-lg font-semibold text-dark1">{group?.isPrivate ? 'Private' : 'Public'}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-lg text-dark1">{group?.description}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a href="#" className="text-bluePrimary text-lg font-semibold hover:text-blue2" onClick={() => handleEditSpace(group?.id)}>
                  Edit
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SubcommunityTable.propTypes = {
  subcommunities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      members: PropTypes.number.isRequired,
      isPrivate: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
      backgroundImage: PropTypes.string,
    })
  ).isRequired,
};

export default SubcommunityTable;
