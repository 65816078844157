import * as React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './Text.module.scss';

const textTags = {
  p: 'p',
  span: 'span',
};

const textSizes = {
  xxSmall: 10,
  xSmall: 12,
  small: 14,
  normal: 16,
  large: 18,
  xLarge: 20,
  xxLarge: 24,
  xxxLarge: 28,
};

const textColors = {
  black: 'dark',
  white: 'white',
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
  inherit: 'reset',
  success: 'success',
};

const textWeights = {
  normal: 'normal',
  bold: 'bold',
  medium: '500',
  semiBold: '600',
  italic: 'italic',
};

const Text = ({ children, tag: Tag, size, color, weight, className }) => {
  return (
    <Tag
      style={{ fontSize: size, fontWeight: weight }}
      className={cls('p-0', 'm-0', styles.root, className, {
        [`text-${color}`]: color,
      })}
    >
      {children}
    </Tag>
  );
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  tag: PropTypes.oneOf(Object.values(textTags)),
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(Object.values(textSizes)),
  ]),
  color: PropTypes.oneOf(Object.values(textColors)),
  weight: PropTypes.oneOf(Object.values(textWeights)),
  className: PropTypes.string,
};

Text.defaultProps = {
  tag: textTags.p,
  size: textSizes.normal,
  color: textColors.black,
  weight: textWeights.normal,
  className: null,
};

export const textProps = {
  tags: textTags,
  sizes: textSizes,
  colors: textColors,
  weights: textWeights,
};

export default Text;
