import { createAction } from 'redux-actions';
import * as AT from './types';

export const setCommunityFeatures = createAction(AT.SET_COMMUNITY_FEATURES);
export const setGroupFeatures = createAction(AT.SET_GROUP_FEATURES);

export const setCurrentGroupId = createAction(AT.SET_CURRENT_GROUP_ID);

export const updateCommunityFeatures = createAction(AT.UPDATE_COMMUNITY_FEATURES);
export const updateCommunityFeaturesSuccess = createAction(AT.UPDATE_COMMUNITY_FEATURES_SUCCESS);

export const updateGroupFeatures = createAction(AT.UPDATE_GROUP_FEATURES);
export const updateGroupFeaturesSuccess = createAction(AT.UPDATE_GROUP_FEATURES_SUCCESS);
