import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Popover as UIPopover, PopoverBody } from 'reactstrap';
import styles from './Popover.module.scss';

export const popoverPositions = {
  top: 'top',
  bottom: 'bottom',
};

const Popover = ({
  children,
  isOpen,
  position,
  id,
  setIsOpen,
  classNames,
}) => {
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const onClick = () => {
      setIsOpen(false);
    };

    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  });

  return (
    <UIPopover
      placement={position}
      isOpen={isOpen}
      target={id}
      toggle={toggleVisibility}
      popperClassName={cls(classNames, styles.popover)}
    >
      <PopoverBody>
        {children}
      </PopoverBody>
    </UIPopover>
  );
};

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  position: PropTypes.oneOf(Object.values(popoverPositions)),

  setIsOpen: PropTypes.func.isRequired,
};

Popover.defaultProps = {
  children: null,
  position: popoverPositions.bottom,
  classNames: null,
};

export default Popover;
