import React from 'react';

import EditIcon from '../../../../../assets/icons/edit.svg';

const UploadImage = ({ image, onEdit }) => {
  return (
    <div className="rounded-[4px] border-[1px] h-[176px] min-w-[176px] border-gray2 relative flex items-center justify-center">
      {image && (
        <img src={image} alt="image" className="w-[122px] h-[122px] rounded-full object-cover" />
      )}
      <img
        src={EditIcon}
        alt="edit"
        className="absolute bottom-4 right-4 rounded-[4px]"
        onClick={onEdit}
      />
    </div>
  );
};

export default UploadImage;
