export const errors = {
  tooShort: 'pages.communityInfo.validation.errors.tooShort',
  notUnique: 'pages.communityInfo.validation.errors.notUnique',
};

export const MIN_COMMUNITY_NAME_LENGTH = 3;
export const MIN_TOKEN_NAME_LENGTH = 3;
export const VALIDATE_DEBOUNCE = 300;

export const COMMUNITY_INFO_FIELD_IDS = {
  COMMUNITY_NAME: 'name',
  TOKEN_NAME: 'token_name',
};
