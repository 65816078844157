import React, { useState, useEffect } from 'react';

function Switch({ label, checked = false, onChange, ...props }) {
  const [isOn, setIsOn] = useState(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  const toggleSwitch = () => {
    const newIsOn = !isOn;
    setIsOn(newIsOn);
    onChange(newIsOn);
  };

  return (
    <div className="flex items-center justify-center" {...props}>
      <div className="flex items-center gap-3">
        <div onClick={toggleSwitch} className="cursor-pointer">
          <div className={`relative w-11 h-6 ${isOn ? 'bg-blue2' : 'bg-gray8'} rounded-full shadow-inner transition`}>
            <div
              className={`absolute left-[2px] top-[2px] bg-white w-5 h-5 rounded-full transition-transform ${isOn ? 'transform translate-x-5' : ''}`}
            ></div>
          </div>
        </div>
        <span className="text-dark3 text-base font-medium">{label}</span>
      </div>
    </div>
  );
}

export default Switch;
