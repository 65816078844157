import { createAction } from 'redux-actions';
import * as AT from './types';

export const setCommunityId = createAction(AT.SET_COMMUNITY_ID);
export const setGroupId = createAction(AT.SET_GROUP_ID);

export const getCommunities = createAction(AT.GET_COMMUNITIES);
export const getCommunitiesSuccess = createAction(AT.GET_COMMUNITIES_SUCCESS);

export const getCommunityGroups = createAction(AT.GET_COMMUNITY_GROUPS);
export const getCommunityGroupsSuccess = createAction(AT.GET_COMMUNITY_GROUPS_SUCCESS);

export const getCommunityAnalytics = createAction(AT.GET_COMMUNITY_ANALYTICS);
export const getCommunityAnalyticsSuccess = createAction(AT.GET_COMMUNITY_ANALYTICS_SUCCESS);

export const updateCommunityInfo = createAction(AT.UPDATE_COMMUNITY_INFO);
export const updateCommunityInfoSuccess = createAction(AT.UPDATE_COMMUNITY_INFO_SUCCESS);

export const updateGroupInfo = createAction(AT.UPDATE_GROUP_INFO);
export const updateGroupInfoSuccess = createAction(AT.UPDATE_GROUP_INFO_SUCCESS);
export const updateGroupsOrder = createAction(AT.UPDATE_GROUPS_ORDER);
