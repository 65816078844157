import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'ramda';
import { useTranslate } from 'react-admin';
import { TRANSACTIONS_TYPES } from '../../constants/transactions';
import * as transactionActions from '../../store/transactions/actions';
import * as transactionSelectors from '../../store/transactions/selectors';
import { getSelectedCommunityId } from '../../store/community/selectors';
import PageContent from '../../components/PageContent';
import TabsPanel from '../../components/TabsAccordion/TabsPanel';
import TabsContent from '../../components/TabsAccordion/TabsContent';
import TransactionsTable from './TransactionsTable';

const CommunityTransactions = ({
  transactions,
  selectedType,
  isLoading,
  communityId,
  setTransactionsType,
  getTransactions,
  declineItemRequest,
  performRefund,
}) => {
  const translate = useTranslate();
  const tabsOptions = [
    {
      value: TRANSACTIONS_TYPES.transfer,
      label: translate('pages.transactions.labels.types.transfer'),
    }, {
      value: TRANSACTIONS_TYPES.issue,
      label: translate('pages.transactions.labels.types.issue'),
    }, {
      value: TRANSACTIONS_TYPES.bonus,
      label: translate('pages.transactions.labels.types.bonus'),
    },
  ];

  const onTabClick = (value) => {
    setTransactionsType(value);
  };

  useEffect(() => {
    if (!isNil(communityId)) {
      setTransactionsType(TRANSACTIONS_TYPES.transfer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <PageContent
      title={translate('pages.transactions.title.page')}
    >
      <TabsPanel
        options={tabsOptions}
        activeTab={selectedType}
        onTabClick={onTabClick}
      />

      <TabsContent>
        <TransactionsTable
          items={transactions}
          type={selectedType}
          isLoading={isLoading}
          getItems={getTransactions}
          declineItemRequest={declineItemRequest}
          performRefund={performRefund}
        />
      </TabsContent>
    </PageContent>
  );
};

CommunityTransactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      reason: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ en: PropTypes.string, he: PropTypes.string }),
      ]),
      createdAt: PropTypes.string,
      groupName: PropTypes.shape({
        en: PropTypes.string,
        he: PropTypes.string,
      }),
      senderName: PropTypes.string,
      receiverName: PropTypes.string,
      amount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  selectedType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  communityId: PropTypes.number,
  setTransactionsType: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  declineItemRequest: PropTypes.func.isRequired,
  performRefund: PropTypes.func.isRequired,
};

CommunityTransactions.defaultProps = {
  communityId: null,
};

export default connect(
  (state) => {
    return {
      transactions: transactionSelectors.getTransactionsByType(state),
      selectedType: transactionSelectors.getSelectedTransactionsType(state),
      isLoading: transactionSelectors.getIsLoading(state),
      communityId: getSelectedCommunityId(state),
    };
  }, {
    setTransactionsType: transactionActions.setTransactionsType,
    getTransactions: transactionActions.getTransactions,
    declineItemRequest: transactionActions.declineItemRequest,
    performRefund: transactionActions.performRefund,
  },
)(CommunityTransactions);

