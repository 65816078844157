import { AxiosResponse } from 'axios';
import api from '../../api/Api';

const getSubcommunities = (communityId) => {
  return api.get(`v2/admin/community/${communityId}/sub-communities`) || [];
};

const addSubcommunity = (communityId, data) => {
  return api.post(`v2/admin/community/${communityId}/sub-community`, data);
};

const updateSubcommunity = (communityId, subcommunityId, data) => {
  return api.patch(`v2/admin/community/${communityId}/sub-community/${subcommunityId}`, data);
};

const addMember = (communityId, subcommunityId, data) => {
  return api.post(
    `v2/admin/community/${communityId}/sub-community/${subcommunityId}/members/create`,
    data,
  );
};

const addAdmin = (communityId, subcommunityId, data) => {
  return api.post(
    `v2/admin/community/${communityId}/sub-community/${subcommunityId}/admins/create`,
    data,
  );
};

const removeMember = (communityId, subcommunityId, memberId) => {
  return api.patch(
    `v2/admin/community/${communityId}/sub-community/${subcommunityId}/members/deactivate`,
    { user_id: memberId },
  );
};

const removeAdmin = (communityId, subcommunityId, adminId) => {
  return api.patch(
    `v2/admin/community/${communityId}/sub-community/${subcommunityId}/admins/deactivate`,
    { user_id: adminId },
  );
};

const getMembers = (communityId, subcommunityId) => {
  return api.get(`v2/admin/community/${communityId}/sub-community/${subcommunityId}/members`);
};

const getMembersJoinRequests = (subcommunityId) => {
  return api.get(`/admin/join_requests/list/sub_community/${subcommunityId}?only_pending=true`);
};

const resolveJoinRequest = (userId, isApproved) => {
  return api.post(`/admin/join_requests/resolve`, {
    join_request_id: userId,
    is_approved: isApproved,
    note: 'allowed to entry',
  });
};

export default {
  getSubcommunities,
  addSubcommunity,
  updateSubcommunity,
  addMember,
  addAdmin,
  removeMember,
  removeAdmin,
  getMembers,
  getMembersJoinRequests,
  resolveJoinRequest,
};
