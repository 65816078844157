import { all, takeEvery, select, put, call } from 'redux-saga/effects';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import api from '../../api/wallets';
import { getSelectedCommunityId } from '../community/selectors';
import { normalize, formatCase } from '../../utils/store';
import { showErrorMessage } from '../ui/operations';
import {
  ITEMS_INITIAL_LIMIT,
  ITEMS_PAGE_LIMIT,
  DEFAULT_SEARCH_TERM,
} from '../../constants/items';

function* onGetCommunityWallets({ payload }) {
  try {
    const {
      isLoadMore = true,
      nextPage = 0,
      searchTerm = DEFAULT_SEARCH_TERM,
    } = payload;

    const communityId = yield select(getSelectedCommunityId);
    const currentOffset = yield select(selectors.getWalletsOffset);
    const isExistMore = yield select(selectors.getWalletsExistMore);

    const isNeedToFetchData = currentOffset <= (nextPage * ITEMS_PAGE_LIMIT);

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const skip = isLoadMore
      ? currentOffset
      : 0;

    const limit = skip === 0
      ? ITEMS_INITIAL_LIMIT
      : ITEMS_PAGE_LIMIT;

    const wallets = yield call(api.getCommunityWallets, { communityId, limit, skip, searchTerm });

    const normalizedWallets = normalize(wallets);

    yield put(actions.getCommunityWalletsSuccess({
      ...normalizedWallets,
      isLoadMore,
      isExistMore: wallets.length === limit,
    }));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onGetWalletsAnalytics({ payload }) {
  try {
    yield put(actions.setIsLoading(true));
    const { onlyLastMonth } = payload;
    const communityId = yield select(getSelectedCommunityId);

    const analytics = yield call(api.getWalletsAnalytics, { communityId, onlyLastMonth });

    yield put(actions.getWalletsAnalyticsSuccess(formatCase.toCamelCase(analytics)));
  } catch (error) {
    yield* showErrorMessage(error);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_COMMUNITY_WALLETS, onGetCommunityWallets),
    takeEvery(AT.GET_WALLETS_ANALYTICS, onGetWalletsAnalytics),
  ]);
}
