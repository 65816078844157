// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Sidebar_root__2kTDd {\n  width: 237px;\n  height: 100%;\n  background-color: #fff;\n  color: #47494C;\n  overflow-y: auto;\n}\n.Sidebar_root__2kTDd::-webkit-scrollbar {\n  height: 8px;\n  width: 4px;\n}\n.Sidebar_root__2kTDd::-webkit-scrollbar-thumb {\n  border: none;\n  background-color: transparent;\n}\n.Sidebar_root__2kTDd:hover::-webkit-scrollbar-thumb {\n  background-color: var(--sidebar-item-active-color);\n}\n\n.Sidebar_sidebarWrapper__2uWks {\n  height: calc(100vh - 60px);\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 237px;\n  transition: width 0.3s ease-in-out;\n  position: fixed;\n  z-index: 101;\n  left: 0;\n}\n\n.Sidebar_sidebarWrapper__2uWks ~ div {\n  position: relative;\n  left: 237px;\n  background-color: #F5F6F7;\n  width: calc(100vw - 237px);\n  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;\n}\n\n.Sidebar_sidebarClose__1KGCb.Sidebar_sidebarWrapper__2uWks {\n  width: 64px;\n}\n@media (max-width: 767.98px) {\n  .Sidebar_sidebarClose__1KGCb.Sidebar_sidebarWrapper__2uWks {\n    width: 0;\n  }\n}\n.Sidebar_sidebarClose__1KGCb.Sidebar_sidebarWrapper__2uWks ~ div {\n  left: 64px;\n  width: calc(100vw - 64px);\n}\n@media (max-width: 767.98px) {\n  .Sidebar_sidebarClose__1KGCb.Sidebar_sidebarWrapper__2uWks ~ div {\n    left: 0;\n    width: 100vw;\n  }\n}\n\n.Sidebar_sidebarClose__1KGCb .Sidebar_sidebarLabels__1deBB > li > a > svg {\n  margin-left: 8px;\n  transition: margin-left 0.3s ease-in-out;\n}\n.Sidebar_staticSidebar__2e1GM .Sidebar_sidebarLabels__1deBB > li > a > svg {\n  transition: none;\n  margin-left: 0;\n}", ""]);
// Exports
exports.locals = {
	"root": "Sidebar_root__2kTDd",
	"sidebarWrapper": "Sidebar_sidebarWrapper__2uWks",
	"sidebarClose": "Sidebar_sidebarClose__1KGCb",
	"sidebarLabels": "Sidebar_sidebarLabels__1deBB",
	"staticSidebar": "Sidebar_staticSidebar__2e1GM"
};
module.exports = exports;
