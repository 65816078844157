import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../../../assets/icons/close.svg';
import SearchIcon from '../../../../../assets/icons/search.svg';
import membersApi from '../../../../../api/members';
import Loader from '../../../../../components/Loader';

const MembersModal = ({ closeModal, onAddMember, addedMembers }) => {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const limit = 20;

  const getMembers = async (isNewSearch = false) => {
    setIsFetching(true);
    if (isNewSearch) {
      setOffset(0);
      setHasMore(true);
    }

    try {
      const response = await membersApi.getCommunityMembers({
        offset: isNewSearch ? 0 : offset,
        limit,
        searchTerm,
      });

      if (isNewSearch) {
        setMembers(response);
      } else {
        setMembers((prev) => [...prev, ...response]);
      }

      if (response.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      setOffset((prev) => prev + limit);
    }
  };

  useEffect(() => {
    if (offset > 0) {
      getMembers();
    }
  }, [offset]);

  useEffect(() => {
    getMembers(true);
  }, [searchTerm]);

  const handleAddMember = (member) => {
    if (!addedMembers.some((m) => m.id === member.id)) {
      onAddMember(member);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-[30%] rounded-2xl shadow-lg">
        <div className="flex items-center justify-between p-4">
          <div className="relative w-full">
            <span className="absolute inset-y-0 left-3 flex items-center">
              <img src={SearchIcon} alt="Search" className="w-5 h-5 " />
            </span>
            <input
              type="text"
              placeholder="Search a User"
              className="w-full pl-10 pr-4 py-2 border text-base text-gray6 rounded-lg focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button onClick={closeModal} className="ml-2">
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>

        <div className="p-4 space-y-4 max-h-[400px] overflow-y-auto">
          {isFetching && <Loader />}
          {members?.map((user) => {
            const isAdded = addedMembers.some((m) => m.id === user.id);

            return (
              <div key={user.id} className="flex items-center justify-between pb-2">
                <div className="flex items-center space-x-3">
                  <img
                    src={user.profile_image}
                    alt={user.name}
                    className="w-10 h-10 rounded-full"
                  />
                  <span className="text-sm font-medium text-dark2">{user.name}</span>
                </div>
                <button
                  className={`px-4 py-2 ${
                    isAdded
                      ? 'bg-gray-400 text-white cursor-not-allowed'
                      : 'bg-bluePrimary text-white hover:bg-blue1'
                  } text-[12px] font-semibold rounded-[20px]`}
                  onClick={() => handleAddMember(user)}
                  disabled={isAdded}
                >
                  {isAdded ? 'Added' : 'Add Member'}
                </button>
              </div>
            );
          })}
          {hasMore && (
            <button
              onClick={handleLoadMore}
              className="w-full py-2 mt-4 bg-bluePrimary text-white text-center rounded-lg"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembersModal;
