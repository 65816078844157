import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { propEq, find, pathOr } from 'ramda';
import { useLocale, useSetLocale } from 'react-admin';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import { changeLocale } from '../../store/ui/actions';
import './override.scss';

const locales = [
  { value: 'en', country: 'US' },
  { value: 'he', country: 'IL' },
];

const LABELS = {
  IL: 'יִשְׂרָאֵל‏‎',
  US: 'English',
};

const LocaleSelect = () => {
  const dispatch = useDispatch();

  const locale = useLocale();
  const setLocale = useSetLocale();
  const selectRef = useRef();

  const { country: selectedCountry } = find(propEq('value', locale))(locales);

  useEffect(() => {
    // https://github.com/ekwonye-richard/react-flags-select/blob/master/README.md#updateselected
    // workaround to fix default country flag selected on refresh
    const reactSelectInstance = pathOr(null, ['current'], selectRef);
    if (reactSelectInstance) {
      reactSelectInstance.updateSelected(selectedCountry);
    }
  });

  const onSelect = (country) => {
    const { value } = find(propEq('country', country))(locales);

    setLocale(value);
    dispatch(changeLocale(value));
    localStorage.setItem('lang', value);
  };

  return (
    <ReactFlagsSelect
      defaultCountry={selectedCountry}
      onSelect={onSelect}
      countries={['IL', 'US']}
      customLabels={LABELS}
      showSelectedLabel={false}
      ref={selectRef}
    />
  );
};

export default LocaleSelect;
