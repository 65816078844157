import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageContent from '../../../components/PageContent';
import { useTranslate } from 'react-admin';
import { useParams, useHistory } from 'react-router-dom';
import Widget from '../../../components/Widget';
import UploadImage from './components/UploadImage';
import Input from '../../../components/Input';
import Switch from '../../../components/Switch';
import BoxList from './components/BoxList';
import * as selectors from '../../../store/community/selectors';
import * as subcommunitiesSelectors from '../../../store/subcommunities/selectors';
import ChevronLeftIcon from '../../../assets/icons/chevron-left.svg';
import AlertModal from './components/AlertModal';
import subcommunitiesApi from '../../../domains/Subcommunities/subcommunities.api';
import MembersModal from './components/MembersModal';
import RequestsBox from './components/RequestsBox/RequestsBox';
import ModeratorsModal from './components/ModeratorsModal';
import { toast } from '../../../components/ToastContainer';
import imageUploaderApi from '../../../domains/ImageUploader/imageUploader.api';

const CreateSubCommunity = ({ communityId, getSubcommunity }) => {
  const translate = useTranslate();
  const history = useHistory();
  const { spaceId: subCommunityId } = useParams();

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [isOpenConfirmationMemberModal, setIsOpenConfirmationMemberModal] = useState(false);
  const [spaceId, setSpaceId] = useState(subCommunityId);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [isModeratorModalOpen, setIsModeratorModalOpen] = useState(false);

  const [state, setState] = useState({
    name: '',
    description: '',
    isHidden: false,
    isPrivate: false,
    password: '',
    selectedImage: null,
    isLoading: false,
    members: [],
    membersJoinRequests: [],
    moderators: [],
    linkImage: '',
    isLoadingButton: false,
    memberToRemove: null,
  });

  const IS_EDIT_MODE = !!spaceId;
  const subcommunity = getSubcommunity(spaceId);

  useEffect(() => {
    if (subcommunity) {
      setState((prev) => ({
        ...prev,
        name: subcommunity.name,
        description: subcommunity.description,
        isHidden: subcommunity.is_active,
        isPrivate: !subcommunity.is_private,
        password: subcommunity.joinCode || '',
        selectedImage: subcommunity.backgroundImage,
      }));
    }
  }, [subcommunity]);

  useEffect(() => {
    if (spaceId) fetchMembers();
    setState((prev) => ({ ...prev, moderators: subcommunity?.admins || [] }));
  }, [spaceId]);

  const fetchMembers = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      const members = await subcommunitiesApi.getMembers(communityId, spaceId);
      const joinRequests = await subcommunitiesApi.getMembersJoinRequests(spaceId);
      setState((prev) => ({ ...prev, members, membersJoinRequests: joinRequests }));
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setState((prev) => ({ ...prev, selectedImage: URL.createObjectURL(file) }));
    }

    const formData = new FormData();
    formData.append('image', file);

    const link = await imageUploaderApi.uploadImage(formData);
    setState((prev) => ({ ...prev, selectedImage: link }));
  };

  const handleOpenMemberModal = () => {
    setIsMemberModalOpen(true);
  };

  const handleCloseMemberModal = () => {
    setIsMemberModalOpen(false);
  };

  const handleOpenModeratorModal = () => {
    setIsModeratorModalOpen(true);
  };

  const handleCloseModeratorModal = () => {
    setIsModeratorModalOpen(false);
  };

  const handleAddMember = async (member) => {
    setState((prev) => ({ ...prev, members: [...prev.members, member] }));

    try {
      await subcommunitiesApi.addMember(communityId, spaceId, {
        user_id: member.id,
      });
      toast('success', 'Member added successfully');
    } catch (error) {
      toast('error', 'Error adding member');
      setState((prev) => ({
        ...prev,
        members: prev.members.filter((m) => m.id !== member.id),
      }));
    }
  };

  const handleSave = async () => {
    const { name, description, isHidden, isPrivate, password, selectedImage } = state;
    const subcommunityData = {
      name,
      description,
      community_id: communityId,
      is_private: !isPrivate,
      join_code: password || null,
      background_image: selectedImage,
      is_active: !isHidden,
    };

    setState((prev) => ({ ...prev, isLoadingButton: true }));

    try {
      if (IS_EDIT_MODE) {
        await subcommunitiesApi.updateSubcommunity(communityId, spaceId, subcommunityData);
        toast('success', 'Subcommunity updated successfully');
      } else {
        const response = await subcommunitiesApi.addSubcommunity(communityId, subcommunityData);
        setSpaceId(response?.id);
        toast('success', 'Subcommunity saved successfully');
      }
    } catch (error) {
      toast('error', 'Error saving subcommunity');
      console.error('Error saving subcommunity:', error);
    } finally {
      setState((prev) => ({ ...prev, isLoadingButton: false }));
    }
  };

  const updateSubcommunity = async () => {
    const { name, description, isHidden, isPrivate, password, selectedImage } = state;

    const subcommunityData = {
      name,
      description,
      community_id: communityId,
      is_private: isPrivate,
      join_code: password || null,
      background_image: selectedImage,
      is_active: !isHidden,
    };

    try {
      await subcommunitiesApi.updateSubcommunity(communityId, spaceId, subcommunityData);
      setIsOpenAlertModal(false);
      toast('success', 'Subcommunity updated successfully');
    } catch (error) {
      toast('error', 'Error updating subcommunity');
      console.error('Error updating subcommunity:', error);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleChangeAlertModal = () => {
    setIsOpenAlertModal((prev) => !prev);
  };

  const handleRemoveModerator = async (moderator) => {
    try {
      await subcommunitiesApi.removeAdmin(communityId, spaceId, moderator.id);
      setState((prev) => ({
        ...prev,
        moderators: prev.moderators.filter((m) => m.id !== moderator.id),
      }));
      toast('success', 'Moderator removed successfully');
    } catch (error) {
      toast('error', 'Error removing moderator');
      console.error('Error removing moderator:', error);
    }
  };

  const handleRemoveMember = async () => {
    const { memberToRemove: member } = state;
    try {
      await subcommunitiesApi.removeMember(communityId, spaceId, member.id);
      setState((prev) => ({
        ...prev,
        members: prev.members.filter((m) => m.id !== member.id),
      }));
      toast('success', 'Member removed successfully');
    } catch (error) {
      toast('error', 'Error removing member');
      console.error('Error removing member:', error);
    }
  };

  const handleRemoveMemberConfirmationModal = (member) => {
    setState((prev) => ({ ...prev, memberToRemove: member }));
    setIsOpenConfirmationMemberModal((prev) => !prev);
  }

  const handleMakeModerator = async (member) => {
    try {
      await subcommunitiesApi.addAdmin(communityId, spaceId, { user_id: member.id });
      setState((prev) => ({
        ...prev,
        moderators: [...prev.moderators, member],
      }));
      toast('success', 'Moderator added successfully');
    } catch (error) {
      console.error('Error making moderator:', error);
    }
  };

  const handleAcceptRequest = async (user) => {
    try {
      await subcommunitiesApi.resolveJoinRequest(user.id, true);
      setState((prev) => ({
        ...prev,
        membersJoinRequests: prev.membersJoinRequests.filter((u) => u.id !== user.id),
        members: [...prev.members, user],
      }));

      toast('success', 'Request accepted successfully');
    } catch (error) {
      toast('error', 'Error accepting request');
      console.error('Error accepting request:', error);
    }
  };

  const handleDeclineRequest = async (user) => {
    try {
      await subcommunitiesApi.resolveJoinRequest(user.id, false);
      setState((prev) => ({
        ...prev,
        membersJoinRequests: prev.membersJoinRequests.filter((u) => u.id !== user.id),
      }));

      toast('success', 'Request declined successfully');
    } catch (error) {
      toast('error', 'Error declining request');
      console.error('Error declining request:', error);
    }
  };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const handleSwitchChange = (field) => (value) => {
    setState((prev) => ({ ...prev, [field]: value }));

    if (field === 'isPrivate' && !value) {
      setIsOpenAlertModal(true);
    }
  };

  return (
    <PageContent title={translate('pages.subcommunities.title.page')}>
      <Widget className="h-full w-full">
        <div className="flex flex-row items-center gap-2">
          <button
            className="bg-bluePrimary w-[32px] h-[32px] rounded-3xl items-center justify-center pl-[3px]"
            onClick={handleGoBack}
          >
            <img src={ChevronLeftIcon} />
          </button>
          <p className="text-black text-[24px] font-semibold">
            {spaceId ? 'Edit Space' : 'Create Space'}
          </p>
        </div>
        <div className="flex flex-row justify-center gap-4 w-full mt-2">
          <UploadImage
            image={state.selectedImage}
            onEdit={() => document.getElementById('imageInput').click()}
          />
          <input
            type="file"
            id="imageInput"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <div className="w-full">
            <div className="flex flex-row items-end gap-4 mb-2">
              <Input label="Name" value={state.name} onChange={handleInputChange('name')} />
              <Switch
                label="Hidden"
                checked={state.isHidden}
                onChange={handleSwitchChange('isHidden')}
                style={{ marginBottom: '10px' }}
              />
            </div>
            <Input
              label="Description/Values"
              type="textarea"
              value={state.description}
              onChange={handleInputChange('description')}
            />

            <div className="flex flex-row items-end gap-4 mt-1">
              <Switch
                label="Private"
                checked={state.isPrivate}
                onChange={handleSwitchChange('isPrivate')}
                style={{ marginBottom: '10px' }}
              />
              <Input
                label="Password"
                value={state.password}
                onChange={handleInputChange('password')}
              />
            </div>

            {IS_EDIT_MODE && (
              <RequestsBox
                users={state.membersJoinRequests}
                onAccept={handleAcceptRequest}
                onDecline={handleDeclineRequest}
                isLoading={state.isLoading}
              />
            )}
          </div>
          {IS_EDIT_MODE && (
            <div className="flex flex-col gap-4 items-end ml-4 relative">
              <BoxList
                title="Moderators"
                onAdd={handleOpenModeratorModal}
                data={state.moderators}
                isModerator
                onRemoveModerator={handleRemoveModerator}
              />
              <BoxList
                title="Members"
                onAdd={handleOpenMemberModal}
                data={state.members}
                onMakeModerator={handleMakeModerator}
                onRemoveMember={handleRemoveMemberConfirmationModal}
                isLoading={state.isLoading}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <button
            className="bg-bluePrimary border-none text-white h-[51px] px-4 py-3 rounded-lg text-[18px] font-semibold flex flex-row items-center justify-center gap-2"
            onClick={handleSave}
            disabled={state.isLoadingButton}
          >
            {state.isLoadingButton ? (
              <div className="flex justify-center items-center h-full">
                <i className="las la-circle-notch la-spin" />
              </div>
            ) : IS_EDIT_MODE ? (
              'Edit Space'
            ) : (
              'Create Space'
            )}
          </button>
        </div>

        {isOpenAlertModal && (
          <AlertModal
            closeModal={handleChangeAlertModal}
            title="Are you sure you want to change the Space’s privacy settings?"
            onConfirm={updateSubcommunity}
          />
        )}

        {isOpenConfirmationMemberModal && (
          <AlertModal
            closeModal={handleRemoveMemberConfirmationModal}
            title="Are you sure you want to remove this member from the Space?"
            onConfirm={handleRemoveMember}
          />
        )}

        {isMemberModalOpen && (
          <MembersModal
            closeModal={handleCloseMemberModal}
            onAddMember={handleAddMember}
            addedMembers={state.members}
          />
        )}
        {isModeratorModalOpen && (
          <ModeratorsModal
            closeModal={handleCloseModeratorModal}
            addedModerators={state.moderators}
            onAddModerator={handleMakeModerator}
          />
        )}
      </Widget>
    </PageContent>
  );
};

export default connect((state) => ({
  communityId: selectors.getSelectedCommunityId(state),
  getSubcommunity: (id) => subcommunitiesSelectors.getSubcommunityById(state, id),
}))(CreateSubCommunity);
