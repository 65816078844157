import api from './Api';

const getBrands = (groupId) => {
  return api.get(`/brands/all/${groupId}`);
};

const addBrand = (data) => {
  return api.post('/brand', data);
};

const updateBrand = (data) => {
  return api.put('/brand', data);
};

const activateBrand = (brandId, data = {}) => {
  return api.put(`/brand/activate/${brandId}`, data);
};

const deactivateBrand = (brandId, data = {}) => {
  return api.put(`/brand/deactivate/${brandId}`, data);
};

export default {
  getBrands,
  addBrand,
  updateBrand,
  activateBrand,
  deactivateBrand,
};
