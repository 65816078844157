import { createSelector } from 'reselect';

export const getGroupId = (state) => {
  return state.sizes.groupId;
};

export const getSizeEntities = (state) => {
  return state.sizes.sizeEntities;
};

export const getSizeIds = (state) => {
  return state.sizes.sizeIds;
};

export const getSizes = createSelector(
  [getSizeIds, getSizeEntities],
  (ids, entities) => {
    return ids.map((id) => {
      return entities[id];
    });
  },
);
