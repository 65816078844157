// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Separator_separator__2pRDr {\n  width: 100%;\n  height: 3px;\n  background-color: rgb(216.6666666667, 220.5, 224.3333333333);\n  margin: 4rem 0;\n}", ""]);
// Exports
exports.locals = {
	"separator": "Separator_separator__2pRDr"
};
module.exports = exports;
