import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslate } from 'react-admin';
import { FormGroup, Label } from 'reactstrap';
import Button, { buttonProps } from '../Button';
import styles from './ImageUploader.module.scss';

const FILE_INPUT_ID = 'upload';

const ImageUploader = ({
  defaultValue: initialImage,
  label,
  id,
  onChange,
}) => {
  const translate = useTranslate();
  const [image, setImage] = useState(initialImage);
  const onImageUpload = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      setImage(reader.result);
      onChange(file);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  return (
    <FormGroup>
      {label && (
        <Label className={cls('font-weight-bold', styles.label)}>{label}</Label>
      )}
      <input
        onChange={onImageUpload}
        accept="image/*"
        id={id}
        type="file"
        name="preview"
        multiple={false}
        style={{ display: 'none' }}
      />
      <div className={cls('d-grid', styles.fileInputGroup)}>
        <div className={cls(styles.fileInput, styles.fileInputNew)}>
          <div className={cls(styles.fileInputNew, styles.thumbnail)}>
            {image
              ? (
                <img src={image} alt="..." className={styles.previewImage} />
              )
              : (
                <div className={styles.emptyPreview}>
                  {translate('general.label.noImage')}
                </div>
              )
            }
          </div>
        </div>
        {image && (
          <span className={styles.dimensionsMessage}>
            {translate('general.label.recommendedSize', { size: '500x500' })}
          </span>
        )}
        <Button
          theme={buttonProps.themes.secondary}
          color={buttonProps.colors.light}
          weight={buttonProps.weights.bold}
          classNames={styles.fileInputButton}
          marginTop={false}
        >
          <Label
            for={id}
            className="mb-0 font-weight-bold"
          >
            {translate('pages.groupInfo.buttons.select')}
          </Label>
        </Button>
      </div>
    </FormGroup>
  );
};

ImageUploader.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

ImageUploader.defaultProps = {
  label: null,
  defaultValue: null,
  id: FILE_INPUT_ID,
  onChange: () => {},
};

export default ImageUploader;
