import { put } from 'redux-saga/effects';
import { showNotification } from 'ra-core';
import { hasPath } from 'ramda';

export function* showErrorMessage(error) {
  // eslint-disable-next-line no-console
  console.warn(error);
  let formattedMessage = '';

  if (hasPath(['response', 'status'], error)) {
    if (error?.response?.data?.message) {
      formattedMessage = `${error.response.status} — ${error.response.data?.message}`;
    } else {
      formattedMessage = `${error.response.status} — ${error.response.data}`;
    }
  } else {
    formattedMessage = error;
  }

  yield put(showNotification(formattedMessage, 'error'));
}
