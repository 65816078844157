import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import EditableTextarea, { inputTypes } from '../../../components/EditableTextarea';

const GroupDescriptionField = ({
  initialValue,
  id,
  onUpdate,
}) => {
  const translate = useTranslate();
  const [description, setDescription] = useState(initialValue);
  const isValueChanged = description !== initialValue;
  const isSaveButtonDisabled = !isValueChanged;

  useEffect(() => {
    setDescription(initialValue);
  }, [initialValue]);

  const onSave = () => {
    const data = new FormData();
    data.append('description', description);

    onUpdate(data);
  };

  return (
    <EditableTextarea
      value={initialValue}
      onInputChange={setDescription}
      onSave={onSave}
      isSaveButtonDisabled={isSaveButtonDisabled}
      id={id}
      label={translate('pages.groupInfo.inputs.groupDescription.label')}
      type={inputTypes.textarea}
      marginTop={false}
      useCustomLabel
    />
  );
};

GroupDescriptionField.propTypes = {
  initialValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

GroupDescriptionField.defaultProps = {
  initialValue: null,
};

export default GroupDescriptionField;
